import * as React from 'react';
import FormLayout from '../../components/form/index';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import * as router from "../../common/router";
import {
    Link
} from "react-router-dom";
import GroupImage from './GroupImage'
import GroupImageLocation from './GroupImageLocation'
import GroupGift from './GroupGift'
 var _ = require('lodash');

const ViewDataShift = (props: any) => {
    const{sup,pg} = props;
    const [data, setListData] = React.useState([]);
    React.useEffect(() => {
        let arr=[] as any
        arr.push(pg.detail)
        setListData(arr);
    }, [pg]);

    const ReloadPage =()=>{
        props.Reload(props.tabindex);
        //clickDetailData(dataId);
    }
    const renderDetail=()=>{
        const {detail} = pg;
        if (detail !== undefined && detail !== null) {
            return <>
                
                <div className="p-col-4">
                    <p>{detail.storeName}</p>
                    <p>Nhân viên : {detail.name}</p>
                </div>
                <div className="p-col-6">
                    <p>Địa chỉ : {detail.storeAddress}</p>
                    <p>Số điện thoại : {detail.username}</p>
                    <p>Ngày : {detail.dateSend}</p>
                </div>
            </>
        }
        return null
    }
    const renderPG=()=>{
        let arr=[] as any;
        if(pg.detail !== null && pg.detail !== undefined && pg.image4 !== undefined && pg.image4 !== null && pg.image4 !== [] && pg.image4.length > 0 )
        {
            pg.image4.map((item,i)=>{
                arr.push(<GroupImageLocation key={i} title="Thông tin Checkin" reload={ReloadPage} questionId={4} store={pg.detail} data={item} />)
            })
        }
        if(pg.detail !== null && pg.detail !== undefined && pg.image5 !== undefined && pg.image5 !== null && pg.image5 !== [] && pg.image5.length > 0 )
        {
            pg.image5.map((item,i)=>{
                arr.push(<GroupImageLocation key={i} title="Thông tin Checkout" reload={ReloadPage} questionId={5} store={pg.detail} data={item} />)
            })
        }  
       // console.log(arr)
            //   <>
            
            // <GroupImageLocation title="Thông tin Checkout" reload={Reload} store={{ dataId: pg.detail.dataId, questionId: 1 }} data={pg.image5} />
            // </> :null}
        return arr;
    }
    const renderGift=()=>{
        return <GroupGift title="Số lượng quà tại booth" reload={ReloadPage}  detail={pg.detail} data={data} />
    }
    return (
        <div>
            <FormLayout formTitle="CHI TIẾT CỬA HÀNG">
            <div className="p-grid class-store">
                 <div className="p-col-2">
                     <Link to={router.ListData}>
                        <Button icon="direction-left"  large >
                            <label>Quay Lại</label>
                        </Button>
                    </Link>
               
                </div>
             
                {renderDetail()}
            </div>
            
                {renderGift()}
                 {renderPG()} 
                 <GroupImage title="Check-in vật dụng làm việc" reload={ReloadPage} store={{ dataId: pg.detail.dataId, questionId: 9 }} data={pg.image9} />
                <GroupImage title="Hình ảnh tổng quan" reload={ReloadPage} store={{ dataId: pg.detail.dataId, questionId: 1 }} data={pg.image1} />
                <GroupImage title="Hình ảnh khay sampling " reload={ReloadPage} store={{ dataId: pg.detail.dataId, questionId: 2 }} data={pg.image2} />
                <GroupImage title="Hình ảnh mời KH dùng thử" reload={ReloadPage} store={{ dataId: pg.detail.dataId, questionId: 3 }} data={pg.image3} />
               
                <GroupImage title="Hình ảnh tư vấn KH" reload={ReloadPage} store={{ dataId: pg.detail.dataId, questionId: 10 }} data={pg.image10} />
              
                 </FormLayout>
        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity, summary, detail) => dispatch(actions.error_success(severity, summary, detail))
    }
}
const mapStateToProps = (state:any) => {
    return {
      profile:state.auth.profile
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(ViewDataShift);
