import * as React from 'react';
import FormLayout from '../../components/form/index';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import * as router from "../../common/router";
import * as api from '../../reducer/contains/api'
import { axiosWithHeaders,axiosWithHeadersNoTokenUploadFile,axiosWithHeadersDownloadFile } from '../../reducer/contains/axiosWrapper'
import {
    Link
} from "react-router-dom";
import * as Status from '../../common/enum'
import GroupImageCustomer from './GroupImageCustomer'
import GroupGiftCustomer from './GroupGiftCustomer'
 var _ = require('lodash');

const ViewDataShift = (props: any) => {
    const{sup,pg} = props;
    const [data, setListData] = React.useState([]) as any;
    const [customerId, setCustomerId] = React.useState(0);
   
    const [listFile, setListFile] = React.useState([]) as any;
    const [gift1, setGift1] = React.useState(0);
    const [gift2, setGift2] = React.useState(0);
    const [gift3, setGift3] = React.useState(0);
    const [gift4, setGift4] = React.useState(0);
    const [gift5, setGift5] = React.useState(0);
    const [detail, setDetail] = React.useState() as any;
    const [image11, setImage11] = React.useState([]) as any;
    const [image12, setImage12] = React.useState([]) as any;
    const [column, setColumn] = React.useState([]) as any;

    React.useEffect(() => {
        if (props.match.params.id !== undefined && props.match.params.id !== null) {
            setCustomerId( props.match.params.id);
          
            LoadData(props.match.params.id);
         
        }
        else {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!");
            props.history.push(router.ListData);
        }


    }, []);
    const LoadData=(customerId)=>{
        axiosWithHeaders('POST', `${api.DETAIL_INFO_CUSTOMER}`, {customerId})
        .then((data: any) => {
            if (data !== undefined && data !== null) {
                let response = data.data;
                let { status, message, result } = response;
              //  console.log(result)
                if (status === Status.STATUS_RESPONSE_OK) {
                    const { detail,image11,image12,detailList,column} = result;
                  setDetail(detail)
                  setImage11(image11)
                  setImage12(image12)
                  setListData(detailList)
                  setColumn(column)
                }
                else {
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                }
            }
            else { props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }

        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
    }

    const ReloadPage =()=>{
        LoadData(customerId)
    }
    
    
    const renderGift=()=>{
        return <GroupGiftCustomer title="THÔNG TIN KHÁCH HÀNG"  column={column} data={data} />
    }
    return (
        <div>
          
            <div className="p-grid class-store">
                 <div className="p-col-2">
                     <Link to={`${router.DataOrder}/${detail !== undefined && detail !== null ? detail.storeId : 0}`}>
                        <Button icon="direction-left"  large >
                            <label>Quay Lại</label>
                        </Button>
                    </Link>
               
                </div>
            </div>
            
                {renderGift()}

                <GroupImageCustomer title="Hình bill " reload={ReloadPage} store={{ customerId, questionId: 11,detail }} data={image11} />           
                <GroupImageCustomer title="Hình KH nhận quà" reload={ReloadPage} store={{customerId, questionId: 12,detail }} data={image12} />
              
               
        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity, summary, detail) => dispatch(actions.error_success(severity, summary, detail))
    }
}
const mapStateToProps = (state:any) => {
    return {
      profile:state.auth.profile
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(ViewDataShift);
