import React, {useState} from 'react';
import {DataTable} from 'primereact/datatable';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
import {Column} from 'primereact/column';

const TableGroupSumary3 = (props: any) => {
    const [emptyMessage] = useState<string>("Chưa có dữ liệu");
    const [paginator] = useState<boolean>(true);
    const [lazy] = useState<boolean>(true);
    const [rowsPerPageOptions] = useState<number[]>([50, 100, 200]);
    const [scrollable] = useState<boolean>(true);
    const [scrollHeight] = useState<string>("57.5vh");
    const [resizableColumns] = useState<boolean>(true);
    const [reorderableColumns] = useState<boolean>(true);
    const [columnResizeMode] = useState<string>("expand");

    const loadingText = () => {
        return <span className="loading-text"></span>;
    }
    const renderColumnGroupFrozen =()=>{
        let headerGroup = <ColumnGroup>
                            <Row>
                                <Column header="ITEM" />
                            </Row>
                            <Row className="tsst">
                                <Column header="ITEM" />
                            </Row>
                            
                        </ColumnGroup>;
        return headerGroup;
    }
    const renderColumnGroup =()=>{
        let headerGroup = <ColumnGroup>
                            <Row>
                                <Column header="Session" colSpan={3}  />
                                <Column header="Sales" colSpan={3}   />
                            </Row>
                            <Row>
                                <Column header="Plan" />
                                <Column header="Actual" />
                                <Column header="%" />
                                <Column header="KPI /session " />
                                <Column header="Actual /session" />
                                <Column header="% Actual/KPI" />
                            </Row>
                        </ColumnGroup>;
        return headerGroup;
    }
    const renderColumns = () =>{
        const {columns, cellTemplate} = props;
        let html: any = [];
        if(columns!==undefined && columns!==null && Array.from(columns).length > 0){
            Array.from(columns).map((item: any, i: number) => {
                return html.push(
                    <Column 
                        key={i} 
                        field={item.field}
                      //  header={item.header}
                      frozen={item.frozen}
                        style={{width: item.width, textAlign: item.align}}
                        loadingBody={() => loadingText()}
                        className="p-col-fixed"
                        body={(item.usedTemplate)?((row: any, cell: any)=> cellTemplate(row, cell)): null}
                    />
                )
            });
        }
        return html;
    }

    const renderTable = () => {
        let {
            data, 
            loading,
            first,
            totalRecords,
            rows,
            onPage,
        } = props;
        // console.log(data, loading, first, totalRecords, rows);
        return (
            <DataTable
                value={data}
                frozenHeaderColumnGroup={renderColumnGroupFrozen()}
                headerColumnGroup={renderColumnGroup()}
                emptyMessage={emptyMessage}
                loading={loading}
              //  paginator={paginator}
                lazy={lazy}
                first={first}
               // onPage={onPage}
                totalRecords={totalRecords}
                rows={rows}
                rowsPerPageOptions={rowsPerPageOptions}
                scrollable={scrollable}
                scrollHeight={scrollHeight}
                resizableColumns={resizableColumns}
                reorderableColumns={reorderableColumns}
                columnResizeMode={columnResizeMode}
                className="table-data"
                frozenWidth="60px"
            >
                {renderColumns()}
            </DataTable>
        )
    }

    return (
        <div className="row" style={{marginTop: 20, marginBottom: 20}}>
            <div className="col-12 col-smaller-12 col-small-12 col-medium-12 col-large-12 col-xlarge-12 col-xxlarge-12">
                {
                    renderTable()
                }
            </div>
        </div>
    );
}

export default TableGroupSumary3;