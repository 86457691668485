import * as React from 'react';
import * as types from '../reducer/contains/actionTypes'
import { connect } from 'react-redux';
import * as router from '../common/router'
import {
    Link
} from "react-router-dom";
class PageHeader extends React.Component<any, any>{
    
    public renderContent(){
        let breadcrumb: any = localStorage.getItem(types.LOCAL_STORAGE_BREADCRUMB);
        breadcrumb = JSON.parse(breadcrumb)
        if(breadcrumb===undefined || breadcrumb===null || Object.keys(breadcrumb).length === 0){
            breadcrumb = this.props;
        }

        const {
            title, 
            icon, 
            href, 
            description
        } = breadcrumb;
        return(
            <div className="page-header">
                <div className="row align-items-end">
                    <div className="col-lg-8">
                        <div className="page-header-title">
                            <i className={ icon + " bg-blue"}></i>
                            <div className="d-inline">
                                <h5>{title}</h5>
                                <span>{description}</span>
                            </div>
                        </div>
                    </div>
                    {
                        (title!=="Trang chủ") && (
                        <>
                        <div className="col-lg-4">
                            <nav className="breadcrumb-container" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={router.Home}>
                                            <i className="ik ik-home"></i>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <Link to={href}>
                                            <i className={icon}></i> {" " + title}
                                        </Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        </>
                        )
                    }
                    
                </div>
            </div>
        )
    }

    public render(){
        // console.log(this.props);
        return (
            <>
                {
                    this.renderContent()
                }
            </>
        )
    }
}
const mapStateToProps = state => {
    // console.log(state)
    return {
        title: state.breadcrumb.title,
        icon: state.breadcrumb.icon,
        href: state.breadcrumb.href,
        description: state.breadcrumb.description,
    }
}

export default connect(mapStateToProps, {})(PageHeader);
