import * as React from 'react';
import FormLayout from '../../components/form/index';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import * as router from "../../common/router";
import {
    Link
} from "react-router-dom";
import * as api from '../../reducer/contains/api'
import {axiosWithHeaders} from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum'
 var _ = require('lodash');

const StoreProductPrice = (props: any) => {
    const [data, setData] = React.useState([]);
    const [dataStore, setDataStore] = React.useState([]);
    const [storeId, setStoreId] = React.useState(0);
    // const [city, setCity] = React.useState(0);
  
    React.useEffect(() => {
        GetListStore(props.match.params.id)
       // onValueChangeStore(props.match.params.id)
    }, []);


    const GetListStore=(store : any)=>{
        axiosWithHeaders('GET',`${api.LIST_STORE_FULL}`,null)
        .then((data:any) => {
            if(data !== undefined && data !== null ){
               
                let response = data.data;
                let {status,message,result} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                    if(result !== undefined  && result !== null && result !== [] && result.length > 0 )
                    {
                        setDataStore(result)
                        if (store !== undefined && store !== null){
                            LoadPriceProduct(store)
                            setStoreId(parseInt(store));
                        } 
                     
                    }
                }
                else{
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                }
            }
            else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }
            
        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
    }
    const LoadPriceProduct=(storeId :any)=>{
        axiosWithHeaders('POST',`${api.GET_STORE_PRODUCT_PRICE}`,{storeId: storeId})
        .then((data:any) => {
            if(data !== undefined && data !== null ){
                let response = data.data;
                let {status,message} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                    setData(response.result)
                }
                else{
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                }
            }
            else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }
            
        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
    }
    const CheckData=()=>{
        if(storeId === undefined || storeId === 0)
        {
            props.error_success('error', 'Lỗi', "Vui lòng chọn siêu thị");
            return false;
        }
        return true;
    }
    const SaveData=()=>{
        if(!CheckData()){
            return false;
        }
        var obj={data}
     //   console.log(data)
        axiosWithHeaders('POST',api.UPDATE_STORE_PRODUCT_PRICE, obj)
        .then((data:any) => {
            if(data !== undefined && data !== null ){
                let response = data.data;
                let {status,message} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                    props.error_success('success', 'Thông báo', 'Thêm mới sản phẩm thành công !');
                    props.history.push(router.ListStoreProductPrice);
                }
                else{
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra ! !!!" + message);
                }
            }
            else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" );}
            
        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
        
    }

    const onValueChangeStore = (value) => {
        setStoreId(value)
         LoadPriceProduct(value)
    }
    const onValueChangePrice = (e,product:any) => {
        let now = data;
        now.map((item :any,idx)=>{
            if(item.productName === product.productName && item.vietnameseName === product.vietnameseName)
            {
                item.price = e.value
            }
        })
        setData(now)
    }
    const renderTable =()=>{
        let arr =[] as any;
        if(data !== undefined && data !== [] && data.length > 0)
        {
            data.map((item :any,idx)=>{
                
                arr.push(<div key={idx} className="p-grid" style={{ marginTop: -7 }}>
                <div className="p-col-8 text-right">
                     <label>{item.productName}</label>
                </div>
                <div className="p-col-4">
                    <InputNumber  value={item.price} placeholder="0"  min={0} max={400000000}
                        style={{ width: '100%' }} inputStyle={{ textAlign: 'right' }} onChange={(e) => onValueChangePrice(e,item)} />
                </div>
            </div>)
            })
        }
        return arr;
    }
    const renderStore=()=>{

            return <div className="p-grid" style={{ marginTop: -7 }}>
            <div className="p-col-4 text-right">
                <label>Chọn Siêu Thị</label>
            </div>
            <div className="p-col-6">
                <Dropdown 
                    
                    value={storeId} 
                
                    name="storeName"
                    optionLabel="storeName" 
                    optionValue="storeId" 
                    options={dataStore} 
                    placeholder="Chọn siêu thị..." 
                    filter={true} 
                    filterPlaceholder="Tìm..." 
                    filterBy="storeId,storeName"
                                        
                    // filterMatchMode="contains"
                    style={{width : '100%'}} 
                    onChange={(e: any)=> onValueChangeStore(e.target.value)}
                />
  
            </div>
        </div>
    }
    return (
        <div className="booking-layout">
            <FormLayout formTitle="CỬA HÀNG">
                <div className="p-grid">
                    <div className="p-col-4"></div>
                    <div className="p-col-4">
                        {renderStore()}
                        {renderTable()}
                        <div className="p-grid" style={{marginBottom: 10, marginTop: 10}}>
                            <div className="p-col-6">
                                <Link className="bp3-button bp3-large bp3-intent-primary btn-block btn-back" to={router.ListStoreProductPrice}>QUAY LẠI</Link>
                            </div>
                            <div className="p-col-6">
                                <Button
                                    intent="primary"
                                    className="btn-block"
                                    large
                                    onClick={()=>SaveData()}
                                >
                                    CẬP NHẬT
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-4"></div>
                </div>
            </FormLayout>
        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity,summary,detail) => dispatch(actions.error_success(severity,summary,detail))
    }
}

export default connect(null, mapDispatchToProps)(StoreProductPrice);
