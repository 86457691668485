import * as React from 'react';
import { connect } from 'react-redux';

import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import Layout from './layout/layout';
import LayoutLogin from './layout/layoutLogin';

import * as router from './common/router';

import Home from "./pages/Home/Home";
// import BBGiaoNhanHangHoa from "./pages/BBGNHH";

import Page404 from './pages/404'


// import Login from './components/login/style1';
// import Login2 from './components/login/style2PureComponent'
import Login2Hooks from './pages/Login/style2Hook'
//User
import ListUser from './pages/User/ListUser';
import User from './pages/User/User';
// Store
import ListStore from './pages/Store/ListStore'
import Store from './pages/Store/Store';

// StoreUser
import ListStoreUser from './pages/StoreUser/ListStoreUser'
import StoreUser from './pages/StoreUser/StoreUser';

//DATA
import ListData from './pages/Data/ListData';
import Data from './pages/Data/Data';
import DataOrder from './pages/Data/DataOrder' ;
import UpdateCustomerOrder from './pages/Data/UpdateCustomerOrder'
import AddData from './pages/Data/AddData'
import AddDataOrder from './pages/Data/AddDataOrder'

// Product
import ListProduct from './pages/Product/ListProduct';
import Product from './pages/Product/Product';

// Store Product Price
import ListStoreProductPrice from './pages/StoreProductPrice/ListStoreProductPrice'
import StoreProductPrice from './pages/StoreProductPrice/StoreProductPrice'

// Gift
import ListGift from './pages/Gift/ListGift'
import Gift from './pages/Gift/Gift'

// Master Plan
import ListMasterPlan from './pages/MasterPlan/ListMasterPlan'
import MasterPlan from './pages/MasterPlan/MasterPlan'


import PlanStock from './pages/Report/PlanStock'
import ReportSumary1 from './pages/Report/ReportSumary1'
import ReportSumary2 from './pages/Report/ReportSumary2'
import ReportSumary3 from './pages/Report/ReportSumary3'

import DetailCustomer from './pages/Data/DetailCustomer';

function RootComponent(props: any) {
  let route = (
    <LayoutLogin>
      <Switch>
        <Route path={router.Login} component={Login2Hooks} />
        <Redirect to={router.Login} />
      </Switch>
    </LayoutLogin>
  );

  if(props.isAuth){
    route = (
      <Layout>
        <Switch>
            <Route exact path={router.Home} component={Home} />
            <Route path={router.Page404} component={Page404} />
            <Redirect to={router.Home} />
        </Switch>
      </Layout>
    );

    const{roleId} = props.profile;

    switch(roleId){
      case 1:
        route = (
          <Layout>
            <Switch>
                <Route exact path={router.ListData} component={ListData} />
                <Route exact path={router.Report} component={Home} />
                <Route exact path={router.ListUser} component={ListUser} />
                <Route exact path={`${router.User}`} render={(props) => <User {...props} />}  />
                <Route exact path={`${router.User}/:id`} render={(props) => <User {...props} />}  />

                <Route exact path={router.ListStore} component={ListStore} />
                <Route exact path={`${router.Store}`} render={(props) => <Store {...props} />}  />
                <Route exact path={`${router.Store}/:id`} render={(props) => <Store {...props} />}  />

                <Route exact path={`${router.DetailCustomer}/:id`} render={(props) => <DetailCustomer {...props} />}  />

                <Route exact path={router.ListProduct} component={ListProduct} />
                <Route exact path={`${router.Product}`} render={(props) => <Product {...props} />}  />
                <Route exact path={`${router.Product}/:id`} render={(props) => <Product {...props} />}  />

                <Route exact path={router.ListStoreProductPrice} component={ListStoreProductPrice} />
                <Route exact path={`${router.StoreProductPrice}`} render={(props) => <StoreProductPrice {...props} />}  />
                <Route exact path={`${router.StoreProductPrice}/:id`} render={(props) => <StoreProductPrice {...props} />}  />

                <Route exact path={router.ListGift} component={ListGift} />
                <Route exact path={`${router.Gift}`} render={(props) => <Gift {...props} />}  />
                <Route exact path={`${router.Gift}/:id`} render={(props) => <Gift {...props} />}  />

                <Route exact path={router.ListMasterPlan} component={ListMasterPlan} />
                <Route exact path={`${router.MasterPlan}`} render={(props) => <MasterPlan {...props} />}  />
                <Route exact path={`${router.MasterPlan}/:id`} render={(props) => <MasterPlan {...props} />}  />

                <Route exact path={router.ListStoreUser} component={ListStoreUser} />
                <Route exact path={`${router.StoreUser}`} render={(props) => <StoreUser {...props} />}  />
                {/* <Route exact path={`${router.Store}`} render={(props) => <Store {...props} />}  />
                <Route exact path={`${router.Store}/:id`} render={(props) => <Store {...props} />}  /> */}

                <Route path={`${router.Data}/:id/:date`} render={(props) => <Data {...props} />}  />
                <Route exact path={`${router.DataOrder}/:id`} render={(props) => <DataOrder {...props} />}  />
                <Route  path={`${router.UpdateCustomerOrder}/:id/:store`} render={(props) => <UpdateCustomerOrder {...props} />}  />
                <Route  exact path={`${router.AddData}`} render={(props) => <AddData {...props} />}  />
                <Route exact path={`${router.AddDataOrder}/:id`} render={(props) => <AddDataOrder {...props} />}  />


                <Route exact path={router.ReportViewPlanStock} component={PlanStock} />
                <Route exact path={router.ReportViewSumary1} component={ReportSumary1} />
                <Route exact path={router.ReportViewSumary2} component={ReportSumary2} />
                <Route exact path={router.ReportViewSumary3} component={ReportSumary3} />



                <Route path={router.Page404} component={Page404} />
                <Redirect to={router.ListData} />
            </Switch>
          </Layout>
        );
    
        break;
      case 2:
        route = (
          <Layout>
            <Switch>
                <Route exact path={router.Report} component={Home} />
                
                <Route exact path={router.ListUser} component={ListUser} />
                <Route exact path={`${router.User}`} render={(props) => <User {...props} />}  />
                <Route exact path={`${router.User}/:id`} render={(props) => <User {...props} />}  />

                <Route exact path={router.ListStoreUser} component={ListStoreUser} />
                <Route exact path={`${router.StoreUser}`} render={(props) => <StoreUser {...props} />}  />
                {/*
                <Route exact path={router.ListStore} component={ListStore} />
                <Route exact path={`${router.Store}`} render={(props) => <Store {...props} />}  />
                <Route exact path={`${router.Store}/:id`} render={(props) => <Store {...props} />}  /> */}

                <Route exact path={router.ListData} component={ListData} />
                <Route exact path={`${router.Data}/:id`} render={(props) => <Data {...props} />}  />
                <Route exact path={`${router.DataOrder}/:id`} render={(props) => <DataOrder {...props} />}  />


                <Route path={router.Page404} component={Page404} />
                <Redirect to={router.ListData} />
            </Switch>
          </Layout>
        );
      //  console.log(router);
        break;
      case 3:
        route = (
          <Layout>
            <Switch>
                 <Route exact path={router.Report} component={Home} />
                <Route exact path={router.ListData} component={ListData} />
                <Route path={`${router.Data}/:id/:date`} render={(props) => <Data {...props} />}  />
                <Route exact path={`${router.DataOrder}/:id`} render={(props) => <DataOrder {...props} />}  />

                <Route exact path={router.ReportViewPlanStock} component={PlanStock} />
                <Route exact path={router.ReportViewSumary1} component={ReportSumary1} />
                <Route exact path={router.ReportViewSumary2} component={ReportSumary2} />
                <Route exact path={router.ReportViewSumary3} component={ReportSumary3} />


                <Route path={router.Page404} component={Page404} />
                <Redirect to={router.ListData} />
                
            </Switch>
          </Layout>
        );
        break;
    }

  }
  return ( <>
     {route}
  </>
  
  );
}


const mapStateToProps = (state:any) => {
  //  console.log(store)
  return {
    isAuth: state.auth.token !== null,
    profile:state.auth.profile
  }
}

export default connect(mapStateToProps, null)(withRouter(RootComponent));