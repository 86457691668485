export const filterTable = (province,stores,users)=> [
    {
        field: 'dateFrom',
        title: 'TỪ NGÀY',
        type: 'date',
        defaultValue: null,
        placeholder: 'Tìm kiếm theo...'
    },
    {
        field: 'dateTo',
        title: 'ĐẾN NGÀY',
        type: 'date',
        defaultValue: null,
        placeholder: 'Tìm kiếm theo...'
    },
    {
        field: 'prefix',
        title: 'Tỉnh thành',
        type: 'dropdown',
        defaultValue: '',
        placeholder: 'Tìm kiếm theo...',
        data:province,
        optionLabel:"provinceName", 
        optionValue:"provinceId" 
    },
    {
        field: 'storeId',
        title: 'Siêu thị',
        type: 'dropdownparent',
        defaultValue: 0,
        placeholder: 'Tìm kiếm theo...',
        data:stores,
        optionLabel:"storeName", 
        optionValue:"storeId",
        child:1,
    },
    {
        field: 'userId',
        title: 'Nhân viên',
        type: 'dropdownitem',
        defaultValue: 0,
        placeholder: 'Tìm kiếm theo...',
        data:users,
        optionLabel:"name", 
        optionValue:"userId",
        valueParent:0,
        parent:1,
        nameItem:"storeId"
    }
]

export const columnTable = [
    {
        field: 'shiftName',
        header: 'Item',
        width: 60,
        frozen:true,
        align: 'center',
        fontSize: 11
    },
    {
        field: 'shiftPlan',
        header: 'Plan',
        width: 100,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'shiftActual',
        header: 'Actual',
        width: 100,
        align: 'right',
        usedTemplate: true 
    },
    {
        field: 'percentPlan',
        header: '%',
        width: 100,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'kpiPlan',
        header: 'KPI / Session',
        width: 120,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'kpiActual',
        header: 'Actual/ Session',
        width: 120,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
   
    {
        field: 'kpiPercent',
        header: '% Actual / LPI',
        width: 100,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    }
]