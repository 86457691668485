import * as React from 'react';
import FormLayout from '../../components/form/index';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import * as router from "../../common/router";
import { Calendar } from 'primereact/calendar';
import {
    Link
} from "react-router-dom";
import * as api from '../../reducer/contains/api'
import {axiosWithHeaders} from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum'
import moment from 'moment';
 var _ = require('lodash');

const MasterPlan = (props: any) => {
    const [button, setButton] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [dataStore, setDataStore] = React.useState([]);
    const [listShift, setListShift] = React.useState([]);
    const [storeId, setStoreId] = React.useState(0);
    const [shiftId, setShiftId] = React.useState(0);
    const [planId, setPlanId] = React.useState(0);
    const [dateRun, setDateRun] = React.useState<Date>(new Date());
     const [sessionPlan, setSessionPlan] = React.useState(0);
  
    React.useEffect(() => {
        GetListStore();
        GetListShift();
        if (props.match.params.id !== undefined && props.match.params.id !== null){
            setButton(false);
            LoadData(props.match.params.id);
        }
       // onValueChangeStore(props.match.params.id)
    }, []);


    const GetListStore=()=>{
        axiosWithHeaders('GET',`${api.LIST_STORE_FULL}`,null)
        .then((data:any) => {
            if(data !== undefined && data !== null ){
               
                let response = data.data;
                let {status,message,result} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                    if(result !== undefined  && result !== null && result !== [] && result.length > 0 )
                    {
                        setDataStore(result)
                    }
                }
                else{
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                }
            }
            else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }
            
        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
    }

    const GetListShift=()=>{
        axiosWithHeaders('GET',`${api.LIST_SHIFT}`,null)
        .then((data:any) => {
            if(data !== undefined && data !== null ){
               
                let response = data.data;
                let {status,message,result} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                    if(result !== undefined  && result !== null && result !== [] && result.length > 0 )
                    {
                        setListShift(result)
                    }
                }
                else{
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                }
            }
            else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }
            
        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
    }
    const LoadData=(planId :any)=>{
        axiosWithHeaders('POST',`${api.GET_MASTER_PLAN}`,{planId: planId})
        .then((data:any) => {
            if(data !== undefined && data !== null ){
                let response = data.data;
                let {status,message} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                    const {planId , dateRun , storeId, shiftId,sessionPlan} =response.result;
                    setPlanId(planId)
                    setDateRun(new Date(dateRun))
                    setStoreId(storeId)
                    setShiftId(shiftId)
                    setSessionPlan(sessionPlan)
                    //setData(response.result)
                }
                else{
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                }
            }
            else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }
            
        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
    }
    const CheckData=()=>{
        if(storeId === undefined || storeId === 0)
        {
            props.error_success('error', 'Lỗi', "Vui lòng chọn siêu thị");
            return false;
        }
        if(shiftId === undefined || shiftId === 0)
        {
            props.error_success('error', 'Lỗi', "Vui lòng chọn siêu thị");
            return false;
        }
        return true;
    }
    const SaveData=()=>{
        if(!CheckData()){
            return false;
        }
        let dateYy = moment(dateRun).format('YYYY-MM-DD')
        var obj={planId,storeId,dateRun : dateYy ,shiftId,sessionPlan}
     //   console.log(data)
        axiosWithHeaders('POST',api.ADD_MASTER_PLAN, obj)
        .then((data:any) => {
            if(data !== undefined && data !== null ){
                let response = data.data;
                let {status,message} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                    props.error_success('success', 'Thông báo', 'Thêm mới plan thành công !');
                    props.history.push(router.ListMasterPlan);
                }
                else{
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra ! !!!" + message);
                }
            }
            else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" );}
            
        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
        
    }

    const SaveUpdateData=()=>{
        if(!CheckData()){
            return false;
        }
        let dateYy = moment(dateRun).format('YYYY-MM-DD')
        var obj={planId,storeId,dateRun : dateYy ,shiftId,sessionPlan}
     //   console.log(data)
        axiosWithHeaders('POST',api.ADD_MASTER_PLAN, obj)
        .then((data:any) => {
            if(data !== undefined && data !== null ){
                let response = data.data;
                let {status,message} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                    props.error_success('success', 'Thông báo', 'Thêm mới plan thành công !');
                    props.history.push(router.ListMasterPlan);
                }
                else{
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra ! !!!" + message);
                }
            }
            else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" );}
            
        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
        
    }

    const onValueChangeStore = (value) => {
        setStoreId(value)
    }
    // const onValueChangePrice = (e,product:any) => {
    //     let now = data;
    //     now.map((item :any,idx)=>{
    //         if(item.productName === product.productName && item.vietnameseName === product.vietnameseName)
    //         {
    //             item.price = e.value
    //         }
    //     })
    //     setData(now)
    // }

    const renderStore=()=>{

            return <div className="p-grid" style={{ marginTop: -7 }}>
            <div className="p-col-4 text-right">
                <label>Chọn Siêu Thị</label>
            </div>
            <div className="p-col-6">
                <Dropdown 
                    
                    value={storeId} 
                
                    name="storeName"
                    optionLabel="storeName" 
                    optionValue="storeId" 
                    options={dataStore} 
                    placeholder="Chọn siêu thị..." 
                    filter={true} 
                    filterPlaceholder="Tìm..." 
                    filterBy="storeId,storeName"
                                        
                    // filterMatchMode="contains"
                    style={{width : '100%'}} 
                    onChange={(e: any)=> onValueChangeStore(e.target.value)}
                />
  
            </div>
        </div>
    }

    const renderShift=()=>{

        return <div className="p-grid" style={{ marginTop: -7 }}>
        <div className="p-col-4 text-right">
            <label>Chọn Ca</label>
        </div>
        <div className="p-col-6">
            <Dropdown 
                
                value={shiftId} 
            
                name="shiftName"
                optionLabel="shiftName" 
                optionValue="shiftId" 
                options={listShift} 
                placeholder="Chọn ca..." 
                filter={true} 
                filterPlaceholder="Tìm..." 
                filterBy="shiftId,shiftName"
                                    
                // filterMatchMode="contains"
                style={{width : '100%'}} 
                onChange={(e: any)=> setShiftId(e.target.value)}
            />

        </div>
    </div>
}
    return (
        <div className="booking-layout">
            <FormLayout formTitle="CỬA HÀNG">
                <div className="p-grid">
                    <div className="p-col-4"></div>
                    <div className="p-col-4">
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                 <label>Ngày chạy </label>
                            </div>
                            <div className="p-col-6">
                                <Calendar
                                value={dateRun}
                                onChange={(e: any) => setDateRun(e.value)}
                                placeholder="Chọn  ngày"
                                style={{ width: "100%" }}
                                dateFormat="dd/mm/yy"
                            //  readOnlyInput
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange="2020:2021"
                                className="filter-item"
                            >
                            </Calendar>
                                  
                            </div>
                        </div>
                        {renderStore()}
                        {renderShift()}

                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Session Plan</label>
                            </div>
                            <div className="p-col-6">
                                <InputNumber  min={0} max={400000000} value={sessionPlan} placeholder="Session Plan" 
                                    style={{ width: '100%',textAlign: 'right' }} onChange={(e) => setSessionPlan(e.value)}   />
                                  
                            </div>
                        </div>
                        <div className="p-grid" style={{marginBottom: 10, marginTop: 10}}>
                            <div className="p-col-6">
                                <Link className="bp3-button bp3-large bp3-intent-primary btn-block btn-back" to={router.ListMasterPlan}>QUAY LẠI</Link>
                            </div>
                            <div className="p-col-6">
                                {button ?     <Button
                                    intent="success"
                                    className="btn-block"
                                    large
                                    onClick={()=>SaveData()}
                                >
                                    THÊM MỚI
                                </Button>:
                                <Button
                                    intent="primary"
                                    className="btn-block"
                                    large
                                    onClick={()=>SaveUpdateData()}
                                >
                                    CẬP NHẬT
                                </Button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="p-col-4"></div>
                </div>
            </FormLayout>
        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity,summary,detail) => dispatch(actions.error_success(severity,summary,detail))
    }
}

export default connect(null, mapDispatchToProps)(MasterPlan);
