import React, {useState, useEffect} from 'react';
import {filterTable, columnTable} from '../../common/data/DataTableProduct';
import { Button } from "@blueprintjs/core";
import {Fieldset} from 'primereact/fieldset';
import { connect } from 'react-redux';
import {
    withRouter,
    Link
} from "react-router-dom";
import * as router from '../../common/router';
import RenderFilter from '../../components/filter/index';
import FormLayout from '../../components/form/index';
import DataTables from '../../components/datatable/index';
import * as Status from '../../common/enum'
import * as api from '../../reducer/contains/api'
import {axiosWithHeaders} from '../../reducer/contains/axiosWrapper'
import * as actions from '../../reducer/actions/index';
import {
    GetURL
} from '../../common/config'
import Swal from 'sweetalert2';

const ListProduct = (props: any) =>{
    const [filter, setFilter] = useState<any>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [pageNo, setPageNo] = useState<number>(0);
    const [pageRows, setPageRows] = useState<number>(0);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [first, setFirst] = useState<number>(0);

    const renderFilter = () => {
        // console.log(filter);
        return(
            <Fieldset legend="TÌM KIẾM NÂNG CAO" className="booking-custom" style={{position: "relative"}}>
                <RenderFilter 
                    filters={filterTable} 
                    getFilterParam={(filters)=> getDataTable(filters, 0, 50)}
                />
            </Fieldset>
        )
    }

    const getDataTable = (filter: any, pageNo: number, pageRows: number) => {
        setLoading(true);
        let obj: any = {};
        if(Array.from(filter).length > 0){
            Array.from(filter).forEach((item: any) => {
                obj[item.field] = item.defaultValue;
            })
            obj["pageNo"] = pageNo + 1;
            obj["pageRows"] = pageRows;
            //  console.log(obj)
            axiosWithHeaders('POST',api.LIST_PRODUCT, obj)
            .then((data:any) => {
                if(data !== undefined && data !== null && Object.keys(data).length > 0){
                    let response = data.data;
                    let {status, result, message} = response;
                  
                    switch(status){
                        case Status.STATUS_RESPONSE_OK:
                            if(Array.from(result).length > 0){
                                let rowIndex: number = 1;
                                Array.from(result).forEach((item: any)=>{
                                    item["stt"] = (pageNo * pageRows) + rowIndex;
                                    rowIndex++;
                                })
                                setLoading(false);
                                setData(result);
                                setTotalRecords(result[0].countRow);
                                setFirst(pageNo * pageRows);
                                setFilter(filter);
                                setPageNo(pageNo);
                                setPageRows(pageRows)
                            }else{
                                setLoading(false);
                                setData([]);
                                setTotalRecords(0);
                                setFirst(0);
                                setFilter(filter);
                                setPageNo(0);
                                setPageRows(50)
                            }
                            break;
                        case Status.STATUS_RESPONSE_FORBIDDEN:
                            props.logOut()
                            break;
                        default:
                            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                            setLoading(false);
                            setData([]);
                            setTotalRecords(0);
                            setFirst(0);
                            setFilter(filter);
                            setPageNo(0);
                            setPageRows(50)
                            break;
                    }
                }
            }).catch(err => {
                console.log(err);
                setLoading(false);
                setData([]);
                setTotalRecords(0);
                setFirst(0);
                setFilter(filter);
                setPageNo(0);
                setPageRows(50)
                props.error_success('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
            });
        }
    }

    useEffect(() => {
   
        getDataTable(filterTable, 0, 50);
    }, []);


    const delCustomer = (e: any, productId: number) => {
        e.preventDefault();
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa sản phẩm này khỏi danh sách ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: "Hủy"
        }).then((result) => {
            if (result.value) {
                axiosWithHeaders('POST', api.DEL_PRODUCT, {productId: productId})
                .then((data: any) => {
                    if(data!==undefined && data!==null && Object.keys(data).length > 0){
                        let response = data;
                        let {status} = response;
                        switch(status){
                            case Status.STATUS_RESPONSE_OK:
                                Swal.fire("Thông báo", "Xóa dữ liệu thành công", "success")
                                getDataTable(filter, pageNo, pageRows);
                                break;
                            case Status.STATUS_RESPONSE_FORBIDDEN:
                                props.logOut();
                                break;
                            default:
                                Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi " + status, "error");
                                break;
                        }
                    }else{
                        Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi...!, ", "error");
                    }
                }).catch(err => {
                    console.log(err);
                    Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi ", "error");
                });
            }
        })
    }

    const cellTemplate = (row: any, cell: any) =>{
        let {field} = cell;
        // console.log(field);
        switch(field){
            case 'pathImage':
                return (
                    <>
                       <div style={{width:'100px',height:'100px'}}>
                           <img style={{width:'100%',height:'100%',objectFit:'contain'}} src={GetURL()+"/"+ row["pathImage"]} alt={row["pathImage"]} title={row["pathImage"]} />
                       </div>
                    </>
                )
            case 'action':
                return (
                    <>
                        <Link className="bp3-button bp3-small bp3-intent-primary add-booking" to={router.Product+"/"+ row["productId"]}>
                            <i className="fa fa-edit"></i>
                        </Link>
                        <Button icon="trash" intent="danger" small style={{marginLeft: 5}} onClick={(e)=> delCustomer(e, row["productId"])}/>
                    </>
                )
            default:
                return null;
        }
    }

    const renderDataTable = () => {
        if(columnTable!==undefined && columnTable!== null && Array.from(columnTable).length > 0){
            return (
                <DataTables 
                    columns={columnTable}
                    loading={isLoading}
                    data={data}
                    rows={pageRows}
                    totalRecords={totalRecords}
                    first={first}
                    onPage={(e)=> onPage(e)}
                    cellTemplate={cellTemplate}
                />
            )
        }
        return null
    }

    

    const onClickAdd = (e: any) => {
        e.preventDefault();
        props.history.push(router.Product);
    }

    const onPage = (e: any) => {
        let {page, rows} = e;
        getDataTable(filter, page, rows)
    }

    return (
        <>
            <FormLayout formTitle="Sản phẩm" showAdd={true} onClickAdd={(e: any)=> onClickAdd(e)}>
                {
                    renderFilter()
                }
                {
                    renderDataTable()
                }
            </FormLayout>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        logOut: () => dispatch(actions.logOut()),
        error_success: (severity,summary,detail) => dispatch(actions.error_success(severity,summary,detail)),
    }
}

export default connect(null, mapDispatchToProps)(withRouter(ListProduct)) as any;
