import {GetURL,GetURLReport} from '../../common/config';

export const LOGIN = `${GetURL()}/api/login`;

export const REFRESHTOKEN = `${GetURL()}/api/refresh-token`;

export const UPLOAD_SINGlE_FILE = `${GetURL()}/api/single`;

//warehouse
export const WAREHOUSE = `${GetURL()}/api/listwarehouse`;

//customer
export const CUSTOMER = `${GetURL()}/api/listcustomer`;

//position in warehouse
export const ROUTERBYWAREHOUSE = `${GetURL()}/api/listroutewarehouse`;

//data default
export const LISTDATADEFAULT = `${GetURL()}/api/listdatadefault`;

//booking
export const ADDBOOKING = `${GetURL()}/api/booking`;
export const UPDATEBOOKING = `${GetURL()}/api/updatebooking`;

export const FINISHBOOKING = `${GetURL()}/api/finishbooking`;
//DATA
export const LIST_DATA = `${GetURL()}/api/listdata`;
export const DETAIL_DATA = `${GetURL()}/api/detaildata`;
export const DETAIL_DATA_SHIFT = `${GetURL()}/api/detaildatashift`;
export const DETAIL_DATA_ID_UNGQUA = `${GetURL()}/api/detaildataidungqua`;
export const DETAIL_DATA_ID_SPECIAL = `${GetURL()}/api/detaildataidspecial`;
export const DETAIL_DATA_ID_BANNER = `${GetURL()}/api/detaildatabanner`;
export const DEL_IMAGE_DETAIL_DATA = `${GetURL()}/api/delimage`;
export const DATA_INSERT_IMAGE = `${GetURL()}/api/insertimage`;
export const DATA_UPDATE_INFOMATION = `${GetURL()}/api/updateinfomation`;
export const DATA_POST_INFOMATION = `${GetURL()}/api/postinfomation`;
export const DATA_POST_INFOMATION_WEB = `${GetURL()}/api/webpostinfomation`;
export const DATA_POST_INFOMATION_ORDER_WEB = `${GetURL()}/api/postinfomationorderweb`;
export const DEL_DATA = `${GetURL()}/api/deldata`;
export const DATA_SPECIAL_LIST_DATA = `${GetURL()}/api/listdetailspecial`;
export const DATA_INSERT_IMAGE_GROUP = `${GetURL()}/api/insertimagegroup`;
export const DATA_UPDATE_INFO_LOCATION_GROUP = `${GetURL()}/api/updateinfolocation`;
export const DATA_UPDATE_INFO_GIFT = `${GetURL()}/api/dataupdategift`;

export const DEL_CUSTOMER = `${GetURL()}/api/deldatacustomer`;
export const DEL_DATA_DATE = `${GetURL()}/api/deldatadate`;

export const DEL_INFOGROUP_DATA = `${GetURL()}/api/deldatainfogroup`;

export const DETAIL_INFO_CUSTOMER = `${GetURL()}/api/infodatacustomer`;
export const DETAIL_INSERT_IMAGE_CUSTOMER = `${GetURL()}/api/insertimagecustomer`;
export const DEL_IMAGE_CUSTOMER= `${GetURL()}/api/delimagecustomer`;
//User
export const LIST_USER = `${GetURL()}/api/listuser`;
export const LIST_USER_AUDIT = `${GetURL()}/api/listuseraudit`;
export const ADD_USER = `${GetURL()}/api/register`;
export const DETAIL_USER = `${GetURL()}/api/detailuser`;
export const UPDATE_USER = `${GetURL()}/api/updateuser`;
export const DEL_USER = `${GetURL()}/api/deluser`;
export const RESET_PASSWORD = `${GetURL()}/api/resetpassword`;
export const CHANGE_PASSWORD = `${GetURL()}/api/changepassword`;

export const LIST_PROVINCE = `${GetURL()}/api/listprovince`;

export const LIST_USER_ROLE = `${GetURL()}/api/listuserrole`;
export const LIST_USER_BY_STOREID = `${GetURL()}/api/listuserbystoreId`;


// Store User

export const LIST_STOREUSER = `${GetURL()}/api/liststoreuser`;
export const UPDATE_STORE_USER = `${GetURL()}/api/updatestoreuser`;
export const GET_ROUTE = `${GetURL()}/api/detailroute`;
export const DEL_STORE_USER = `${GetURL()}/api/delstoreuser`;


export const LIST_STORE = `${GetURL()}/api/liststore`;
export const LIST_STORE_BY_USERID = `${GetURL()}/api/liststorebyuserid`;
export const LIST_STORE_FULL = `${GetURL()}/api/liststorefull`;
export const ADD_STORE = `${GetURL()}/api/addstore`;
export const GET_STORE= `${GetURL()}/api/getstore`;
export const UPDATE_STORE = `${GetURL()}/api/updatestore`;
export const DEL_STORE = `${GetURL()}/api/delstore`;
export const UPDATE_COST_STORE = `${GetURL()}/api/updatecost`;

//fileupload
export const FILE_UPLOAD = `${GetURL()}/api/single`;

// Report 
export const REPORT_FORM_F = `${GetURLReport()}/api/report/reportformf`;
export const REPORT_ALL = `${GetURLReport()}/api/report/reportall`;
export const REPORT_HANGTON = `${GetURLReport()}/api/report/reportungqua`;
export const REPORT_HANGTONTHAP = `${GetURLReport()}/api/report/reportton`;
export const REPORT_THUHOI = `${GetURLReport()}/api/report/reportthuhoi`;
export const REPORT_UNGQUA= `${GetURLReport()}/api/report/reportungqua`;
export const REPORT_STORE_THANHTOAN= `${GetURLReport()}/api/report/reportstorethanhtoan`;
export const REPORT_STORE_INCENTIVE= `${GetURLReport()}/api/report/reportstoreincentive`;

export const VIEW_REPORT_PLAN_STOCK= `${GetURL()}/api/reportplanstock`;
export const VIEW_REPORT_SUMARY_1= `${GetURL()}/api/reportsumary1`;
export const VIEW_REPORT_SUMARY_3= `${GetURL()}/api/reportsumary3`;
export const VIEW_REPORT_SUMARY_2= `${GetURL()}/api/reportsumary2`;


// Product
export const LIST_PRODUCT = `${GetURL()}/api/listproduct`;
export const ADD_PRODUCT = `${GetURL()}/api/addproduct`;
export const GET_PRODUCT= `${GetURL()}/api/getproduct`;
export const UPDATE_PRODUCT = `${GetURL()}/api/updateproduct`;
export const DEL_PRODUCT = `${GetURL()}/api/delproduct`;


//Store  Product Price
export const LIST_STORE_PRODUCT_PRICE = `${GetURL()}/api/liststoreproductprice`;
export const ADD_STORE_PRODUCT_PRICE = `${GetURL()}/api/addstoreproductprice`;
export const GET_STORE_PRODUCT_PRICE= `${GetURL()}/api/getstoreproductprice`;
export const UPDATE_STORE_PRODUCT_PRICE = `${GetURL()}/api/updatestoreproductprice`;
export const DEL_STORE_PRODUCT_PRICE = `${GetURL()}/api/delstoreproductprice`;
export const LIST_PRODUCT_STORE_ID_PRICE = `${GetURL()}/api/mobilelistproduct?storeId=`;

//Gift
export const LIST_GIFT = `${GetURL()}/api/listgift`;
export const ADD_GIFT = `${GetURL()}/api/addgift`;
export const GET_GIFT= `${GetURL()}/api/getgift`;
export const UPDATE_GIFT = `${GetURL()}/api/updategift`;
export const DEL_GIFT = `${GetURL()}/api/delgift`;

// Shift
export const LIST_SHIFT = `${GetURL()}/api/listshift`;

// Master plan
export const LIST_MASTER_PLAN =     `${GetURL()}/api/listmasterplan`;
export const ADD_MASTER_PLAN =      `${GetURL()}/api/addmasterplan`;
export const GET_MASTER_PLAN=       `${GetURL()}/api/getmasterplan`;
export const UPDATE_MASTER_PLAN =   `${GetURL()}/api/updatemasterplan`;
export const DEL_MASTER_PLAN =      `${GetURL()}/api/delmasterplan`;

//CUstomer

export const GET_CUSTOMER_DETAIL =      `${GetURL()}/api/datacustomerdetail`;
export const UPDATE_CUSTOMER_ORDER =      `${GetURL()}/api/updatecustomerorder`;

// Province

export const LIST_PROVINCE_STORE =      `${GetURL()}/api/listprovincestore`;
export const LIST_DEFAULT_STORE_SUMARY =      `${GetURL()}/api/listdefaultsumary2`;