import * as React from "react";
import * as ReactDOM from "react-dom";

// bootstrap
import 'bootstrap/dist/css/bootstrap.css';
// sweetalert
import 'sweetalert2/dist/sweetalert2.min.css';
// blueprint
import 'normalize.css/normalize.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
// select
import '@blueprintjs/select/lib/css/blueprint-select.css';
// datetime
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
// table
import '@blueprintjs/table/lib/css/table.css';
// timezone
import '@blueprintjs/timezone/lib/css/blueprint-timezone.css';
// primereact
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css'
//custome css
// import '../src/assets/css/custom/customize.css'
import App from './App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter } from 'react-router-dom';




ReactDOM.render(

    <BrowserRouter>
        <App />
    </BrowserRouter>
    ,
    document.getElementById('root')
);

serviceWorker.unregister();
