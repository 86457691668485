import React, {useState, useEffect} from 'react';
import {filterTable, columnTable} from '../../common/data/DataTableReportSumary1';
import { Button } from "@blueprintjs/core";
import {Fieldset} from 'primereact/fieldset';
import { connect } from 'react-redux';
import {
    withRouter,
    Link
} from "react-router-dom";
import * as router from '../../common/router';
import RenderFilter from '../../components/filter/index';
import FormLayout from '../../components/form/index';
import TableGroupSumary1 from './TableGroupSumary1';
import * as Status from '../../common/enum'
import * as api from '../../reducer/contains/api'
import {axiosWithHeaders} from '../../reducer/contains/axiosWrapper'
import * as actions from '../../reducer/actions/index';
import {
    GetURL
} from '../../common/config'
import Swal from 'sweetalert2';

const ReportSumary1 = (props: any) =>{
    const [filter, setFilter] = useState<any>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [pageNo, setPageNo] = useState<number>(0);
    const [pageRows, setPageRows] = useState<number>(0);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [first, setFirst] = useState<number>(0);
    const [provinces, setProvinces] = useState<any>([]);
    const [stores, setStore] = useState<any>([]);
    useEffect(() => {
            axiosWithHeaders('GET',`${api.LIST_PROVINCE_STORE}`,null)
            .then((data:any) => {
                if(data !== undefined && data !== null ){
                    let response = data.data;
                    let {status,message,result} = response;
                    if(status === Status.STATUS_RESPONSE_OK)
                    {
                        setProvinces(result)
                     
                        getDataTable(filterTable(result), 0, 50);
                    }
                    else{
                        props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                    }
                }
                else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }
                
            }).catch(err => {
                props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
            });
            
    }, []);


    const renderFilter = () => {
        // console.log(filter);
        return(
            <Fieldset legend="TÌM KIẾM NÂNG CAO" className="booking-custom" style={{position: "relative"}}>
                <RenderFilter 
                    filters={filterTable(provinces)} 
                    getFilterParam={(filters)=> getDataTable(filters, 0, 50)}
                />
            </Fieldset>
        )
    }

    const getDataTable = (filter: any, pageNo: number, pageRows: number) => {
        setLoading(true);
        let obj: any = {};
        if(Array.from(filter).length > 0){
            Array.from(filter).forEach((item: any) => {
                obj[item.field] = item.defaultValue;
            })
            obj["pageNo"] = pageNo + 1;
            obj["pageRows"] = pageRows;
            //  console.log(obj)
            axiosWithHeaders('POST',api.VIEW_REPORT_SUMARY_1, obj)
            .then((data:any) => {
                if(data !== undefined && data !== null && Object.keys(data).length > 0){
                    let response = data.data;
                    let {status, result, message} = response;
                  
                    switch(status){
                        case Status.STATUS_RESPONSE_OK:
                            if(Array.from(result).length > 0){
                                let rowIndex: number = 1;
                                Array.from(result).forEach((item: any)=>{
                                    item["stt"] = (pageNo * pageRows) + rowIndex;
                                    rowIndex++;
                                })
                                setLoading(false);
                                setData(result);
                                setTotalRecords(result[0].countRow);
                                setFirst(pageNo * pageRows);
                                setFilter(filter);
                                setPageNo(pageNo);
                                setPageRows(pageRows)
                            }else{
                                setLoading(false);
                                setData([]);
                                setTotalRecords(0);
                                setFirst(0);
                                setFilter(filter);
                                setPageNo(0);
                                setPageRows(50)
                            }
                            break;
                        case Status.STATUS_RESPONSE_FORBIDDEN:
                            props.logOut()
                            break;
                        default:
                            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                            setLoading(false);
                            setData([]);
                            setTotalRecords(0);
                            setFirst(0);
                            setFilter(filter);
                            setPageNo(0);
                            setPageRows(50)
                            break;
                    }
                }
            }).catch(err => {
                console.log(err);
                setLoading(false);
                setData([]);
                setTotalRecords(0);
                setFirst(0);
                setFilter(filter);
                setPageNo(0);
                setPageRows(50)
                props.error_success('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
            });
        }
    }

 

 
    const cellTemplate = (row: any, cell: any) =>{
        let {field} = cell;
        // console.log(field);
        if(row.chain === 'TOTAL' || row.chain === 'AVERAGE')
        {
            switch(field){
                case 'shiftPlan':
                    return (
                        <>
                        {row.shiftPlan.toFixed(2)} 
                        </>
                    )
                case 'shiftActual':
                    return (
                        <>
                        {row.shiftActual.toFixed(2)} 
                        </>
                    )
                case 'percentPlan':
                    return (
                        <>
                        {row.percentPlan.toFixed(2)} %
                        </>
                    )
                case 'kpiPlan':
                    return (
                        <>
                        {row.kpiPlan.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})} 
                        </>
                    )
                case 'kpiPlanUTD':
                    return (
                        <>
                        {row.kpiPlanUTD.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})} 
                        </>
                    )
                case 'costOrder':
                    return (
                        <>
                        {row.costOrder.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})} 
                        </>
                    )
                case 'percentKpiUTD':
                    return (
                        <>
                        {row.percentKpiUTD.toFixed(2)} %
                        </>
                    )
                case 'percentKpi':
                    return (
                        <>
                        {row.percentKpi.toFixed(2)} %
                        </>
                    )
                default:
                    return null;
            }
        }
        else
        {
            switch(field){
                case 'shiftPlan':
                    return (
                        <>
                        {row.shiftPlan} 
                        </>
                    )
                case 'shiftActual':
                    return (
                        <>
                        {row.shiftActual} 
                        </>
                    )
                case 'percentPlan':
                    return (
                        <>
                        {row.percentPlan.toFixed(2)} %
                        </>
                    )
                case 'kpiPlan':
                    return (
                        <>
                        {row.kpiPlan.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})} 
                        </>
                    )
                case 'kpiPlanUTD':
                    return (
                        <>
                        {row.kpiPlanUTD.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})} 
                        </>
                    )
                case 'costOrder':
                    return (
                        <>
                        {row.costOrder.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})} 
                        </>
                    )
                case 'percentKpiUTD':
                    return (
                        <>
                        {row.percentKpiUTD.toFixed(2)} %
                        </>
                    )
                case 'percentKpi':
                    return (
                        <>
                        {row.percentKpi.toFixed(2)} %
                        </>
                    )
                default:
                    return null;
            }
        }
       
    }
    const rowClassNow=(data)=> {
        return {
            'row-accessories': data.chain === 'TOTAL'
        }
    }
    const renderDataTable = () => {
        if(columnTable!==undefined && columnTable!== null && Array.from(columnTable).length > 0){
            return (
                <TableGroupSumary1 
                    columns={columnTable}
                    loading={isLoading}
                    data={data}
                    rows={pageRows}
                    totalRecords={totalRecords}
                    first={first}
                    onPage={(e)=> onPage(e)}
                   cellTemplate={cellTemplate}
                />
            )
        }
        return null
    }

    

    const onClickAdd = (e: any) => {
        e.preventDefault();
        props.history.push(router.MasterPlan);
    }

    const onPage = (e: any) => {
        let {page, rows} = e;
        getDataTable(filter, page, rows)
    }

    return (
        <>
            <FormLayout formTitle="WHOLE PLAN BY CHAINS" showAdd={false} onClickAdd={(e: any)=> onClickAdd(e)}>
                { provinces !== [] && provinces.length>0 ?
                    renderFilter() : null
                }
                {
                    renderDataTable()
                }
            </FormLayout>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        logOut: () => dispatch(actions.logOut()),
        error_success: (severity,summary,detail) => dispatch(actions.error_success(severity,summary,detail)),
    }
}

export default connect(null, mapDispatchToProps)(withRouter(ReportSumary1)) as any;
