import * as React from 'react';
import FormLayout from '../../components/form/index';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import { InputText } from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import * as router from "../../common/router";
import {
    Link
} from "react-router-dom";
import * as api from '../../reducer/contains/api'
import {axiosWithHeaders} from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum'
// var _ = require('lodash');

const Store = (props: any) => {
    const [button, setButton] = React.useState(true);
    const [storeId, setstoreId] = React.useState('');
    const [storeCode,    setStoreCode] = React.useState('');
    const [storeName,    setStoreName] = React.useState('');
    const [ward,   setWard] = React.useState('');
    const [chain,   setChain] = React.useState('');
    const [storeAddress, setStoreAddress] = React.useState('');
    const [district, setDistrict] = React.useState('');
    const [kpiAM, setKPIAM] = React.useState(0);
    const [kpiPM, setKPIPM] = React.useState(0);
    const [shiftPlan, setShiftPlan] = React.useState(0);
    const [area, setArea] = React.useState('');
    const [provinceId, setProvinceId] = React.useState('');
    const [listprovince, setListProvince] = React.useState([]);
    
    React.useEffect(() => {
        if (props.match.params.id !== undefined && props.match.params.id !== null){
            setButton(false);
            axiosWithHeaders('POST',`${api.GET_STORE}`,{storeId: props.match.params.id})
            .then((data:any) => {
                if(data !== undefined && data !== null ){
                    let response = data.data;
                    let {status,message} = response;
                    if(status === Status.STATUS_RESPONSE_OK)
                    {
    
                       const{	storeId  ,storeCode   
                        ,storeName   
                        ,storeAddress   
                        ,ward   
                        ,district   
                        ,area   
                        ,chain   
                        ,kpiAM   
                        ,provinceId   
                        ,kpiPM   
                        ,shiftPlan 
                    } = response.result;
    
                        setstoreId(storeId);
                        setStoreCode(storeCode);
                        setStoreAddress(storeAddress);
                        setStoreName(storeName);
                        setWard(ward);
                        setDistrict(district);
                        setArea(area);
                        setChain(chain);
                        setProvinceId(provinceId);
                        setKPIAM(kpiAM);
                        setKPIPM(kpiPM);
                        setShiftPlan(shiftPlan);
                        GetProvince();
                    }
                    else{
                        props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                    }
                }
                else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }
                
            }).catch(err => {
                props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
            });
        }
        else
        {
            GetProvince();
        }
       
        
    }, []);
    const GetProvince=()=>{
        axiosWithHeaders('GET',`${api.LIST_PROVINCE}`,null)
        .then((data:any) => {
            if(data !== undefined && data !== null ){
                let response = data.data;
                let {status,message,result} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                   setListProvince(result)      
                }
                else{
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                }
            }
            else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }
            
        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });

    }
    const onValueChange = (e, field) => {
        switch(field){
            case 'storeCode':
                setStoreCode(e.target.value);
                break;
            case 'storeName':
                setStoreName(e.target.value);
                break;
            case 'storeAddress':
                setStoreAddress(e.target.value);
                break;
            case 'ward':
                setWard(e.target.value);
                break;
            case 'district':
                setDistrict(e.target.value);
                break;
            case 'area':
                setArea(e.target.value);
                break;
            case 'chain':
                setChain(e.target.value);
                break;
            case 'kpiAM':
                setKPIAM(e.target.value);
                break;
            case 'kpiPM':
                setKPIPM(e.target.value);
                break;
            case 'shiftPlan':
                setShiftPlan(e.target.value);
                break;
            case 'provinceId':
                setProvinceId(e.value);
                break;
            default:
                break;
        }
    }
    const CheckData=()=>{
        if(storeCode === undefined || storeCode === '')
        {
            props.error_success('error', 'Lỗi', "Vui lòng nhập mã cửa hàng");
            return false;
        }
        return true;
    }
    const SaveData=()=>{
        if(!CheckData()){
            return false;
        }
        var obj={storeCode   
            ,storeName   
            ,storeAddress   
            ,ward   
            ,district   
            ,area   
            ,chain   
            ,kpiAM   
            ,provinceId   
            ,kpiPM   
            ,shiftPlan }
        axiosWithHeaders('POST',api.ADD_STORE, obj)
        .then((data:any) => {
            if(data !== undefined && data !== null ){
                let response = data.data;
                let {status,message} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                    props.error_success('success', 'Thông báo', 'Thêm mới store thành công !');
                    props.history.push(router.ListStore);
                }
                else{
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra ! !!!" + message);
                }
            }
            else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" );}
            
        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
        
    }

    const SaveUpdateData=()=>{
        if(!CheckData()){
            return false;
        }
        var obj={storeId  ,storeCode   
            ,storeName   
            ,storeAddress   
            ,ward   
            ,district   
            ,area   
            ,chain   
            ,kpiAM   
            ,provinceId   
            ,kpiPM   
            ,shiftPlan
        }
        axiosWithHeaders('POST',api.UPDATE_STORE, obj)
        .then((data:any) => {
            if(data !== undefined && data !== null ){
                let response = data.data;
                let {status,message} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                    props.error_success('success', 'Thông báo', 'Cập nhat store thành công !');
                    props.history.push(router.ListStore);
                }
                else{
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra ! !!!" + message);
                }
            }
            else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" );}
            
        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
        
    }

    return (
        <div className="booking-layout">
            <FormLayout formTitle="CỬA HÀNG">
                <div className="p-grid">
                    <div className="p-col-4"></div>
                    <div className="p-col-4">
                        <div className="p-grid" style={{ marginTop: 30 }}>
                            <div className="p-col-4 text-right">
                                <label>Mã </label>
                            </div>
                            <div className="p-col-6">
                                <InputText value={storeCode} placeholder="Mã store"
                                    style={{ width: '100%' }} onChange={(e) => onValueChange(e, 'storeCode')} />
                            </div>
                        </div>
                        {/* <div className="p-grid">
                            <div className="p-col-4 text-right">
                                <label>Loại hình kinh doanh</label>
                            </div>
                            <div className="p-col-6">
                                <Dropdown 
                                    value={storeBusinessForm} 
                                    optionLabel="storeBusinessFormName" 
                                    optionValue="storeBusinessFormId" 
                                    options={listBusiness} 
                                    placeholder="Chọn Loại hình kinh doanh..." 
                                    filter={true} 
                                    filterPlaceholder="Tìm..." 
                                    filterBy="storeBusinessFormId,storeBusinessFormName"
                                    // filterMatchMode="contains"
                                    style={{width : '100%'}} 
                                    onChange={(e: any)=> onValueChange(e, 'storeBusinessForm')}
                                />
                            </div>
                        </div> */}
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Tên cửa hàng</label>
                            </div>
                            <div className="p-col-6">
                                <InputText value={storeName} placeholder="Tên cửa hàng"
                                    style={{ width: '100%' }} onChange={(e) => onValueChange(e, 'storeName')} />
                            </div>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Hệ thông phân phối</label>
                            </div>
                            <div className="p-col-6">
                                <InputText value={chain} placeholder="Hệ thông phân phối"
                                    style={{ width: '100%' }} onChange={(e) => onValueChange(e, 'chain')} />
                            </div>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Địa chỉ</label>
                            </div>
                            <div className="p-col-6">
                                <InputTextarea rows={4} value={storeAddress} placeholder="Địa chỉ"
                                    style={{ width: '100%' }} onChange={(e) => onValueChange(e, 'storeAddress')} />
                            </div>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Phường / Xã</label>
                            </div>
                            <div className="p-col-6">
                                <InputTextarea rows={4} value={ward} placeholder="Phường / Xã"
                                    style={{ width: '100%' }} onChange={(e) => onValueChange(e, 'ward')} />
                            </div>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Quận huyện</label>
                            </div>
                            <div className="p-col-6">
                                <InputText value={district} placeholder="Quận huyện"
                                    style={{ width: '100%' }} onChange={(e) => onValueChange(e, 'district')} />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-4 text-right">
                                <label>Tỉnh thành</label>
                            </div>
                            <div className="p-col-6">
                                <Dropdown 
                                    value={provinceId} 
                                    optionLabel="provinceName" 
                                    optionValue="provinceCode" 
                                    options={listprovince} 
                                    placeholder="Chọn Tỉnh thành..." 
                                    filter={true} 
                                    filterPlaceholder="Tìm..." 
                                    filterBy="provinceCode,provinceName"
                                    // filterMatchMode="contains"
                                    style={{width : '100%'}} 
                                    onChange={(e: any)=> onValueChange(e, 'provinceId')}
                                />
                            </div>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Vùng</label>
                            </div>
                            <div className="p-col-6">
                                <InputText value={area} placeholder="Vùng"
                                    style={{ width: '100%' }} onChange={(e) => onValueChange(e, 'area')} />
                            </div>
                        </div>

                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>KPI AM</label>
                            </div>
                            <div className="p-col-6">
                                <InputNumber  min={0} max={400000000} value={kpiAM} placeholder="KPI AM" 
                                    style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e, 'kpiAM')}   />
                                  
                            </div>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>KPI PM</label>
                            </div>
                            <div className="p-col-6">
                            <InputNumber  min={0} max={400000000} value={kpiPM} placeholder="KPI PM"
                                     style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e, 'kpiPM')} />
                            </div>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Số ca kế hoạch</label>
                            </div>
                            <div className="p-col-6">
                            <InputNumber  min={0} max={400000000} value={shiftPlan} placeholder="Số ca kế hoạch"
                                     style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e, 'shiftPlan')} />
                            </div>
                        </div>

                        <div className="p-grid" style={{marginBottom: 10, marginTop: 10}}>
                            <div className="p-col-6">
                                <Link className="bp3-button bp3-large bp3-intent-primary btn-block btn-back" to={router.ListStore}>QUAY LẠI</Link>
                            </div>
                            <div className="p-col-6">
                                {button ?     <Button
                                    intent="success"
                                    className="btn-block"
                                    large
                                    onClick={()=>SaveData()}
                                >
                                    THÊM MỚI
                                </Button>:
                                <Button
                                    intent="primary"
                                    className="btn-block"
                                    large
                                    onClick={()=>SaveUpdateData()}
                                >
                                    CẬP NHẬT
                                </Button>
                                }
                            </div>
                        {/* <div className="p-grid">
                            <div className="p-col-12 text-center">
                                {button ?
                                <Button
                                    intent="primary"
                                    large
                                    onClick={()=>SaveData()}
                                >
                                    Thêm mới
                                </Button> :
                                <Button
                                intent="primary"
                                large
                                onClick={()=>SaveUpdateData()}
                            >
                                Cập nhật
                            </Button>
                                }
                            </div> */}
                        </div>
                    </div>
                    <div className="p-col-4"></div>
                </div>
            </FormLayout>
        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity,summary,detail) => dispatch(actions.error_success(severity,summary,detail))
    }
}

export default connect(null, mapDispatchToProps)(Store);
