import '../../assets/css/login/login2.css';
import '../../assets/css/font-awesome/css/font-awesome.css';
import * as React from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import * as actions from '../../reducer/actions/index';

interface ILoginFormState {
    username: string | '';
    password: string | '';
}

const LoginForm: React.FC<ILoginFormState> = (props: any) => {

    const [username, setUsername] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');

    const onClickLogin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (username === "") {
            Swal.fire("Lỗi", "Không để trống tài khoản", "error");
            return false;
        }

        if (password === "") {
            Swal.fire("Lỗi", "Không để trống mật khẩu", "error");
            return false;
        }
        props.login(username, password);
    }

    const onKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.which === 13) {
            let loginButton = document.querySelector(".login-buton");
            (loginButton as HTMLButtonElement).click();
        }
    }

    const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value);
    };

    const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    return (
        <div className="form-login" style={{ margin: 'auto', display: 'flex' }} >
            <div className="form-content" style={{ display: 'flex' }}>
                <div className="form" style={{ backgroundColor: 'pink', width: '400px', height: '600px' }}>
                    <div className="form-row col-sm-12">
                        <div className="col-sm-12 text-center">
                            {/* <h2 style={{color:'white',fontWeight:'bolder'}}>SENKA</h2> */}
                            <div className="input-group">
                            <img src={"/Logo01.png"} style={{height:'100px',margin:'auto'}} className="header-brand-img" alt="lavalite" /> 
                            </div>
                        </div>
                    </div>
                    <div className="form-row col-sm-12" style={{ backgroundColor: 'white', padding: 0, margin: 0 }}>
                        <div className="form-row col-sm-12" style={{
                            backgroundColor: 'pink', height: '50px'
                            , padding: 10, margin: 0, textAlign: "center", color: 'white'
                        }}>
                            <h3 style={{ margin: 'auto'}}>CUSTOMER LOGIN</h3>
                        </div>
                        <div className="form-row col-sm-12" style={{ marginTop: 30 }}>
                            <div className="col-sm-12">
                                <label className="sr-only">Tài khoản</label>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <i className="fa fa-user"></i>
                                        </div>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control username"
                                        placeholder="Tài khoản"
                                        value={username}
                                        onChange={onUsernameChange}
                                        onKeyPress={onKeypress}
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="form-row col-sm-12" style={{ marginTop: 15 }}>
                            <div className="col-sm-12">
                                <label className="sr-only">Mật khẩu</label>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <i className="fa fa-lock"></i>
                                        </div>
                                    </div>
                                    <input
                                        type="password"
                                        className="form-control password"
                                        placeholder="********"
                                        value={password}
                                        onChange={onPasswordChange}
                                        onKeyPress={onKeypress}
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="form-row col-sm-12" style={{ marginTop: 30 }}>
                            <div style={{width:'150px',margin:'auto'}}>
                                <button className="btn btn-dark btn-block login-buton" onClick={onClickLogin}>
                                    LOGIN
                            </button>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
};

const mapStateToProps = state => {
    return {
        isLoading: state.auth.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        login: (username, password) => dispatch(actions.login(username, password)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
