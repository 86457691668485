import { combineReducers } from 'redux';
import auth from './auth';
import router from './router';
import datadefault from './datadefault';
import booking from './booking';
import filter from './filter';
import error from './error';
import breadcrumb from './breadcrumb'


const reducers = combineReducers({
    auth,
    router,
    datadefault,
    booking,
    filter,
    error,
    breadcrumb
});

export default reducers;