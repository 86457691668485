import * as React  from 'react';
import FormLayout from '../../components/form/index';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import { InputText } from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import {TabView,TabPanel} from 'primereact/tabview';
import * as router from "../../common/router";
import {
    Link
} from "react-router-dom";
import * as api from '../../reducer/contains/api'
import {axiosWithHeaders,axiosWithHeadersNoTokenUploadFile} from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum'
import {Fieldset} from 'primereact/fieldset';
import Lightbox from '../../components/Lightbox-component/index';
import {GetURL} from '../../common/config'
import Swal from 'sweetalert2';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import Location from './Location'
import moment from 'moment';
import { datadefault_fail } from '../../reducer/actions/datadefault';
 var _ = require('lodash');

const GroupImageLocation = (props: any) => {
  
    const [detail, setDetail] = React.useState<boolean>(false);
    const {data,store,title,questionId} = props;
 
    const [displayBasic, setDisplayBasic] = React.useState(false);
    const [displayBasic1, setDisplayBasic1] = React.useState(false);
    const [location , setLocation] =React.useState<any>(data !== undefined && data.location !== undefined ? data.location.answer : '0,0');
    const [dateQuestion1, setDateQuestion1] = React.useState<Date>(data !== undefined && data.location !== undefined ? new Date(data.location.dateCreate) : new Date());
    const [listFile, setListFile] = React.useState([]) as any;
    const onClick = (stateMethod) => {
        stateMethod(true);
    }

    const onHide = (stateMethod) => {
        stateMethod(false);
    }
    const UploadData=()=>{
        if(listFile === null || listFile === [] )
        {
            props.error_success('error', 'Lỗi', "Không có hình ảnh");
            return false;
        }
        if(dateQuestion1 === undefined || dateQuestion1 === null)
        {
            props.error_success('error', 'Lỗi', "Vui lòng chọn thời gian");
            return false;
        }
        axiosWithHeaders('POST', `${api.DATA_INSERT_IMAGE_GROUP}`, {list:listFile,dateSend: moment(dateQuestion1).format('YYYY-MM-DD HH:mm:ss.SSS'),infogroup:data.infogroup})
        .then((response: any) => {
            const {status, message } = response.data;
            switch(status){
                case Status.STATUS_RESPONSE_OK:
                  //  console.log(data[0].dataId)
                     props.reload();
                     setDisplayBasic(false);
                     setListFile([]);
                    break;
                default:
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                    break;
            }
        }).catch(err => console.log(err));
       // props.reload();
    }

    const UploadDataLocation=()=>{
    
        if(dateQuestion1 === undefined || dateQuestion1 === null)
        {
            props.error_success('error', 'Lỗi', "Vui lòng chọn thời gian");
            return false;
        }
        axiosWithHeaders('POST', `${api.DATA_UPDATE_INFO_LOCATION_GROUP}`, {dataId:data.dataId
            ,detailDataId:data.location.detailDataId,questionId:data.location.questionId
            , dateCreate: moment(dateQuestion1).format('YYYY-MM-DD HH:mm:ss.SSS'),infogroup:data.infogroup
            ,answer : location
        })
        .then((response: any) => {
            const {status, message } = response.data;
            switch(status){
                case Status.STATUS_RESPONSE_OK:
                  //  console.log(data[0].dataId)
                     props.reload();
                     setDisplayBasic1(false);
                    break;
                default:
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                    break;
            }
        }).catch(err => console.log(err));
       // props.reload();
    }


    const renderFooter = (stateMethod) => {
        return (
            <div>
                <Button title="Yes" icon="saved" onClick={() => UploadData()} >
                        <label>Có</label>
                </Button>
                <Button title="No" icon="cross" onClick={() => onHide(stateMethod)} className="p-button-secondary" >
                        <label>Không</label>
                </Button>
            </div>
        );
    }
    const renderFooterLocation = () => {
        return (
            <div>
                <Button title="Yes" icon="saved" onClick={() => UploadDataLocation()} >
                        <label>Có</label>
                </Button>
                <Button title="No" icon="cross" onClick={() => onHide(setDisplayBasic1)} className="p-button-secondary" >
                        <label>Không</label>
                </Button>
            </div>
        );
    }
    const DelImage=(item)=>{
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa hình ảnh này không ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: "Hủy"
        }).then((result) => {
            if (result.value) {
                axiosWithHeaders('POST', api.DEL_IMAGE_DETAIL_DATA, {detailDataId: item.detailDataId})
                .then((response: any) => {
                    if(response!==undefined && response!==null && Object.keys(response).length > 0){
                        let {status,message} = response.data;
                        switch(status){
                            case Status.STATUS_RESPONSE_OK:
                                Swal.fire("Thông báo", "Xóa dữ liệu thành công", "success")
                                props.reload();
                                break;
                            default:
                                Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi " + status, "error");
                                break;
                        }
                    }else{
                        Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi...!, ", "error");
                    }
                }).catch(err => {
                    console.log(err);
                    Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi...!, ", "error");
                });
            }
        })
       
    }
    const renderImage =()=>{
        const {detail} = data;
        if(detail !== undefined && detail !== null && detail.length > 0)
        {
            let arr = [] as any;
            _.forEach(detail, function (value) {
                arr.push({
                    src: GetURL()+'/'+ value.answer,
                    title: value.questionName,
                    description: value.questionName,
                    datesend: value.dateSend,
                    detailDataId: value.detailDataId
                });
            });
            
            return <Lightbox images={arr}            
                renderImageFunc={(idx, image, toggleLightbox) => {
                    return (
                        <div key={idx} className='p-col-4'
                        >
                            <div className="row" style={{ position: "relative" }}>
                                <div className='p-col-12'>
                                    <img
                                        alt={image.title}
                                        key={idx}
                                        src={image.src}
                                        className='img-circle'
                                        style={{padding:'15px',width: '100%', objectFit: 'contain',maxHeight:'350px' }}
                                        onClick={toggleLightbox.bind(null, idx)} />
                                    <div style={{ bottom: 0, position: "absolute", width: '100%', textAlign: "center" }}>
                    <p style={{ color: 'red'}}>{image.datesend}</p>
                                    </div>
                                </div>
                                {props.profile.roleId ===1 || props.profile.roleId === 2?
                                <div style={{ top: 0, position: "absolute", right: 15 }}>
                                        <Button icon="trash" intent="danger" small style={{marginLeft: 5}} onClick={()=>DelImage(image)} />
                                </div> : null}
                            </div>
                        </div>
                    )
                }}
            />
        }
        return null;
    }
    const renderButton =()=>{
        if(props.profile.roleId ===1 || props.profile.roleId === 2){
            return <><div className='p-col-12' style={{position:'relative'}}>
            <Button icon="plus" intent="success" large style={{marginLeft: 5, position:'absolute',top:'-60px',right:'10px'}}   onClick={() => onClick(setDisplayBasic)} /> 
           {data.location !== undefined && data.location !== null ? <Button icon="locate" intent="warning" large style={{marginLeft: 5, position:'absolute',top:'-60px',right:'120px'}}   onClick={() => onClick(setDisplayBasic1)} /> :null}
            <Button icon="trash" intent="danger" large style={{marginLeft: 5, position:'absolute',top:'-60px',right:'60px'}}  onClick={(e)=> delCustomer(e, data)} /> 
             </div>
             </>
        }
        return null
     
      
    }

    const delCustomer = (e: any, data: any) => {
        e.preventDefault();
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa thông tin này khỏi danh sách ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: "Hủy"
        }).then((result) => {
            if (result.value) {
                axiosWithHeaders('POST', api.DEL_INFOGROUP_DATA, {dataId:data.dataId,infogroup:data.infogroup})
                .then((data: any) => {
                    if(data!==undefined && data!==null && Object.keys(data).length > 0){
                        let response = data;
                        let {status} = response;
                        switch(status){
                            case Status.STATUS_RESPONSE_OK:
                                Swal.fire("Thông báo", "Xóa dữ liệu thành công", "success")
                                props.reload();
                                break;
                            case Status.STATUS_RESPONSE_FORBIDDEN:
                                props.logOut();
                                break;
                            default:
                                Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi " + status, "error");
                                break;
                        }
                    }else{
                        Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi...!, ", "error");
                    }
                }).catch(err => {
                    console.log(err);
                    Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi ", "error");
                });
            }
        })
    }

    const convertImageto64 = (file) => {
        var f = new FormData();
        f.append("file", file)
        axiosWithHeadersNoTokenUploadFile('POST', `${api.FILE_UPLOAD}`, f)
            .then((response: any) => {
                if (response !== undefined && response.data.filePath !== undefined) {
                    var obj = {
                        dataId: data.dataId,
                        questionId: questionId,
                        answer: response.data.filePath,
                        dateCreate: dateQuestion1
                    }
                    let list = [...listFile];
                    //let list = listFile;
                    list.push(obj); 
                    setListFile(list)
                  
                }
            }).catch(err => console.log(err));
    }
    const onChangeFile = (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const fileList = Array.from(files);
          
            fileList.map((image: any) => {
                convertImageto64(image)
            });
         
        }
    }
    const renderImagePreview=()=>{
        let arr= [] as any
        if(listFile !== undefined && listFile !== null && listFile.length > 0)
        {
            listFile.map((item:any,idx)=>(
               item !== null && item !== undefined ? arr.push(<div key={idx} className='col-md-3'>
                    <img style={{width:'100%',height:'150px'}} src={GetURL()+'/'+item.answer} alt={item.questionId} />
                </div>)
                :null
            ))
        }
        return arr
    }

    const renderLocation = () => {
        return ( data.location !== undefined && data.location !== null ? <Location location={data.location.answer} /> : null
        )

    }
    return  ((data !== undefined && data !== null ) ? <>
        <Fieldset legend={props.title} toggleable={true} className="fieldset-group-image" >
            {renderButton()}
            <div className="p-grid class-store">
                <div className='p-col-6' style={{margin:'auto',textAlign:'center'}} >
                    {data.location !== undefined && data.location !== null ? <>
                        <h3 className='p-col-12' >Thời gian thực hiện </h3>
                            <h3 className='p-col-12' style={{fontWeight:'bold'}} >
                                {moment(data.location.dateCreate).hour()} giờ {moment(data.location.dateCreate).minute()} phút  ngày {moment(data.location.dateCreate).date()} tháng {moment(data.location.dateCreate).month()+1} năm {moment(data.location.dateCreate).year()}
                                </h3></>
                        :<></>}
                    </div>
                <div className="p-col-6">
                    {renderLocation()}
                </div>
            </div>
        
            {renderImage()}
            <Dialog header="Thêm mới hình ảnh" visible={displayBasic} style={{ width: '70%'}}
            contentStyle={{  height: '600px' }}
            onHide={() => onHide(setDisplayBasic)} 
            footer={renderFooter(setDisplayBasic)}
            maximizable={true}
        >
            <div className='p-col-12'>
            <div className='row p-col-12'>
                  
                    <div className='row p-col-12'>
                        <label className="p-col-4">Thời gian thực hiện</label>
                        <Calendar className="p-col-3" showTime={true} hourFormat="24" value={dateQuestion1} dateFormat="dd/mm/yy" touchUI
                         onChange={(e: any) => setDateQuestion1(e.value)}></Calendar>
                    </div>
                </div>
                <div className='row p-col-12'>
                    <div className='row p-col-12'>
                            <label className="p-col-4">{title}</label>
                        <input type="file"
                            name="import1"
                            className="p-col-4" multiple accept="image/*"
                            onChange={(event) => onChangeFile(event)}
                        />
                       
                    </div>
                </div>
                <div className='row p-col-12'>
                        {renderImagePreview()}
                </div>
            </div>
        </Dialog>
        <Dialog header="Cập nhật thời gian checkin checkout" visible={displayBasic1} style={{ width: '70%'}}
            contentStyle={{  height: '600px' }}
            onHide={() => onHide(setDisplayBasic1)} 
            footer={renderFooterLocation()}
            maximizable={true}
        >
            <div className='p-col-12'>
            <div className='row p-col-12'>
                  
                    <div className='row p-col-12'>
                        <label className="p-col-4">Thời gian thực hiện</label>
                        <Calendar className="p-col-3" showTime={true} hourFormat="24" value={dateQuestion1} dateFormat="dd/mm/yy" touchUI
                         onChange={(e: any) => setDateQuestion1(e.value)}></Calendar>
                    </div>
                </div>
                <div className='row p-col-12'>
                            <div className="p-col-4">
                                <label>Định vị</label>
                            </div>
                            <div className="p-col-6">
                                <InputText value={location} placeholder="Vui lòng nhập Định vị..."
                                    style={{ width: '100%' }} onChange={(e:any) => setLocation(e.target.value)} />
                            </div>
                </div>
                <div className='row p-col-12'>
                        <img src={"/Location.png"} alt="Location" style={{width:'100%'}} />
                </div>

                <div className='row p-col-12'>
                        {renderImagePreview()}
                </div>
            </div>
        </Dialog>
        </Fieldset>
        
    </> : null
    )
}
//</DataTable>   >  
const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity,summary,detail) => dispatch(actions.error_success(severity,summary,detail))
    }
}
const mapStateToProps = (state:any) => {
    return {
      profile:state.auth.profile
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(GroupImageLocation);