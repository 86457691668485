import * as React from 'react';
import FormLayout from '../../components/form/index';
import {filterTable, columnTable} from '../../common/data/DataDetailCustomerOrder';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import DataTables from '../../components/datatable/index';
 var _ = require('lodash');

const GroupGiftCustomer = (props: any) => {
    const {data,column} = props;
    const [columnTableNow, setColumnTable] = React.useState<any>({});
    React.useEffect(() => {
        setColumnTable(columnTable(column))
    }, [column]);
    const cellTemplate = (row: any, cell: any) =>{
        let {field} = cell;
        // console.log(field);
        
            switch(field){
                case 'nameCustomer':
                    return (
                        <>
                        {row.nameCustomer} 
                        </>
                    )
                case 'gift1':
                case 'gift2':
                case 'gift3':
                case 'gift4':
                case 'gift5':
                    return (row[field] !== 0 && row[field] !== '' && row[field] !== null ?
                        <>
                            {row[field]} 
                       </> :
                        <>
                           -
                        </>
                   )
                default:
                    return (row[field] !== undefined && row[field] !== null && row[field] !== 0 ?
                         <>
                             {row[field].toString()} 
                        </> :
                         <>
                            -
                         </>
                    )
            }
       
    }

    const renderDataTable = () => {
        if(columnTableNow!==undefined && columnTableNow!== null && Array.from(columnTableNow).length > 0){
            return (
                <DataTables 
                    columns={columnTableNow}
                    data={data}
                    rows={1}
                    totalRecords={1}
                    first={1}
                   // onPage={(e)=> onPage(e)}
                    cellTemplate={cellTemplate}
                    frozenWidth="300px"
                    className="table-data-customer"
                />
            )
        }
        return null
    }
    return (
        <div>
            <FormLayout formTitle="THÔNG TIN KHÁCH HÀNG">
               
                {renderDataTable()}
               
            </FormLayout>
        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity, summary, detail) => dispatch(actions.error_success(severity, summary, detail))
    }
}
const mapStateToProps = (state:any) => {
    return {
      profile:state.auth.profile
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(GroupGiftCustomer);
