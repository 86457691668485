import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import FormLayout from '../../components/form/index';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Dropdown} from 'primereact/dropdown';
import { Button } from "@blueprintjs/core";
import { listRole} from '../../common/data';
import * as api from '../../reducer/contains/api';
import {
    withRouter,
    Link
} from "react-router-dom";
import * as router from "../../common/router";
import {axiosWithHeaders} from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum';

const _ = require("lodash");

const User = (props: any) =>{
    const {profile} = props;
    const [username, setUsername] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [roleId, setRoleId] = useState<number>(0);
    const [password, setPassword] = useState<string>("");
    const [userId, setUserId] = useState<number>(0);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const getDetailUser = (userId: number) => {
        axiosWithHeaders('GET',`${api.DETAIL_USER}?userId=${userId}`,null)
            .then((data:any) => {
                if(data !== undefined && data !== null ){
                    let response = data.data;
                    let {status,message, result} = response;
                    if(status === Status.STATUS_RESPONSE_OK)
                    {
                        if(result!==undefined && result!==null && Object.keys(result).length > 0){
                        
                                let {
                                    userId,
                                    name,
                                    roleId,
                                    username
                                } = result;
                        
                                setUserId(userId);                        
                                setName(name);
                                setRoleId(roleId);
                                setUsername(username);
                          
                        }
                    }
                    else{
                        props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                    }
                }
                else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }
                
            }).catch(err => {
                props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
            });
    }

    // console.log(listTransportation);
    useEffect(()=>{
        let userId = (props.match.params.id);
        if(userId!==undefined && userId!== null && isNaN(userId) !==true ){
            setIsUpdate(true);
            // setUserId(userId);
            getDetailUser(userId);
        }
    }, [])

    const renderListRole = () =>{
        return (
            <div className="p-grid">
                <div className="p-col-4 text-right">
                    <label>Quyền truy cập</label>
                </div>
                <div className="p-col-6">
                    <Dropdown 
                        value={roleId} 
                        options={listRole(profile.roleId)} 
                        onChange={(e: any)=> setRoleId(e.target.value)}
                        filter={true} 
                        filterPlaceholder="Tìm..." 
                        filterBy="label,value"
                        placeholder="Chọn quyền truy cập..."
                        filterMatchMode="contains"
                        style={{width: "100%"}}
                    />
                </div>
            </div>
        )
    }

    const updateData  = (e: any, api: string) =>{
        e.preventDefault();
        let objUpdate: any = {         
            username: username,          
            name: name,         
            roleId: roleId, 
            password: password,
        }
        if(isUpdate){
            objUpdate.userId = userId;
          
        }
        axiosWithHeaders('POST',api, objUpdate)
        .then((data:any) => { 
            if(data !== undefined && data !== null ){
                let response = data.data;
                let {status,message} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                    props.error_success('success', 'Thông báo', 'Cập nhật nhân viên thành công !');
                    props.history.push(router.ListUser)
                }
                else{
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra ! !!!" + message);
                }
            }
            else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" );}
            
        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });

    }

    return (
        <div className="booking-layout add-user">
            <FormLayout formTitle="NHÂN VIÊN">
               
                <div className="p-grid">
                    <div className="p-col-4">
                    </div>
                    <div className="p-col-4">
                        {
                            renderListRole()
                        }
                          <div className="p-grid">
                            <div className="p-col-4 text-right">
                                <label>Tên đăng nhập</label>
                            </div>
                            <div className="p-col-6">
                                <InputText 
                                    value={username} 
                                    placeholder="Nhập tài khoản...." 
                                    onChange={(e: any)=> setUsername(e.target.value)} 
                                    style={{width: "100%"}}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-4 text-right">
                                <label>Họ và tên</label>
                            </div>
                            <div className="p-col-6">
                                <InputText 
                                    value={name} 
                                    placeholder="Nhập họ tên...." 
                                    onChange={(e: any)=> setName(e.target.value)} 
                                    style={{width: "100%"}}
                                />
                            </div>
                        </div>
                        {
                            !isUpdate && (
                                <div className="p-grid">
                                    <div className="p-col-4 text-right">
                                        <label>Mật khẩu</label>
                                    </div>
                                    <div className="p-col-6">
                                        <Password 
                                            value={password} 
                                            placeholder="Nhập mật khẩu...." 
                                            onChange={(e: any)=> setPassword(e.target.value)} 
                                            style={{width: "100%"}}
                                        />
                                    </div>
                                </div>
                            )
                        }
                     
                      
                         <div className="p-grid" style={{marginBottom: 10, marginTop: 10}}>
                            <div className="p-col-6">
                                <Link 
                                    className="bp3-button bp3-large bp3-intent-primary btn-block btn-back" 
                                    to={router.ListUser}
                                >QUAY LẠI</Link>
                            </div>
                            <div className="p-col-6">
                                {!isUpdate ? <Button
                                    intent="success"
                                    className="btn-block"
                                    large
                                    onClick={(e)=> updateData(e, api.ADD_USER)}
                                    disabled={(roleId === 0) ? true: false}
                                >
                                    THÊM MỚI
                                </Button>:
                                <Button
                                    intent="primary"
                                    className="btn-block"
                                    large
                                    onClick={(e)=> updateData(e, api.UPDATE_USER)}
                                    disabled={(roleId === 0) ? true: false}
                                >
                                    CẬP NHẬT
                                </Button>
                                }
                            </div>
                        </div>

                    </div>
                    <div className="p-col-4"></div>
                </div>
            </FormLayout>
        </div>
    );
}
const mapStateToProps = state => {
    // console.log(state.datadefault.listCost);
    return {
        profile: state.auth.profile
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getListDataDefault: () => dispatch(actions.getListDataDefault()),
        error_success: (severity,summary,detail) => dispatch(actions.error_success(severity,summary,detail)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(User));