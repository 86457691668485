export const filterTable = [
    {
        field: 'prefix',
        title: 'Từ khóa',
        type: 'string',
        defaultValue: '',
        placeholder: 'Tìm kiếm theo...'
    }
]

export const columnTable = (columnProduct:any)=> {
    
    let obj =[

    {
        field: 'stt',
        header: '#',
        width: 20,
        align: 'left',
        frozen:true,
        fontSize: 11
    },
    {
        field: 'dateSend',
        header: 'Ngày chạy',
        width: 80,
        align: 'left',
        frozen:true,
        fontSize: 11
    },
    // {
    //     field: 'shiftName',
    //     header: 'Ca',
    //     width: 40,
    //     align: 'left',
    //     frozen:true,
    //     fontSize: 11
    // },
    {
        field: 'nameCustomer',
        header: 'Tên khách hàng',
        width: 160,
        align: 'left',
        frozen:true,
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'phoneCustomer',
        header: 'SDT',
        width: 100,
        align: 'left',
        fontSize: 11
    },
    // {
    //     field: 'costOrder',
    //     header: 'Sale Volume (Actual)',
    //     width: 100,
    //     align: 'right',
    //     fontSize: 11,
    //     usedTemplate: true
    // },
    {
        field: 'gift1',
        header: 'Không trúng',
        width: 50,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'gift2',
        header: 'Card 10K',
        width: 50,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'gift3',
        header: 'Đế DT',
        width: 50,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'gift4',
        header: 'Tai phone',
        width: 50,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'gift5',
        header: 'Loa',
        width: 50,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'action',
        header: '',
        width: 80,
        align: 'left',
        fontSize: 11,
        usedTemplate: true
    }
    ]
    if(columnProduct !== undefined && columnProduct !==[] && columnProduct.length> 0)
    {
        columnProduct.reverse().map(item=>{
            obj.splice(9, 0, item)
        })
       
    }
    return obj
}