import * as React from 'react';
import FormLayout from '../../components/form/index';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import * as router from "../../common/router";
import {
    Link
} from "react-router-dom";
import * as api from '../../reducer/contains/api'
import { axiosWithHeaders,axiosWithHeadersNoTokenUploadFile,axiosWithHeadersDownloadFile } from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum'
import TableThuDoi from './TableThuDoi'
import TableBanner from './TableBanner'
import ViewDataShift from './ViewDataShift'
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import {InputNumber} from 'primereact/inputnumber';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';
 var _ = require('lodash');

const Data = (props: any) => {
    const [button, setButton] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const [storeId, setstoreId] = React.useState('');
    const [ampg, setAMPG] = React.useState<any>({});
    const [pmpg, setPMPG] = React.useState<any>({});
    const [amsup, setAMSUP] = React.useState({});
    const [pmsup, setPMSUP] = React.useState({});
    const [dateFrom, setDateFrom] = React.useState<Date>();
    const [dateTo, setDateTo] = React.useState<Date>();
    const [listdetail, setListDetail] = React.useState<any>();
   // const [banner, setBanner] = React.useState([]);
    const [dateSend, setDateSend] = React.useState();
    const [ungqua, setUngqua] = React.useState([]);
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [dateSendData, setDateQuestion1] = React.useState<Date>(new Date());
    const [lng, setLng] = React.useState<any>(0);
    const [lat, setLat] = React.useState<any>(0);
    const [ungbalo, setUngBalo] = React.useState(0);
    const [ungbanh, setUngbanh] = React.useState(0);
    const [thubalo, setThuBalo] = React.useState(0);
    const [thubanh, setThubanh] = React.useState(0);
    const [doibalo, setDoiBalo] = React.useState(0);
    const [doibanh, setDoibanh] = React.useState(0);
    const [thuthe, setThuThe] = React.useState(0);
    const [hangmilo, setHangMilo] = React.useState(0);
    const [userId, setUserId] = React.useState(0);
    const [roleId, setRoleId] = React.useState(0);
    const [showThuDoi, setShowThuDoi] = React.useState(false);
    const [showUngQua, setShowUngQua] = React.useState(false);
    const [listUserThuDoi, setListUserThuDoi] = React.useState([]);
    const [listUserUngQua, setListUserUngqua] = React.useState([]);
    const [listFile, setListFile] = React.useState([]) as any;
    React.useEffect(() => {
        if (props.match.params.id !== undefined && props.match.params.id !== null && props.match.params.date !== undefined && props.match.params.date !== null) {
            setstoreId( props.match.params.id);
            setDateSend(props.match.params.date)
            LoadData(props.match.params.id,props.match.params.date);
            LoadUser();
        }
        else {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!");
            props.history.push(router.ListData);
        }


    }, []);
    const ReloadPage=(index)=>{ 
         setActiveIndex(index);
         LoadData(storeId,dateSend);
        // LoadUser();
    }
    const LoadData=(storeId:any,dateSend:any)=>{
        axiosWithHeaders('POST', `${api.DETAIL_DATA_SHIFT}`, { storeId: props.match.params.id,dateSend:dateSend})
        .then((data: any) => {
            if (data !== undefined && data !== null) {
                let response = data.data;
                let { status, message, result } = response;

                if (status === Status.STATUS_RESPONSE_OK) {
                    const { ampg,amsup,pmpg,pmsup,listdetail} = result;
                  //  setstoreId(storeId);
                    setListDetail(listdetail);

                    setAMPG(ampg);
                    setAMSUP(amsup);
                    setPMPG(pmpg);
                    setPMSUP(pmsup);
                }
                else {
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                }
            }
            else { props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }

        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
    }
    const DownloadReport = (apiReport,nameFile) => {
        setIsLoading(true)
        let from = ''
        if (dateFrom !== undefined &&   dateFrom !== null)
            from = moment(dateFrom).format('YYYYMMDD')
        let to = ''
        if (dateTo !== undefined  && dateTo !== null)
            to = moment(dateTo).format('YYYYMMDD')
      //  console.log({ dateFrom: from, dateTo: to ,storeId:parseInt(storeId)})
        axiosWithHeadersDownloadFile('POST', apiReport, { dateFrom: from, dateTo: to ,storeId:parseInt(storeId)}, nameFile)
            .then((response: any) => {
                setIsLoading(false);
                // console.log(data)
            }).catch(err => {
                setIsLoading(false);
                props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
            });
    }
    const LoadUser = () => {
        axiosWithHeaders('POST', api.LIST_USER_BY_STOREID,{storeId})
            .then((response: any) => {
                if (response !== undefined && response !== null && Object.keys(response).length > 0) {
                 
                    let { status, message, result } = response.data;
                    switch (status) {
                        case Status.STATUS_RESPONSE_OK:
                       
                           let thudoi = _.filter(result, function(o) { return o.roleId === 6; })
                           let ungqua =_.filter(result, function(o) { return o.roleId === 5; })
                           // console.log(thudoi,ungqua)
                            setListUserThuDoi(thudoi);
                            setListUserUngqua(ungqua);
                            break;
                        case Status.STATUS_RESPONSE_FORBIDDEN:
                            props.logOut();
                            break;
                        default:
                            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                            break;
                    }
                } else {
                    props.error_success('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
                }
            }).catch(err => {
                console.log(err);
                props.error_success('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
            });
    }
    // const renderDetail = () => {
    //     if (detail !== undefined && detail !== null) {
    //         return <div className="p-grid class-store">
    //             <div className="p-col-4">
    //                 <p style={{ fontSize: '30px', fontWeight: 'bolder' }} >{detail.storeName}</p>
    //                 <p>Địa chỉ : {detail.storeAddress}</p>
    //                 <p>Mã điểm đổi giải : {detail.storeCode}</p>
    //                 {detail.status !== undefined && detail.status !== null ? 
    //                 <Button   large style={{marginTop:25,width:250}}  className={detail.status ? "bp3-intent-success" :"bp3-intent-danger"}>
    //                     <label>{detail.status ? "ĐỒNG Ý" : "KHÔNG ĐỒNG Ý"}</label>
    //                 </Button> : null}
    //                 {detail.note !== undefined && detail.note !== null ? 
    //                 <h5 style={{paddingTop:5}}>Ghi chú: {detail.note}</h5> : null}
    //             </div>
    //             <div className="p-col-4">
    //                 <p>Số tài khoản : {detail.storeCodeBank}</p>
    //                 <p>Ngân hàng : {detail.storeBank}</p>
    //                 <p>Người thụ hưởng : {detail.storeOwner}</p>
    //                 <p>Sales Rep : {detail.srName} -- SS : {detail.ssName}</p>
    //             </div>
    //             <div className="p-col-4">
    //                 <div className="row" style={{paddingBottom:'5px'}}>
    //                     <div className="col-5 col-smaller-5 col-small-5 col-medium-5 col-large-5 col-xlarge-5 col-xxlarge-5">
    //                         <label style={{ paddingTop: 9 }} className="filter-label">Ngày bắt đầu</label>
    //                     </div>
    //                     <div className="col-6 col-smaller-6 col-small-6 col-medium-6 col-large-6 col-xlarge-6 col-xxlarge-6">
    //                         {
    //                             renderDateItem('dateFrom')
    //                         }
    //                     </div>
    //                 </div>
    //                 <div className="row" style={{paddingBottom:'5px'}}>
    //                     <div className="col-5 col-smaller-5 col-small-5 col-medium-5 col-large-5 col-xlarge-5 col-xxlarge-5">
    //                         <label style={{ paddingTop: 9 }} className="filter-label">Ngày kết thúc</label>
    //                     </div>
    //                     <div className="col-6 col-smaller-6 col-small-6 col-medium-6 col-large-6 col-xlarge-6 col-xxlarge-6">
    //                         {
    //                             renderDateItem('dateTo')
    //                         }
    //                     </div>
    //                 </div>
    //                 <div className="row" style={{marginTop: 20, marginBottom: 5}}>
    //                 <div className="col-12 col-smaller-12 col-small-12 col-medium-12 col-large-12 col-xlarge-12 col-xxlarge-12 text-center">
    //                     <Button className='p-col-4'
    //                         icon="search"
    //                         onClick={()=> LoadData()}
    //                     >
    //                         Tìm kiếm
    //                     </Button>
    //                     <Button className='p-col-4 bp3-intent-primary'
    //                         icon="archive"
    //                         onClick={()=> DownloadReport(api.REPORT_STORE_INCENTIVE,'ReportStoreINCENTIVE.xlsx')}
    //                     >
    //                         Report INCENTIVE
    //                     </Button>
    //                     {/* <Button className='p-col-4'
    //                         icon="search"
    //                         onClick={()=> DownloadReport(api.REPORT_STORE_HISTORY,'ReportHistory.xlsx')}
    //                     >
    //                        Report Tổng kết
    //                     </Button> */}
    //                 </div>
    //                 {props.profile.roleId === 1 || props.profile.roleId ===2 ?
    //                 <div className="col-12 col-smaller-12 col-small-12 col-medium-12 col-large-12 col-xlarge-12 col-xxlarge-12 text-center">
    //                         <Button className='bp3-intent-success' icon="plus" onClick={() => setShowUngQua(true)} large >
    //                             <label>Thêm mới ứng quà/thu hồi/đổi quà</label>
    //                         </Button>
    //                 </div> : null }
    //             </div>
    //             </div>
    //             {isLoading ? <div className='p-col-12' style={{ margin: 'auto', display: 'flex' }} >
    //                             <ProgressSpinner />
    //                         </div> : null
    //             }
    //         </div>
    //     }
    //     return null
    // }
    const renderDateItem = (item: any) => {
        if (item !== undefined && item !== null && Object.keys(item).length > 0) {
            switch (item) {
                case 'dateFrom':
                    return (
                        <Calendar
                            value={dateFrom}
                            onChange={(e: any) => setDateFrom(e.value)}
                            placeholder="Chọn từ ngày"
                            style={{ width: "100%" }}
                            dateFormat="dd/mm/yy"
                          //  readOnlyInput
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="2020:2021"
                            className="filter-item"
                        >
                        </Calendar>
                    );
                case 'dateTo':
                    return (
                        <Calendar
                            value={dateTo}
                            onChange={(e: any) => setDateTo(e.value)}
                            placeholder="Chọn đến ngày"
                            style={{ width: "100%" }}
                            dateFormat="dd/mm/yy"
                          //  readOnlyInput
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="2020:2021"
                            className="filter-item"
                        >
                        </Calendar>
                    );
                default:
                    return null;
            }
            // return null;
        }
        return null;
    }
    const UpdateInfomationUngQua =()=>{
      
    }
    const convertImageto64 = (file, item) => {
        var f = new FormData();
        f.append("file", file)
        axiosWithHeadersNoTokenUploadFile('POST', `${api.FILE_UPLOAD}`, f)
            .then((data: any) => {
                if (data !== undefined && data.data.filePath !== undefined) {
                    var obj = {
                        questionId: item.questionId,
                        answer: data.data.filePath,
                        dateCreate: dateSendData
                    }
                    let list = listFile;
                    list.push(obj);
                    setListFile(list)
                }
            }).catch(err => console.log(err));
    }

    const onChangeFile = (event, item) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const fileList = Array.from(files);
            fileList.map((image: any) => {
                convertImageto64(image, item)
            });
        }
    }
    const renderFooterUngQua = () => {
        return (
            <div>
                <Button title="Yes" icon="saved" onClick={() => UpdateInfomationUngQua()} >
                    <label>Lưu</label>
                </Button>
                <Button title="No" icon="cross" onClick={() => setShowUngQua(false)} className="p-button-secondary" >
                    <label>Không</label>
                </Button>
            </div>
        );
    }
    const onTabChange =(e:any)=>{
        setActiveIndex(e.index)
        ReloadPage(e.index)
    }
    return (
        <div>
          
            {ampg.detail !== undefined ?
                <ViewDataShift sup={amsup} pg={ampg} Reload={ReloadPage} tabindex={0} /> : null
            }
        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity, summary, detail) => dispatch(actions.error_success(severity, summary, detail))
    }
}
const mapStateToProps = (state:any) => {
    return {
      profile:state.auth.profile
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(Data);
