
 const dotenv = require('dotenv');
 dotenv.config();
// capture the environment variables the application needs
export const {
        REACT_APP_PAGE_SIZE,
        REACT_APP_MAX_WIDTH,
        REACT_APP_MAX_HEIGHT,
        REACT_APP_SECRET_KEY,
        REACT_APP_SECRET_KEY_CLIENT,
        REACT_APP_JWT,
        REACT_APP_JWT_REFESH
} = process.env;
export function GetURL() {
    var enviroment = 1;
    var baseUrlLink ='http://localhost:3020';
    switch(enviroment){
        case 0 :
                baseUrlLink = 'http://localhost:3020';
                break;
        case 1 : 
                baseUrlLink ='https://apimega-thematic.tengroup.com.vn';
                break;
        case 2 : 
                baseUrlLink ='http://14.161.22.101:3008';
                break;
        default:
            baseUrlLink = '';
            break;
    }
    return baseUrlLink;
}

export function GetURLReport() {
        var enviroment = 1;
        var baseUrlLink ='http://localhost:32018';
        switch(enviroment){
            case 0 :
                    baseUrlLink = 'http://localhost:32018';
                    break;
            case 1 : 
                    baseUrlLink ='https://reportmega-thematic.tengroup.com.vn';
                    break;
                case 2 : 
                baseUrlLink ='http://14.161.22.101:8081';
                break;
            default:
                baseUrlLink = '';
                break;
        }
        return baseUrlLink;
    }
export const SERVICE_URI = GetURL();




