export const filterTable = [
    {
        field: 'dateFrom',
        title: 'TỪ NGÀY',
        type: 'date',
        defaultValue: null,
        placeholder: 'Tìm kiếm theo...'
    },
    {
        field: 'dateTo',
        title: 'ĐẾN NGÀY',
        type: 'date',
        defaultValue: null,
        placeholder: 'Tìm kiếm theo...'
    }
]

export const columnTable = [
    {
        field: 'name',
        header: 'Quà tặng',
        width: 100,
        frozen:true,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'giftMask',
        header: 'Mặt nạ',
        width: 60,
        align: 'right',
        fontSize: 11
    },
    {
        field: 'giftKhan',
        header: 'Khăn giấy tẩy trang',
        width: 100,
        align: 'right' 
    },
    {
        field: 'giftTho',
        header: 'Thố thủy tinh',
        width: 80,
        align: 'right',
        fontSize: 11,
    }
]