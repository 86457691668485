import * as React from 'react';
import FormLayout from '../../components/form/index';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import { InputText } from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import {InputSwitch} from 'primereact/inputswitch';
import * as router from "../../common/router";
import {
    Link
} from "react-router-dom";
import * as api from '../../reducer/contains/api'
import {axiosWithHeaders} from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum'
import UploadAvatar from './UploadAvatar'
// var _ = require('lodash');

const Product = (props: any) => {
    const [button, setButton] = React.useState(true);
    const [isPrice, setIsPrice] = React.useState(false);
    const [price, setPrice] = React.useState(0);
    const [productId, setproductId] = React.useState('');
    const [productName,    setproductName] = React.useState('');
    const [productGroup,    setproductGroup] = React.useState('');
    const [pathImage,    setPathImage] = React.useState('');
    const [avatar, setAvatar] = React.useState<string>("");
    const [vietnameseName, setvietnameseName] = React.useState<string>("");
    const [specifications, setspecifications] = React.useState<string>("");
  
    React.useEffect(() => {
        if (props.match.params.id !== undefined && props.match.params.id !== null){
            setButton(false);
            axiosWithHeaders('POST',`${api.GET_PRODUCT}`,{productId: props.match.params.id})
            .then((data:any) => {
                if(data !== undefined && data !== null ){
                    let response = data.data;
                    let {status,message} = response;
                    if(status === Status.STATUS_RESPONSE_OK)
                    {
    
                       const{	productId,productName,pathImage,specifications,vietnameseName,productGroup
                    } = response.result;
    
                        setproductId(productId);
                        setproductName(productName);
                        setPathImage(pathImage);
                        setvietnameseName(vietnameseName)
                        setspecifications(specifications)
                        setproductGroup(productGroup)
                    }
                    else{
                        props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                    }
                }
                else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }
                
            }).catch(err => {
                props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
            });
        }   
    }, []);

    const onValueChange = (e, field) => {
        switch(field){
            case 'productName':
                setproductName(e.target.value);
                break;
            case 'specifications':
                setspecifications(e.target.value);
                break;
            case 'vietnameseName':
                setvietnameseName(e.target.value);
                break;
            case 'productGroup' :
                setproductGroup(e.target.value);
            case 'price' :
                    setPrice(e.value)
            default:
                break;
        }
    }
    const CheckData=()=>{
        if(productName === undefined || productName === '')
        {
            props.error_success('error', 'Lỗi', "Vui lòng nhập tên sản phẩm");
            return false;
        }
        if(productGroup === undefined || productGroup === '' || productGroup === '0')
        {
            props.error_success('error', 'Lỗi', "Vui lòng nhập nhóm sản phẩm");
            return false;
        }
        if(pathImage === undefined || pathImage === null || pathImage === '')
        {
            props.error_success('error', 'Lỗi', "Vui lòng chọn hình ảnh sản phẩm");
            return false;
        }
        return true;
    }
    const SaveData=()=>{
        if(!CheckData()){
            return false;
        }
        var obj={productId,productName,pathImage,specifications,vietnameseName,productGroup,isPrice,price}
        axiosWithHeaders('POST',api.ADD_PRODUCT, obj)
        .then((data:any) => {
            if(data !== undefined && data !== null ){
                let response = data.data;
                let {status,message} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                    props.error_success('success', 'Thông báo', 'Thêm mới sản phẩm thành công !');
                    props.history.push(router.ListProduct);
                }
                else{
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra ! !!!" + message);
                }
            }
            else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" );}
            
        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
        
    }

    const SaveUpdateData=()=>{
        if(!CheckData()){
            return false;
        }
        var obj={productId,productName,pathImage,specifications,vietnameseName,productGroup,isPrice,price
        }
        axiosWithHeaders('POST',api.UPDATE_PRODUCT, obj)
        .then((data:any) => {
            if(data !== undefined && data !== null ){
                let response = data.data;
                let {status,message} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                    props.error_success('success', 'Thông báo', 'Cập nhat sản phẩm thành công !');
                    props.history.push(router.ListProduct);
                }
                else{
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra ! !!!" + message);
                }
            }
            else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" );}
            
        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
        
    }

    return (
        <div className="booking-layout">
            <FormLayout formTitle="CỬA HÀNG">
                <div className="p-grid">
                    <div className="p-col-4"></div>
                    <div className="p-col-4">
                    <UploadAvatar onChange={setPathImage} urlAvatar={pathImage} />
                    <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Hình ảnh sản phẩm</label>
                            </div>
                            <div className="p-col-6">
                            <InputText 
                                    value={pathImage} 
                                    placeholder="Chọn ảnh và upload ở trên..." 
                                    onChange={(e: any)=> setPathImage(e.target.value)} 
                                    style={{width: "100%"}}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="row p-col-12">
                           
                           <label className="p-col-4 text-right">Nhóm sản phẩm</label>
                         
                           <div className="p-col-6">
                               <Dropdown 
                                   value={productGroup} 
                                   optionLabel="productGroupName" 
                                   optionValue="productGroupId" 
                                   options={[ {"productGroupId":1,"productGroupName":"COCACOLA"}
                              ]} 
                                   placeholder="Chọn Nhóm sản phẩm..." 
                                   filter={true} 
                                   filterPlaceholder="Tìm..." 
                                   filterBy="productGroupId,productGroupName"
                                   // filterMatchMode="contains"
                                   style={{width : '100%'}} 
                                   onChange={(e: any)=> setproductGroup(e.value)}
                               />
                           </div>
                       </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Tên sản phẩm</label>
                            </div>
                            <div className="p-col-6">
                                <InputText value={productName} placeholder="Tên sản phẩm"
                                    style={{ width: '100%' }} onChange={(e) => onValueChange(e, 'productName')} />
                            </div>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Tên tiếng việt</label>
                            </div>
                            <div className="p-col-6">
                                <InputText value={vietnameseName} placeholder="Tên tiếng việt"
                                    style={{ width: '100%' }} onChange={(e) => onValueChange(e, 'vietnameseName')} />
                            </div>
                        </div>
                        {/* <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Quy cách</label>
                            </div>
                            <div className="p-col-6">
                                <InputText value={specifications} placeholder="Quy cách"
                                    style={{ width: '100%' }} onChange={(e) => onValueChange(e, 'specifications')} />
                            </div>
                        </div> */}
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Có Nhập Giá Tiền Sản Phẩm Không ?????</label>
                            </div>
                            <div className="p-col-6">
                                    <InputSwitch checked={isPrice} onChange={(e) => setIsPrice(e.value)} />
                            </div>
                        </div>
                        {isPrice ? 
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label> Giá :</label>
                            </div>
                            <div className="p-col-6">
                            <InputNumber  value={price} placeholder="0"  min={0} max={400000000}
                                              style={{ width: '100%' }} inputStyle={{ textAlign: 'right' }} onChange={(e) => onValueChange(e,'price')} />
                            </div>
                        </div>
                        :null}
                        <div className="p-grid" style={{marginBottom: 10, marginTop: 10}}>
                            <div className="p-col-6">
                                <Link className="bp3-button bp3-large bp3-intent-primary btn-block btn-back" to={router.ListProduct}>QUAY LẠI</Link>
                            </div>
                            <div className="p-col-6">
                                {button ?     <Button
                                    intent="success"
                                    className="btn-block"
                                    large
                                    onClick={()=>SaveData()}
                                >
                                    THÊM MỚI
                                </Button>:
                                <Button
                                    intent="primary"
                                    className="btn-block"
                                    large
                                    onClick={()=>SaveUpdateData()}
                                >
                                    CẬP NHẬT
                                </Button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="p-col-4"></div>
                </div>
            </FormLayout>
        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity,summary,detail) => dispatch(actions.error_success(severity,summary,detail))
    }
}

export default connect(null, mapDispatchToProps)(Product);
