import * as React  from 'react';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import {InputNumber} from 'primereact/inputnumber';
import * as api from '../../reducer/contains/api'
import {axiosWithHeaders} from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum'
import {Fieldset} from 'primereact/fieldset';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
 var _ = require('lodash');

const GroupGift = (props: any) => {
  
    const {data,detail} = props;
    const [displayBasic1, setDisplayBasic1] = React.useState(false);
    const [gift1, setGift1] = React.useState(0);
    const [gift2, setGift2] = React.useState(0);
    const [gift3, setGift3] = React.useState(0);
    const [gift4, setGift4] = React.useState(0);
    const [gift5, setGift5] = React.useState(0);
    React.useEffect(() => {
        setGift1(detail.gift1)
        setGift2(detail.gift2)
        setGift3(detail.gift3)
        setGift4(detail.gift4)
        setGift5(detail.gift5)
    }, []);
    const onClick = (stateMethod) => {
        stateMethod(true);
    }

    const onHide = (stateMethod) => {
        stateMethod(false);
    }
    const UploadDataLocation=()=>{
        axiosWithHeaders('POST', `${api.DATA_UPDATE_INFO_GIFT}`, {dataId:detail.dataId
            ,gift1
            ,gift2
            ,gift3
            ,gift4
            ,gift5
        })
        .then((response: any) => {
            const {status, message } = response.data;
            switch(status){
                case Status.STATUS_RESPONSE_OK:
                  
                     props.reload();
                     setDisplayBasic1(false);
                    break;
                default:
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                    break;
            }
        }).catch(err => console.log(err));
       // props.reload();
    }

    const renderFooterLocation = () => {
        return (
            <div>
                <Button title="Yes" icon="saved" onClick={() => UploadDataLocation()} >
                        <label>Có</label>
                </Button>
                <Button title="No" icon="cross" onClick={() => onHide(setDisplayBasic1)} className="p-button-secondary" >
                        <label>Không</label>
                </Button>
            </div>
        );
    }
  
  
    const renderButton =()=>{
        if(props.profile.roleId ===1 || props.profile.roleId === 2){
            return <><div className='p-col-12' style={{position:'relative'}}>
            <Button icon="plus" intent="warning" large style={{marginLeft: 5, position:'absolute',top:'-60px',right:'120px'}}   onClick={() => onClick(setDisplayBasic1)} /> 
             </div>
             </>
        }
        return null
     
      
    }

    const onValueChange = (e, field) => {
        let value = e.value !== undefined && e.value !== null ? e.value : 0
        switch(field){
            case 'gift1':
                setGift1(value);
                break;
            case 'gift2':
                setGift2(value);
                break;
            case 'gift3':
                setGift3(value);
                break;
            case 'gift4':
                setGift4(value);
                break;
            case 'gift5':
                setGift5(value);
                break;
            default:
                break;
        }
    }
  
    return  ((data !== undefined && data !== null ) ? <>
        <Fieldset legend={props.title} toggleable={true} className="fieldset-group-image" >
            {renderButton()}
            <div className="p-grid class-store">
               
                <div className='p-col-12' style={{ margin: 'auto' }}>
                        <DataTable value={data} responsive={true} tableClassName='table-hnk' >
                            <Column field="gift1" header="Không trúng" style={{ fontSize: '18px', textAlign: 'center' }} />
                            <Column field="gift2" header="SL Card 10K" style={{ fontSize: '18px', textAlign: 'center' }} />
                            <Column field="gift3" header="SL Đế phone" style={{ fontSize: '18px', textAlign: 'center' }} />
                            <Column field="gift4" header="SL Tai nghe" style={{ fontSize: '18px', textAlign: 'center' }} />
                            <Column field="gift5" header="SL Loa" style={{ fontSize: '18px', textAlign: 'center' }} />
                        </DataTable>
                </div>
              
            </div>
         
        <Dialog header="Cập nhật Số lượng quà tại booth" visible={displayBasic1} style={{ width: '70%'}}
            contentStyle={{  height: '600px' }}
            onHide={() => onHide(setDisplayBasic1)} 
            footer={renderFooterLocation()}
            maximizable={true}
        >
            <div className='p-col-12'>
            <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Số lượng Không Trúng</label>
                            </div>
                            <div className="p-col-2">
                                <InputNumber   max={400000000} value={gift1} placeholder="Số lượng Không trúng" 
                                    style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e,'gift1')}   />
                                  
                            </div>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Số lượng Phone card 10k</label>
                            </div>
                            <div className="p-col-2">
                                <InputNumber   max={400000000} value={gift2} placeholder="Số lượng Phone card 10k" 
                                    style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e,'gift2')}   />
                                  
                            </div>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Số lượng Đế gắn điện thoại</label>
                            </div>
                            <div className="p-col-2">
                                <InputNumber   max={400000000} value={gift3} placeholder="Số lượng Đế gắn điện thoại" 
                                    style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e,'gift3')}   />
                                  
                            </div>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Số lượng Tai nghe chụp tai Kanen IP-350</label>
                            </div>
                            <div className="p-col-2">
                                <InputNumber   max={400000000} value={gift4} placeholder="Số lượng Tai nghe chụp tai Kanen IP-350" 
                                    style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e,'gift4')}   />
                                  
                            </div>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Số lượng Loa Bluetooth JBL GO2</label>
                            </div>
                            <div className="p-col-2">
                                <InputNumber   max={400000000} value={gift5} placeholder="Số lượng Loa Bluetooth JBL GO2" 
                                    style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e,'gift5')}   />
                                  
                            </div>
                        </div>
                
            </div>
        </Dialog>
        </Fieldset>
        
    </> : null
    )
}
//</DataTable>   >  
const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity,summary,detail) => dispatch(actions.error_success(severity,summary,detail))
    }
}
const mapStateToProps = (state:any) => {
    return {
      profile:state.auth.profile
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(GroupGift);