import React from 'react';
const Location = (props: any) => {

    const{location} = props;

    const  _renderMap=(location, mapHeight)=> {
        const urlMap = "https://maps.google.com/maps?q=" + location + "&output=embed"
        return (
            <iframe title="Bản đồ" width="100%" height={mapHeight} scrolling="no" src={urlMap} style={{ border: 0 }}></iframe>
        )
    }

        return (
            <div>
                {_renderMap(location,300)}
            </div>
            )
}
export default Location;