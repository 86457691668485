import React, {useEffect, useRef} from 'react';
import '../../assets/css/style.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
    Link
} from "react-router-dom";
import * as router from "../../common/router";
import { dataMenus } from '../../common/data';
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import * as types from '../../reducer/contains/actionTypes';
import PerfectScrollbar from 'react-perfect-scrollbar'

// let selectMenu: any = null;

// let selectMenu: any = null;

const SideBar = (props: any) =>{
    const {
        profile,
        wrapper
    } = props;

    const appSideBar = useRef<any>(null);
    const sideBarContent = useRef<any>(null);

    /*Render nav-lavel*/

    useEffect(()=>{
        setTimeout(()=>{
            let breadcrumb: any = localStorage.getItem(types.LOCAL_STORAGE_BREADCRUMB);
            breadcrumb = JSON.parse(breadcrumb)
            if(breadcrumb!==undefined && breadcrumb!==null && Object.keys(breadcrumb).length > 0){
                // console.log(breadcrumb);
                const {
                    parentIndex,
                    menuIndex
                } = breadcrumb;

                // console.log(parentIndex, menuIndex);
                
                if(parentIndex > 0){
                    let menuDivParent: any = document.querySelector(".nav-0-" + parentIndex)
                    menuDivParent.classList.add("nav-collapsed-open");
                    menuDivParent.classList.add("active");
                }
                let menuDiv: any = document.querySelector(".nav-" + parentIndex +"-" + menuIndex);
                // console.log(menuDiv);
                if(menuDiv!==null)
                    menuDiv.classList.add("active")
            }
        }, 150)
        initAnimation();
    }, [])

    const initAnimation = () =>{
        const t = document;
        const ab = (e) => {
            const   a = e.querySelector(".submenu-content"),
                    s = a.querySelectorAll(".menu-item");
                    a.style.display = "";

            setTimeout(() => {
                Array.from(s).forEach((item: any) => {
                    item.classList.remove("is-shown");
                })
                e.classList.remove("open");
            }, 200);
        }

        const   s = appSideBar.current as any,
                n = sideBarContent.current as any,
                l = wrapper.current as any;
                // o = t.querySelector(".sidebar-content") as any;

        n._container.querySelectorAll(".navigation-main .nav-item a").forEach((element: any) => {
            element.addEventListener("click", (event: any) => {
                // console.log(event.target.parentNode);
                const e: any = event.target.parentNode;
                // console.log(e.classList);
                if(Array.from(e.classList).includes("has-sub") 
                && Array.from(e.classList).includes("open")){
                    ab(e);
                } else {
                    if(Array.from(e.classList).includes("has-sub")) {
                        const   a = e.querySelector(".submenu-content"),
                                s = a.querySelectorAll(".menu-item");
                        Array.from(s).forEach((item: any) => {
                            item.classList.add("is-hidden")
                        });

                        e.classList.add("open");
                        a.style.display = "";

                        setTimeout(() => {
                            Array.from(s).forEach((item: any) => {
                                item.classList.add("is-shown");
                                item.classList.remove("is-hidden");
                            })
                        }, 100);

                        // console.log(n._container.querySelectorAll(".open"), e);
                        // ab(e);
                        let list: any = n._container.querySelectorAll(".open");
                        Array.from(list).forEach((it: any)=>{
                            if(it!==e){
                                it.classList.remove("open");
                                it.classList.remove("active");
                            }
                            e.classList.add("open");
                        })
                    }
                }

            }, false);
        //     // // console.log(element   .closest(".nav-item"))
        });
        
        s.addEventListener("mouseenter", ()=>{
            // console.log(l);
            if(Array.from(l.classList).includes("nav-collapsed")){
                l.classList.remove("menu-collapsed");
                const e: any = t.querySelector(".navigation-main .nav-collapsed-open");
                // console.log(e);
                if(e){
                    const a: any = e.querySelector(".submenu-content");
                    setTimeout(() => {
                        if(a){
                            a.style.display = "";
                        }
                        // const t =  n._container.querySelector(".nav-item.active");
                        // console.log(t)
                        e.classList.add("open");
                        e.classList.remove("nav-collapsed-open");
                        // n._container.querySelector(".nav-item .active").closest(".nav-item").classList.add("open");
                    }, 300);
                }
            }
        }, false)

        s.addEventListener("mouseleave", ()=>{
            if(Array.from(l.classList).includes("nav-collapsed")){
                l.classList.add("menu-collapsed");
                const i = t.querySelector(".navigation-main .open");
                if(i){
                    const a: any = i.querySelector(".submenu-content");
                    i.classList.add("nav-collapsed-open");
                    // i.classList.add("active");
                    setTimeout(()=>{
                        if(a){
                            a.style.display = "";
                        }
                        i.classList.remove("open");
                    }, 300)
                }
            }
        }, false)
    }

    const selectedMenuChange = (e: MouseEvent, item: any) => {
        // console.log((e.target as any).classList)
        // console.log(item);
        if(Array.from((e.target as any).classList).includes("menu-item")){
            (e.target as any).classList.add("active");
        }
        let {
            title,
            icon,
            href,
            description,
            menuIndex,
            parentIndex,
            groupName
        } = item;
        // breadcrumbUpdate(title, href, icon, description, menuIndex, parentIndex, groupName);
        actions.saveBreadcrumb(title, href, icon, description, menuIndex, parentIndex, groupName);
        // setActiveToMenuItem(menuIndex);
        // props.history.push(href);
    }

    const renderLavel = () =>{
        let html: any = [];
        if(profile!==undefined && profile!==null && Object.keys(profile).length > 0){
            let {roleId} = profile;
            // console.log(roleId);
            Array.from(dataMenus).forEach((item: any, index: number)=>{
                // console.log(item, index);
                /*Set quyền hiển thị menu*/
                if(item.listRole.includes(roleId)){
                    html.push(
                        <div key={index}>
                            <div className="nav-lavel" key={index}>{item.groupName}</div>
                            {
                                renderMenuWithIcon(item.items)
                            }
                        </div>
                    )
                }
            })
        }
        return html;
    }

    const renderMenuWithIcon = (listMenu) =>{
        let html: any = [];
        if(Array.from(listMenu).length > 0){
            if(profile!==undefined && profile!==null && Object.keys(profile).length > 0){
                let {roleId} = profile;
                // console.log(roleId);
                Array.from(listMenu).forEach((item: any, index: number)=>{
                    // console.log(item, index);
                    /*Set quyền hiển thị menu*/
                    let listMenu = item.items
                    if(item.listRole.includes(roleId)){
                        if(item.items !== undefined && item.items!== null && Array.from(item.items).length > 0){
                            html.push(
                                <div 
                                    className={["nav-" + item.parentIndex +"-" + item.menuIndex, "nav-item", "has-sub"].join(" ")} 
                                    // onClick={showItemHasSub} 
                                    key={index}
                                >
                                    <a 
                                        href={item.href} 
                                        title={item.description}
                                    >
                                        <i className={item.icon}></i>
                                        {/* <span></span> */}
                                        {item.title}
                                    </a>
                                    <div className="submenu-content">
                                        {
                                            renderMenuChildWithoutIcon(listMenu)
                                        }
                                    </div>
                                </div>
                            )
                        }else{
                            // console.log(item);
                            html.push(
                                <div 
                                    className={["nav-" + item.parentIndex +"-" + item.menuIndex, "nav-item"].join(" ")} 
                                    key={index} 
                                    onClick={(e: any)=> selectedMenuChange(e, item)}
                                >
                                    <a 
                                        href={item.href}
                                        title={item.description}
                                    >
                                        <i className={item.icon}></i>
                                        {/* <span></span> */}
                                        {item.title}
                                    </a>
                                </div>
                            )
                        }
                    }
                })
            }
        }
        return html;
    }

    const renderMenuChildWithoutIcon  = (listMenu) => {
        let html: any = [];
        if(profile!==undefined && profile!==null && Object.keys(profile).length > 0){
            let {roleId} = profile;
            // console.log(roleId);
            Array.from(listMenu).forEach((item: any, index: number)=>{
                // console.log(item, index);
                /*Set quyền hiển thị menu*/
                if(item.listRole.includes(roleId)){
                    html.push(
                        <a 
                            href={item.href} 
                            className={["menu-item", "nav-" + item.parentIndex +"-" + item.menuIndex].join(" ")}
                            title={item.description} 
                            key={index} 
                            onClick={(e: any)=> selectedMenuChange(e, item)}
                        >
                            {item.title}
                        </a>
                    )
                }
            })
        }
        return html;
    }




    // console.log(dataMenus);
    return (
        <div className="app-sidebar colored" ref={appSideBar}>
            <div className="sidebar-header">
                <Link className="header-brand" to={router.Home}>
                    <div className="logo-img">
                        <img src={require("../../assets/images/theme/brand-white.svg")} className="header-brand-img" alt="lavalite" /> 
                    </div>
                    <span className="text" style={{marginLeft: 5, textTransform:"uppercase"}}>{profile.name}</span>
                </Link>
            </div>
            
            <PerfectScrollbar className="sidebar-content" ref={sideBarContent}>
                <div className="nav-container">
                    <nav id="main-menu-navigation" className="navigation-main">
                        {
                            renderLavel()
                        }
                    </nav>
                </div>
            </PerfectScrollbar>
        </div>
    );
}

const mapStateToProps = (state:any) => {
    return {
        profile:state.auth.profile
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // breadcrumbUpdate: (title: string, href: string, icon: string, description: string, menuIndex: number, parentIndex: number, parentName: string) => dispatch(actions.breadcrumb_change(title, href, icon, description, menuIndex, parentIndex, parentName)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);