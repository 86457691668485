import * as React from 'react';
import FormLayout from '../../components/form/index';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import * as router from "../../common/router";
import {
    Link
} from "react-router-dom";
import { Calendar } from 'primereact/calendar';
import * as api from '../../reducer/contains/api'
import {axiosWithHeaders} from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum'
 var _ = require('lodash');

const UpdateCustomerOrder = (props: any) => {
    const [dateSendData, setDateQuestion1] = React.useState<Date>(new Date());
    const [shiftId, setShiftId] = React.useState(0);
    const [userId, setUserId] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [customerId, setCustomerId] = React.useState(0);
    const [storeId, setStoreId] = React.useState(0);
    const [nameCustomer, setNameCustomer] = React.useState('');
    const [phoneCustomer, setPhoneCustomer] = React.useState('');
    const [gift1, setGift1] = React.useState(0);
    const [gift2, setGift2] = React.useState(0);
    const [gift3, setGift3] = React.useState(0);
    const [gift4, setGift4] = React.useState(0);
    const [gift5, setGift5] = React.useState(0);
    const [listPG, setListPG] = React.useState([]);
    // const [city, setCity] = React.useState(0);
  
    React.useEffect(() => {
        if (props.match.params.id !== undefined && props.match.params.id !== null && props.match.params.store !== undefined && props.match.params.store !== null){
            LoadCustomer(props.match.params.id,props.match.params.store)
            LoadUser(props.match.params.store);
        }
        else{
            props.history.push(router.ListData);
        }
       // onValueChangeStore(props.match.params.id)
    }, []);

    const LoadUser = (storeId :any) => {
        axiosWithHeaders('POST', api.LIST_USER_BY_STOREID,{storeId:storeId})
            .then((response: any) => {
                if (response !== undefined && response !== null && Object.keys(response).length > 0) {
                 
                    let { status, message, result } = response.data;
                    switch (status) {
                        case Status.STATUS_RESPONSE_OK:
                       
                         //  let sup = _.filter(result, function(o) { return o.roleId === 4; })
                           let pg =_.filter(result, function(o) { return o.roleId === 5; })
                        //    // console.log(thudoi,ungqua)
                        //     setListSup(sup);
                            setListPG(pg);
                            break;
                        case Status.STATUS_RESPONSE_FORBIDDEN:
                            props.logOut();
                            break;
                        default:
                            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                            break;
                    }
                } else {
                    props.error_success('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
                }
            }).catch(err => {
                console.log(err);
                props.error_success('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
            });
    }
    const LoadCustomer=(customerId : any,store:any)=>{
        axiosWithHeaders('POST',`${api.GET_CUSTOMER_DETAIL}`,{customerId,storeId:store})
        .then((data:any) => {
            if(data !== undefined && data !== null ){
               
                let response = data.data;
                let {status,message,result} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                    if(result !== undefined  && result !== null  )
                    {
                        const {products,detail}= result;
                       setProducts(products);
                       setCustomerId(customerId);
                      // setStoreId(store);
                       setNameCustomer(detail.nameCustomer);
                       setPhoneCustomer(detail.phoneCustomer);
                       setGift1(detail.gift1)
                       setGift2(detail.gift2)
                       setGift3(detail.gift3)
                       setGift4(detail.gift4)
                       setGift5(detail.gift5)
                       setStoreId(detail.storeId);
                       setShiftId(detail.shiftId);
                       setUserId(detail.userId);
                       setDateQuestion1(new Date(detail.dateCreate));
                    }
                }
                else{
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                }
            }
            else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }
            
        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
    }

    const CheckData=()=>{
        if(nameCustomer === undefined || nameCustomer === '')
        {
            props.error_success('error', 'Lỗi', "Vui lòng nhập tên khách hàng");
            return false;
        }
        if(phoneCustomer === undefined || phoneCustomer === '')
        {
            props.error_success('error', 'Lỗi', "Vui lòng nhập sdt khách hàng");
            return false;
        }
        if(gift1 === undefined || gift2 === undefined || gift3 === undefined || gift4 === undefined || gift5 === undefined 
            || gift1 === null || gift2 === null || gift3 === null  || gift4 === null || gift5 === null )
        {
            props.error_success('error', 'Lỗi', "Vui lòng nhập đúng sl quà");
            return false;
        }
        return true;
    }
    const SaveData=()=>{
        if(!CheckData()){
            return false;
        }
        var obj={products,customerId,storeId,  gift1
            ,gift2
            ,gift3
            ,gift4
            ,gift5,nameCustomer,phoneCustomer,dateSendData,shiftId,userId}
       // console.log(obj)
     //   console.log(data)
        axiosWithHeaders('POST',api.UPDATE_CUSTOMER_ORDER, obj)
        .then((data:any) => {
            if(data !== undefined && data !== null ){
                let response = data.data;
                let {status,message} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                    props.error_success('success', 'Thông báo', 'Cập nhật thành công !');
                    props.history.push(router.DataOrder+"/"+storeId);
                }
                else{
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra ! !!!" + message);
                }
            }
            else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" );}
            
        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
        
    }
    const onValueChange = (e, field) => {
        let value = e.value !== undefined && e.value !== null ? e.value : 0
        switch(field){
            case 'gift1':
                setGift1(value);
                break;
            case 'gift2':
                setGift2(value);
                break;
            case 'gift3':
                setGift3(value);
                break;
            case 'gift4':
                setGift4(value);
                break;
            case 'gift5':
                setGift5(value);
                break;
            default:
                break;
        }
    }

    const onValueChangePrice = (e,product:any) => {
        let now = products;
        now.map((item :any,idx)=>{
            if(item.productName === product.productName && item.vietnameseName === product.vietnameseName)
            {
                item.cost = e.value !== undefined && e.value !== null ? e.value : 0
                item.customerId= customerId
            }
        })
        setProducts(now)
    }
    const onValueChangeQuantity = (e,product:any) => {
        let now = products;
        now.map((item :any,idx)=>{
            if(item.productName === product.productName && item.vietnameseName === product.vietnameseName)
            {
                item.quantity =  e.value !== undefined && e.value !== null ? e.value : 0
                item.customerId= customerId
            }
        })
        setProducts(now)
    }
    const renderTable =()=>{
        let arr =[] as any;
        if(products !== undefined && products !== [] && products.length > 0)
        {
            products.map((item :any,idx)=>{
                
                arr.push(<div key={idx} className="p-grid" style={{ marginTop: -7 }}>
                <div className="p-col-4 text-right">
                     <label>{item.productName}</label>
                </div>
                {/* <div className="p-col-2 text-right">
                     <label>{item.vietnameseName}</label>
                </div> */}
                <div className="p-col-1 text-right">
                     <label>Giá :</label>
                </div>
                <div className="p-col-2">
                    <InputNumber  value={item.cost} placeholder="0"  min={0} max={400000000}
                        style={{ width: '100%' }} inputStyle={{ textAlign: 'right' }} onChange={(e) => onValueChangePrice(e,item)} />
                </div>
                <div className="p-col-1 text-right">
                     <label>Số lượng :</label>
                </div>
                <div className="p-col-2">
                    <InputNumber  value={item.quantity} placeholder="0"  min={0} max={400000000}
                        style={{ width: '100%' }} inputStyle={{ textAlign: 'right' }} onChange={(e) => onValueChangeQuantity(e,item)} />
                </div>
            </div>)
            })
        }
        return arr;
    }
   
    return (
        <div className="booking-layout">
            <FormLayout formTitle="CỬA HÀNG">
                <div className="p-grid">
                  
                    <div className="p-col-12">
                    <div className='p-grid'>
                            <label className="p-col-4 text-right">Thời gian thực hiện</label>
                            <Calendar className="p-col-3" showTime={true} hourFormat="24" value={dateSendData}  dateFormat="dd/mm/yy" touchUI
                                onChange={(e: any) => setDateQuestion1(e.value)}></Calendar>
                        </div>
                        {/* <div className="p-grid">
                           
                           <label className="p-col-4 text-right">CA</label>
                         
                           <div className="p-col-4">
                               <Dropdown 
                                   value={shiftId} 
                                   optionLabel="shiftName" 
                                   optionValue="shiftId" 
                                   options={[{"shiftId":1,"shiftName":"AM"},{"shiftId":2,"shiftName":"PM"}]} 
                                   placeholder="Chọn Ca thực hiện..." 
                                   filter={true} 
                                   filterPlaceholder="Tìm..." 
                                   filterBy="shiftId,shiftName"
                                   // filterMatchMode="contains"
                                   style={{width : '100%'}} 
                                   onChange={(e: any)=> setShiftId(e.value)}
                               />
                           </div>
                       </div> */}
                        <div className="p-grid">
                            <label className="p-col-4 text-right">Nhân viên audit</label>
                            <div className="p-col-4">
                                <Dropdown 
                                    value={userId} 
                                    optionLabel="name" 
                                    optionValue="userId" 
                                    options={listPG} 
                                    placeholder="Chọn user audit..." 
                                    filter={true} 
                                    filterPlaceholder="Tìm..." 
                                    filterBy="userId,name"
                                    // filterMatchMode="contains"
                                    style={{width : '100%'}} 
                                    onChange={(e: any)=> setUserId(e.value)}
                                />
                            </div>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                                <div className="p-col-4 text-right">
                                    <label>Tên khách hàng</label>
                                </div>
                                <div className="p-col-4">
                                    <InputText value={nameCustomer} placeholder="Tên khách hàng"
                                        style={{ width: '100%' }} onChange={(e :any) => setNameCustomer(e.target.value)} />
                                </div>
                            </div>
                            <div className="p-grid" style={{ marginTop: -7 }}>
                                <div className="p-col-4 text-right">
                                    <label>Số điện thoại</label>
                                </div>
                                <div className="p-col-4">
                                    <InputText value={phoneCustomer} placeholder="Số điện thoại"
                                        style={{ width: '100%' }} onChange={(e:any) => setPhoneCustomer(e.target.value)} />
                                </div>
                            </div>
                            <div className="p-grid" style={{ marginTop: -7 }}>
                                <div className="p-col-4 text-right">
                                    <label>Thời gian thực hiện</label>
                                </div>
                                <div className="p-col-4">
                                    <InputText value={phoneCustomer} placeholder="Số điện thoại"
                                        style={{ width: '100%' }} onChange={(e:any) => setPhoneCustomer(e.target.value)} />
                                </div>
                            </div>
                        {renderTable()}
                        <div className="p-grid" style={{ marginTop: -7 }}>
                    <div className="p-col-4 text-right">
                        <label>Số lượng Không Trúng</label>
                    </div>
                    <div className="p-col-2">
                        <InputNumber   max={400000000} value={gift1} placeholder="Số lượng Không trúng" 
                            style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e,'gift1')}   />
                          
                    </div>
                </div>
                <div className="p-grid" style={{ marginTop: -7 }}>
                    <div className="p-col-4 text-right">
                        <label>Số lượng Phone card 10k</label>
                    </div>
                    <div className="p-col-2">
                        <InputNumber   max={400000000} value={gift2} placeholder="Số lượng Phone card 10k" 
                            style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e,'gift2')}   />
                          
                    </div>
                </div>
                <div className="p-grid" style={{ marginTop: -7 }}>
                    <div className="p-col-4 text-right">
                        <label>Số lượng Đế gắn điện thoại</label>
                    </div>
                    <div className="p-col-2">
                        <InputNumber   max={400000000} value={gift3} placeholder="Số lượng Đế gắn điện thoại" 
                            style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e,'gift3')}   />
                          
                    </div>
                </div>
                <div className="p-grid" style={{ marginTop: -7 }}>
                    <div className="p-col-4 text-right">
                        <label>Số lượng Tai nghe chụp tai Kanen IP-350</label>
                    </div>
                    <div className="p-col-2">
                        <InputNumber   max={400000000} value={gift4} placeholder="Số lượng Tai nghe chụp tai Kanen IP-350" 
                            style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e,'gift4')}   />
                          
                    </div>
                </div>
                <div className="p-grid" style={{ marginTop: -7 }}>
                    <div className="p-col-4 text-right">
                        <label>Số lượng Loa Bluetooth JBL GO2</label>
                    </div>
                    <div className="p-col-2">
                        <InputNumber   max={400000000} value={gift5} placeholder="Số lượng Loa Bluetooth JBL GO2" 
                            style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e,'gift5')}   />
                          
                    </div>
                </div>
                        <div className="p-grid" style={{marginBottom: 10, marginTop: 10}}>
                            <div className="p-col-6">
                                <Link className="bp3-button bp3-large bp3-intent-primary btn-block btn-back" to={router.DataOrder+"/"+storeId}>QUAY LẠI</Link>
                            </div>
                            <div className="p-col-6">
                                <Button
                                    intent="primary"
                                    className="btn-block"
                                    large
                                    onClick={()=>SaveData()}
                                >
                                    CẬP NHẬT
                                </Button>
                            </div>
                        </div>
                    </div>
                
                </div>
            </FormLayout>
        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity,summary,detail) => dispatch(actions.error_success(severity,summary,detail))
    }
}

export default connect(null, mapDispatchToProps)(UpdateCustomerOrder);
