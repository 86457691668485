export const filterTable = [
    {
        field: 'prefix',
        title: 'Từ khóa',
        type: 'string',
        defaultValue: '',
        placeholder: 'Tìm kiếm theo...'
    }
]

export const columnTable = [
    {
        field: 'stt',
        header: '#',
        width: 40,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'storeCode',
        header: 'Mã',
        width: 60,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'storeName',
        header: 'Tên cửa hàng',
        width: 100,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'chain',
        header: 'Hệ thống phân phối',
        width: 70,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'shiftPlan',
        header: 'Số ca kế hoạch',
        width: 60,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'storeAddress',
        header: 'Địa chỉ',
        width: 250,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'ward',
        header: 'Phường/Xã',
        width: 70,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'district',
        header: 'Quận/Huyện',
        width: 70,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'provinceName',
        header: 'Tỉnh thành',
        width: 70,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'kpiAM',
        header: 'KPI AM',
        width: 70,
        align: 'left',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'kpiPM',
        header: 'KPI PM',
        width: 70,
        align: 'left',
        fontSize: 11,
        usedTemplate: true
    },
   {
        field: 'action',
        header: '',
        width: 55,
        align: 'left',
        fontSize: 11,
        usedTemplate: true
    }
]