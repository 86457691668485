import { 
     enCryptDataClient, 
    deCryptToken 
} from '../../authentication/crypto';
import {REFRESHTOKEN} from './api'
 import {store } from '../reducers/storereducer';
 import {auth_reload_token,auth_logout} from '../actions/auth';
 import {error_ini} from '../actions/error';
 import {REACT_APP_JWT,REACT_APP_JWT_REFESH} from '../../common/config';
const axios = require('axios').default;


// axios.interceptors.request.use((config) => {
//   // do something with the config
//   //kiểm tra token còn hạn sử dụng ko ?
//   let token =  config.headers['Authorization'] ;
//   console.log(JSON.stringify(token))
//   return config
// }, (err) => {
//   return Promise.reject(err.response)
// })


axios.interceptors.response.use((response) => {
    return response
  }, (err) => {
    
    // if (!err.status) {
    //   // network error
    //   return getNewToken()
    //   .then((res :any) => {
    //     let config = err.config;
    //     const { accesstoken } = res;
        
    //     config.headers['Authorization'] = res.accesstoken;
    //     store.dispatch(auth_reload_token({ accesstoken }))
    //     localStorage.setItem(REACT_APP_JWT as string, enCryptDataClient(accesstoken));
    //     return new Promise((resolve, reject) => {
    //       axios.request(config)
    //         .then(res => resolve(res))
    //         .catch(e => reject(e))
    //     })
    //   })
    // }
    // Return any error which is not due to authentication bac
    console.log(err)
    if (err.response.status === 403) {
     // console.log(1111)
      //dispatch return to login
     // alert(403)
       store.dispatch(error_ini())
       store.dispatch(auth_logout())
     
    }
   // console.log(store.getState().auth.token)
    if (err.response.status === 401) {
    
      return getNewToken()
        .then((res :any) => {
          let config = err.config;
          const { accesstoken } = res;
          
          config.headers['Authorization'] = res.accesstoken;
          store.dispatch(auth_reload_token({ accesstoken }))
          localStorage.setItem(REACT_APP_JWT as string, enCryptDataClient(accesstoken));
          return new Promise((resolve, reject) => {
            axios.request(config)
              .then(res => resolve(res))
              .catch(e => reject(e))
          })
        })
     }
  
    // return new (Promise as any).reject(err) 
  })

  const getNewToken = () => {
    let token = store.getState().auth.refresh as string;
  //  console.log(token)
    let req = {
      url: REFRESHTOKEN,
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        refreshToken:token
      })
    };
    return new Promise((resolve, reject) => {
      axios(req)
        .then((res : any) => {
         // console.log(res)
          return resolve(res.data)
        })
        .catch(e => {
        //  console.log(e);
         return reject(e)
        })
    })
  }
  

export async function axiosWithHeaders(verb, url, data) {
    // console.log(verb, url, data);

    let token = deCryptToken() as string;
    // console.log(token);
    let request = {
        url: url,
        method: verb,
        headers: {
            "Content-Type": "application/json",
            'Authorization': token
        },
        data:JSON.stringify(data)
    };
    let responseData = null;
    //  console.log(request);
    await axios(request)
        .then((response) => {
            // console.log(response)
            // console.log('====================================');
            // console.log(response);
            // console.log('====================================');
            responseData = response;
        }).catch(err => {
            console.log(err);
        });
    return responseData;
};

export async function axiosWithHeadersFormData(verb, url, body) {
  // console.log(verb, url, data);

  let token = deCryptToken() as string;
  // console.log(token);
  let request = {
      url: url,
      method: verb,
      headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': token
      },
      data: body
  };
  let responseData = null;
  // console.log(request);
  await axios(request)
      .then((response) => {
          // console.log(response)
          // console.log('====================================');
          // console.log(response);
          // console.log('====================================');
          responseData = response;
      }).catch(err => {
          console.log(err);
      });
  return responseData;
};


export async function axiosWithHeadersDownloadFile(verb, url, data,namefile) {

    let request = {
        url:url,
        method: verb,
        headers: {
            "Content-Type": "application/json"
        },
        responseType: 'blob',
        data:JSON.stringify(data)
    };
    //let responseData = null;
    //  console.log(request);
    await axios(request)
        .then((response) => {
         //  console.log(response)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', namefile); //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            console.log(err);
        });
    //return responseData;
};

export async function axiosWithHeadersNoToken(verb, url, data) {
    let request = {
        url:url,
        method: verb,
        headers: {
            "Content-Type": "application/json" 
        },
        data:JSON.stringify({data:data})
    };
    let responseData = null;
    // console.log(request);
    await axios(request)
        .then((response) => {
            // console.log('====================================');
            // console.log(response);
            // console.log('====================================');
            responseData = response;
        }).catch(err => {
            console.log(err);
        });
    return responseData;
};

export async function axiosWithHeadersNoTokenUploadFile(verb, url, data) {
  let responseData = null;
  await  axios.post(`${url}`, data, {
    headers: { 'Content-Type': 'multipart/form-data' 
  }
    })
      .then((response) => {
          responseData = response;
      }).catch(err => {
          console.log(err);
      });
  return responseData;
};