import * as types from '../contains/actionTypes';
import {LISTDATADEFAULT} from '../contains/api';
import * as Status from '../../common/enum';
import {axiosWithHeaders} from '../contains/axiosWrapper';
import Swal from 'sweetalert2';
// import {logOut} from './index';

// export const auth_ini = () => {
//     return {
//         type: types.AUTH_INI
//     };
// };

export const datadefault_ini = () => {
    return {
        type: types.DATA_DEFAULT_INI
    };
};

export const datadefault_success = (listCost, listCostCustomer, listCostDriver, listTransportation, listType, listShippingUnit, listHeight,listWareHouse, profile) => {
    // console.log(listType, listTransportation, types.DATA_DEFAULT_SUCCESS);
    return {
        type: types.DATA_DEFAULT_SUCCESS,
        listCost,
        listCostCustomer,
        listCostDriver,
        listTransportation, 
        listType,
        listShippingUnit,
        listHeight,
        listWareHouse,
        profile
    }
}


// export const datadefault_loading = (loading) => {
//     return {
//         type: types.LOADING,
//         loading
//     }
// }

export const auth_fail =(severity,summary,detail) => {
    return {
        type: types.ERROR_SUCCESS,
        severity
        ,summary
        ,detail
    }
}


export const datadefault_fail = (message) => {
    return {
        type: types.DATA_DEFAULT_FAIL,
        message
    }
}


export function getListDataDefault() {
    // console.log(`${POSITIONBYWAREHOUSE}?wareHouseId=${wareHouseId}`);
    
    return async dispatch => {
        // datadefault_ini()
        dispatch(datadefault_ini());
        dispatch(auth_fail(null, null, null))
        axiosWithHeaders('GET',LISTDATADEFAULT, {})
            .then((data:any) => {
                if(data!==undefined && data!==null && Object.keys(data).length > 0){
                    let response = data.data;
                    let {status, result} = response;
                    // console.log(status, message, result);
                    switch(status){
                        case Status.STATUS_RESPONSE_OK:
                            // console.log(data)
                            // datadefault_success(result);
                            if(result!==undefined && result!==null && Object.keys(result).length > 0){
                                let {
                                    listCost,
                                    listCostCustomer,
                                    listCostDriver,
                                    listTransportation, 
                                    listType,
                                    listShippingUnit,
                                    listHeight,
                                    listWareHouse,
                                    profile
                                } = result;

                                // console.log(result);

                                let dataCost: any = [];
                                if(Array.from(listCost).length > 0){
                                    Array.from(listCost).forEach((item: any)=>{
                                        // console.log(item);
                                        const {costId, costName} = item;
                                        let obj= {
                                            label: costName,
                                            value: false,
                                            costId: costId
                                        }
                                        dataCost.push(obj);
                                    })
                                }

                                let dataCostCustomer: any = [];
                                //Chưa xử lý
                                // if(Array.from(listCostCustomer).length > 0){
                                //     Array.from(listCostCustomer).forEach((item: any)=>{
                                //         console.log(item);
                                //         const {costId, costName} = item;
                                //         let obj= {
                                //             label: costId,
                                //             value: costName
                                //         }
                                //         dataCostCustomer.push(obj);
                                //     })
                                // }

                                let dataCostDriver: any = [];
                                // Chưa xử lý
                                // if(Array.from(dataCostDriver).length > 0){
                                //     Array.from(dataCostDriver).forEach((item: any)=>{
                                //         // console.log(item);
                                //         const {costId, costName} = item;
                                //         let obj= {
                                //             label: costId,
                                //             value: costName
                                //         }
                                //         dataCost.push(obj);
                                //     })
                                // }

                                let dataTransportation: any =[];
                                if(Array.from(listTransportation).length > 0){
                                    Array.from(listTransportation).forEach((item: any)=>{
                                        // console.log(item);
                                        const {transportationName, transportationId} = item;
                                        let obj= {
                                            label: transportationName,
                                            value: transportationId
                                        }
                                        dataTransportation.push(obj)
                                    })
                                }

                                let dataType: any = [];
                                if(Array.from(listType).length > 0){
                                    Array.from(listType).forEach((item: any)=>{
                                        const {typePackageName, typePackageId} = item;
                                        let obj= {
                                            label: typePackageName,
                                            value: typePackageId
                                        }
                                        dataType.push(obj)
                                    })
                                }

                                let dataHeight: any = [];
                                if(Array.from(listHeight).length > 0){
                                    Array.from(listHeight).forEach(item => {
                                        let obj = {
                                            label: item,
                                            value: item
                                        }
                                        dataHeight.push(obj);
                                    })
                                }
                                
                                dispatch(datadefault_success(dataCost, dataCostCustomer, dataCostDriver, dataTransportation, dataType, listShippingUnit, dataHeight, listWareHouse, profile));
                            }
                            break;
                        default:
                            dispatch(auth_fail("error", "Lỗi", "Hệ thống [data_default] đang xảy ra lỗi " + status))
                            // Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi " + status, "error");
                            break;
                    }
                }else{
                    dispatch(auth_fail("error", "Lỗi", "Hệ thống [data_default] đang xảy ra lỗi... "))
                    // Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi...!, Không thể lấy danh sách điểm đến", "error");
                }
            }).catch(err => {
                console.log(err);
                // Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi ", "error");
                dispatch(auth_fail("error", "Lỗi", err.toString()))
                dispatch(datadefault_fail(false));
            });
    }
}
