export const filterTable = [
    {
        field: 'prefix',
        title: 'Từ khóa',
        type: 'string',
        defaultValue: '',
        placeholder: 'Tìm kiếm theo...'
    }
]

export const columnTable = [
    {
        field: 'stt',
        header: '#',
        width: 40,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'storeName',
        header: 'Tên siêu thị',
        width: 60,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'vietnameseName',
        header: 'Tên tiếng việt',
        width: 60,
        align: 'left',
        fontSize: 11
    },
    // {
    //     field: 'specifications',
    //     header: 'Quy cách',
    //     width: 60,
    //     align: 'left',
    //     fontSize: 11
    // },
    {
        field: 'price',
        header: 'Giá sản phẩm',
        width: 55,
        align: 'left',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'action',
        header: '',
        width: 55,
        align: 'left',
        fontSize: 11,
        usedTemplate: true
    }
]