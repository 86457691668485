import * as React from 'react';
import { connect } from 'react-redux';
import {Growl} from 'primereact/growl';

const Error =(props:any)=>{
    let growl;
    React.useEffect(() => {
        if(props.error.severity !== null)
        {
            const{severity,summary,detail} = props.error;
            growl.show({severity: severity, summary: summary, detail: detail});
        }
        
    });
  
    return <Growl ref={(el) => growl = el} style={{paddingTop:70}} />
    
}

const mapStateToProps = state => {
  
    return {
        error: state.error
    }
}

export default connect(mapStateToProps, null)(Error);
