import * as React from 'react';
import FormLayout from '../../components/form/index';
import {filterTable, columnTable} from '../../common/data/DataTableListDataOrder';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import { Icon } from "@blueprintjs/core";
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import DataTables from '../../components/datatable/index';
import * as router from "../../common/router";
import {
    Link
} from "react-router-dom";
import * as api from '../../reducer/contains/api'
import { axiosWithHeaders,axiosWithHeadersNoTokenUploadFile,axiosWithHeadersDownloadFile } from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum'
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import {InputNumber} from 'primereact/inputnumber';
import { ProgressSpinner } from 'primereact/progressspinner';
import Swal from 'sweetalert2';
import moment from 'moment';
 var _ = require('lodash');

const DataOrder = (props: any) => {
    const {profile} = props;
    const [filter, setFilter] = React.useState<any>();
    const [dateSendData, setDateQuestion1] = React.useState();
    const [columnTableNow, setColumnTable] = React.useState<any>({});
    const [button, setButton] = React.useState(true);
    const [shiftId, setShiftId] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [storeId, setstoreId] = React.useState('');
    const [listData, setListData] = React.useState([]);
    const [note, setNote] = React.useState('');
    const [dateFrom, setDateFrom] = React.useState<Date>();
    const [dateTo, setDateTo] = React.useState<Date>();
    const [detail, setDetail] = React.useState<any>();
    const [data, setData] = React.useState<any>([]);
    const [pageNo, setPageNo] = React.useState<number>(0);
    const [pageRows, setPageRows] = React.useState<number>(0);
    const [totalRecords, setTotalRecords] = React.useState<number>(0);
    const [first, setFirst] = React.useState<number>(0);
    React.useEffect(() => {
        if (props.match.params.id !== undefined && props.match.params.id !== null) {
            setstoreId( props.match.params.id);
          //  LoadData(props.match.params.id);
            getDataTable(filterTable, 0, 500,props.match.params.id);
            LoadDetailStore(props.match.params.id)
        }
        else {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!");
            props.history.push(router.ListData);
        }


    }, []);

    const LoadDetailStore=(storeId: any)=>{
        axiosWithHeaders('POST', `${api.GET_STORE}`, { storeId})
        .then((data: any) => {
            if (data !== undefined && data !== null) {
                let response = data.data;
                let { status, message, result } = response;

                if (status === Status.STATUS_RESPONSE_OK) {
                    setDetail(result);
                }
                else {
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                }
            }
            else { props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }

        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
    }

    const getDataTable = (filter: any, pageNo: number, pageRows: number,storeId :any) => {
        setIsLoading(true);
        let obj: any = {};
        if(Array.from(filter).length > 0){
            Array.from(filter).forEach((item: any) => {
                obj[item.field] = item.defaultValue;
            })
            let from = ''
            if (dateSendData !== undefined && dateSendData !== '' && dateSendData !== null)
                from = moment(dateSendData).format('YYYYMMDD')
            
           // alert(from)
            // obj["pageNo"] = pageNo + 1;
            // obj["pageRows"] = pageRows;
            //  console.log(obj)
            axiosWithHeaders('POST', `${api.DETAIL_DATA}`, { storeId,shiftId,dateSend:from })
            .then((data:any) => {
                if(data !== undefined && data !== null && Object.keys(data).length > 0){
                    let response = data.data;
                    let {status, result, message,column} = response;
                  
                    switch(status){
                        case Status.STATUS_RESPONSE_OK:
                            if(Array.from(result).length > 0){
                                let rowIndex: number = 1;
                                Array.from(result).forEach((item: any)=>{
                                    item["stt"] = (pageNo * pageRows) + rowIndex;
                                    rowIndex++;
                                })
                                setIsLoading(false);
                                setData(result);
                                setTotalRecords(result[0].countRow);
                                setFirst(pageNo * pageRows);
                                setFilter(filter);
                                setPageNo(pageNo);
                                setPageRows(pageRows)
                            }else{
                                setIsLoading(false);
                                setData([]);
                                setTotalRecords(0);
                                setFirst(0);
                                setFilter(filter);
                                setPageNo(0);
                                setPageRows(50)
                            }
                            setColumnTable(columnTable(column))
                            break;
                        case Status.STATUS_RESPONSE_FORBIDDEN:
                            props.logOut()
                            break;
                        default:
                            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                            setIsLoading(false);
                            setData([]);
                            setTotalRecords(0);
                            setFirst(0);
                            setFilter(filter);
                            setPageNo(0);
                            setPageRows(50)
                            break;
                    }
                }
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
                setData([]);
                setTotalRecords(0);
                setFirst(0);
                setFilter(filter);
                setPageNo(0);
                setPageRows(50)
                props.error_success('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
            });
        }
    }
    const onClickAdd =(e)=>{
        props.history.push(router.AddDataOrder+"/"+storeId);
    }
  

    const renderDetail = () => {
        if (detail !== undefined && detail !== null) {
            return <div className="p-grid class-store">
                <div className='p-col-12 row'>
                    <div className="p-col-2">
                        <Link to={router.ListData}>
                            <Button icon="direction-left"  large >
                                <label>Quay Lại</label>
                            </Button>
                        </Link>
                
                    </div>
                    <div className="p-col-8">
                        <p style={{ fontSize: '30px', fontWeight: 'bolder' }} >TÊN CỬA HÀNG :{detail.storeName}</p>
                        <p>ĐỊA CHỈ :{detail.storeAddress}</p>
                        <p>HỆ THỐNG PHÂN PHỐI :{detail.chain}</p>
                    </div>
                
                
                    <div className="p-col-2">
                    {(profile.roleId === 1 || profile.roleId === 2) ?
                        
                            <Button text="THÊM MỚI" icon="plus" style={{marginRight: 10}} onClick={(e: any)=> onClickAdd(e)}/>
                        : null
                        }
                    </div>
                </div>
                <div className='p-col-12 row'>
                    <div className='col-12 col-smaller-12 col-small-12 col-medium-4 col-large-4 col-xlarge-3 col-xxlarge-3'>
                            <label className="p-col-3 text-right">Ngày</label>
                            <Calendar className="p-col-4" showTime={false} hourFormat="24" value={dateSendData}  dateFormat="dd/mm/yy" touchUI
                                onChange={(e: any) => setDateQuestion1(e.value)}></Calendar>
                    </div>
                    <div className="col-12 col-smaller-12 col-small-12 col-medium-2 col-large-2 col-xlarge-2 col-xxlarge-2">
                           <div className="p-calendar p-col-6 p-inputwrapper-filled">
                                 <Button text="Tìm kiếm" icon="search" style={{marginRight: 10}} onClick={(e: any)=>   getDataTable(filterTable, 0, 500,storeId)}/>
                           </div>
                    </div>
                </div>
               
            </div>
        }
        return null
    }
    const delCustomer = (e: any, customerId: number) => {
        e.preventDefault();
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa khách hàng này khỏi danh sách ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: "Hủy"
        }).then((result) => {
            if (result.value) {
                axiosWithHeaders('POST', api.DEL_CUSTOMER, {customerId})
                .then((data: any) => {
                    if(data!==undefined && data!==null && Object.keys(data).length > 0){
                        let response = data;
                        let {status} = response;
                        switch(status){
                            case Status.STATUS_RESPONSE_OK:
                                Swal.fire("Thông báo", "Xóa dữ liệu thành công", "success")
                                getDataTable(filter, pageNo, pageRows,storeId);
                                break;
                            case Status.STATUS_RESPONSE_FORBIDDEN:
                                props.logOut();
                                break;
                            default:
                                Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi " + status, "error");
                                break;
                        }
                    }else{
                        Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi...!, ", "error");
                    }
                }).catch(err => {
                    console.log(err);
                    Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi ", "error");
                });
            }
        })
    }

    const cellTemplate = (row: any, cell: any) =>{
        let {field} = cell;
        // console.log(field);
        if(row.nameCustomer === 'TOTAL')
        {
            switch(field){
                case 'nameCustomer':
                    return (
                        <p style={{color:'red'}}>
                        {row.nameCustomer} 
                        </p>
                    )
                case 'costOrder':
                    return (
                        <p style={{color:'red'}}>
                        {row.costOrder.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})} 
                        </p>
                    )
                case 'giftMask':
                    return (row["giftMask"] !== 0 && row["giftMask"] !== '' && row["giftMask"] !== null ?
                        <p style={{color:'red'}}>
                            {row["giftMask"]} 
                    </p> :
                        <>
                        -
                        </>
                )
                case 'giftKhan':
                    return (row["giftKhan"] !== 0 && row["giftKhan"] !== ''&& row["giftKhan"] !== null ?
                        <p style={{color:'red'}}>
                            {row["giftKhan"]} 
                    </p> :
                        <>
                        -
                        </>
                )
                case 'giftTho':
                    return (row["giftTho"] !== 0 && row["giftTho"] !== '' && row["giftTho"] !== null ?
                    <p style={{color:'red'}}>
                            {row["giftTho"]} 
                    </p> :
                        <>
                        -
                        </>
                )
                case 'action':
                    return (
                        <>
                        </>
                    )
                default:
                    return (row[field] !== 0 ?
                        <p style={{color:'red'}}>
                            {row[field].toString()} 
                        </p> :
                        <>
                            -
                        </>
                    )
            }
        }
        else
        {
            switch(field){
                case 'nameCustomer':
                    return (
                        <>
                        {row.nameCustomer} 
                        </>
                    )
                case 'costOrder':
                    return (
                        <>
                        {row.costOrder.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})} 
                        </>
                    )
                case 'giftMask':
                    return (row["giftMask"] !== 0 && row["giftMask"] !== '' && row["giftMask"] !== null ?
                        <>
                            {row["giftMask"]} 
                       </> :
                        <>
                           -
                        </>
                   )
                case 'giftKhan':
                    return (row["giftKhan"] !== 0 && row["giftKhan"] !== ''&& row["giftKhan"] !== null ?
                        <>
                            {row["giftKhan"]} 
                       </> :
                        <>
                           -
                        </>
                   )
                case 'giftTho':
                    return (row["giftTho"] !== 0 && row["giftTho"] !== '' && row["giftTho"] !== null ?
                        <>
                            {row["giftTho"]} 
                       </> :
                        <>
                           -
                        </>
                   )
                case 'action':
                    return (
                        <>
                           <Link className="bp3-button bp3-small bp3-intent-success add-booking" to={router.DetailCustomer+"/"+ row["customerId"]}>
                                 <Icon icon="cog" />
                            </Link>
                            <Link className="bp3-button bp3-small bp3-intent-primary add-booking" to={router.UpdateCustomerOrder+"/"+ row["customerId"]+"/"+row["storeId"]}>
                                <i className="fa fa-edit"></i>
                            </Link>
                            <Button icon="trash" intent="danger" small style={{marginLeft: 5}} onClick={(e)=> delCustomer(e, row["customerId"])}/>
                        </>
                    )
                default:
                    return (row[field] !== 0 ?
                         <>
                             {row[field].toString()} 
                        </> :
                         <>
                            -
                         </>
                    )
            }
        }
    }

    const renderDataTable = () => {
        if(columnTableNow!==undefined && columnTableNow!== null && Array.from(columnTableNow).length > 0){
            return (
                <DataTables 
                    columns={columnTableNow}
                    loading={isLoading}
                    data={data}
                    rows={pageRows}
                    totalRecords={totalRecords}
                    first={first}
                   // onPage={(e)=> onPage(e)}
                    cellTemplate={cellTemplate}
                    frozenWidth="300px"
                    className="table-data-order"
                />
            )
        }
        return null
    }
    return (
        <div>
            <FormLayout formTitle="CHI TIẾT CỬA HÀNG">
                {renderDetail()}
                {renderDataTable()}
               
            </FormLayout>
        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity, summary, detail) => dispatch(actions.error_success(severity, summary, detail))
    }
}
const mapStateToProps = (state:any) => {
    return {
      profile:state.auth.profile
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(DataOrder);
