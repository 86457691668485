import * as router from './router';

export const dataMenu = (roleId)=>{
    let obj=[
        {
            groupName: "Chuyển hướng",
            items:[
                {
                    title: "Trang chủ",
                    description: "Trang chủ",
                    icon: "ik ik-home",
                    href: router.Home,
                    parentIndex: 0,
                    menuIndex: 1
                }
            ]
        }
    ];
   
    return obj;
} 

/*1: ADMIN IT, 2:ADMIN, 3: KE TOAN, 4: KHO, 5: KHACH HANG, 6: TAI XE, 7: DIEU PHOI XE, 8: KE TOAN KH*/
export const dataMenus = [
    {
		groupName: "Quản lý",
		items: [
			{
				title: "Tài khoản",
				description: "Danh sách tài khoản",
				icon: "ik ik-truck",
				href: router.ListUser,
                menuIndex: 2,
                parentIndex: 0,
				listRole: [1,2]
            },
            {
				title: "Danh sách cửa hàng",
				description: "Danh sách cửa hàng",
				icon: "ik ik-package",
				href: "#",
                menuIndex: 3,
                parentIndex: 0,
				groupName: "",
                items: [
                    {
                        title: "Danh sách cửa hàng",
                        description: "Danh sách cửa hàng",
                        icon: "ik ik-package",
                        href: router.ListStore,
                        menuIndex: 1,
                        parentIndex: 3,
                        listRole: [1]
                    },
                    {
                        title: "Danh sách sản phẩm",
                        description: "Danh sách sản phẩm",
                        icon: "ik ik-package",
                        href: router.ListProduct,
                        menuIndex: 2,
                        parentIndex: 3,
                        listRole: [1]
                    },
                   
                    {
                        title: "DS giá sản phẩm siêu thị",
                        description: "Danh sách giá sản phẩm theo siêu thị",
                        icon: "ik ik-package",
                        href: router.ListStoreProductPrice,
                        menuIndex: 3,
                        parentIndex: 3,
                        listRole: [1]
                    },
                    {
                        title: "Danh sách cửa hàng - user",
                        description: "Danh sách cửa hàng - user",
                        icon: "ik ik-user-check",
                        href: router.ListStoreUser,
                        menuIndex: 4,
                        parentIndex: 3,
                        listRole: [1,2]
                    },
                    {
                        title: "Danh sách quà tặng",
                        description: "Danh sách quà tặng",
                        icon: "ik ik-package",
                        href: router.ListGift,
                        menuIndex: 5,
                        parentIndex: 3,
                        listRole: [1]
                    },
				],
				listRole: [1,2]
            },
           
            // {
			// 	title: "Master Plan",
			// 	description: "Master Plan",
			// 	icon: "ik ik-package",
			// 	href: router.ListMasterPlan,
            //     menuIndex: 7,
            //     parentIndex: 0,
			// 	listRole: [1]
            // },
            
            {
				title: "REPORT",
				description: "REPORT",
				icon: "ik ik-map",
				href: "#",
				menuIndex: 9,
				parentIndex: 0,
				groupName: "",
                items: [
                    {
                        title: "Report Cửa Hàng",
                        description: "Report Từng Cửa Hàng Theo Từng Ngày",
                        icon: "ik ik-navigation-2",
                        href: router.ListData,
                        listRole: [1, 2, 3],
						menuIndex: 1,
						parentIndex: 9,
						groupName: "REPORT"
                    },
                    // {
                    //     title: "Sumary Report_1",
                    //     description: "WHOLE PLAN BY CHAIN",
                    //     icon: "ik ik-shopping-bag",
                    //     href: router.ReportViewSumary1,
                    //     listRole: [1, 3],
					// 	menuIndex: 2,
					// 	parentIndex: 9,
					// 	groupName: "REPORT"
                    // }, 
                    // {
                    //     title: "Sumary Report_2",
                    //     description: "WHOLE PLAN BY TIME",
                    //     icon: "ik ik-shopping-bag",
                    //     href: router.ReportViewSumary2,
                    //     listRole: [1, 3],
					// 	menuIndex: 3,
					// 	parentIndex: 9,
					// 	groupName: "REPORT"
                    // }, 
                    // {
                    //     title: "Sumary Report_3",
                    //     description: "WHOLE PLAN BY STORE",
                    //     icon: "ik ik-shopping-bag",
                    //     href: router.ReportViewSumary3,
                    //     listRole: [1, 3],
					// 	menuIndex: 4,
					// 	parentIndex: 9,
					// 	groupName: "REPORT"
					// }, 
					// {
                    //     title: "Sumary Report_4",
                    //     description: "WHOLE PLAN STOCK CONTROL",
                    //     icon: "ik ik-shopping-bag",
                    //     href: router.ReportViewPlanStock,
                    //     listRole: [1, 3],
					// 	menuIndex: 5,
					// 	parentIndex: 9,
					// 	groupName: "REPORT"
					// }, 
                    {
                        title: "Download Report",
                        description: "Report",
                        icon: "ik ik-shopping-bag",
                        href: router.Report,
                        menuIndex: 6,
                        listRole: [1, 3],
						parentIndex: 9,
						groupName: "REPORT"
					}
				],
				listRole: [1, 2,3]
			},
		],
		listRole: [1, 2, 3]
	}
	
]





export const filterUser = [
    {
        field: 'prefix',
        title: 'Từ khóa',
        type: 'string',
        defaultValue: '',
        placeholder: 'Tìm kiếm theo...'
    }
]

export const columnUser = [
    {
        field: 'stt',
        header: '#',
        width: 40,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'code',
        header: 'Mã NV',
        width: 60,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'name',
        header: 'Nhân viên',
        width: 100,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'address',
        header: 'Địa chỉ',
        width: 300,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'username',
        header: 'Tài khoản',
        width: 70,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'phone',
        header: 'Di dộng',
        width: 70,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'roleName',
        header: 'Quyền truy cập',
        width: 100,
        align: 'left',
        fontSize: 11
    }, {
        field: 'action',
        header: '',
        width: 55,
        align: 'left',
        fontSize: 11,
        usedTemplate: true
    }
]



export const listRole = (roleId)=>{
    if(roleId === 1)
   return  [
        {
            value: 2,
            label: "Moderator"
        },
        {
            value: 3,
            label: "Client"
        },
        {
            value: 4,
            label: "Auditer Banner"
        },
        {
            value: 5,
            label: "Auditer Thu Đổi Quà"
        }
    ]
    return  [
        {
            value: 4,
            label: "Auditer Banner"
        },
        {
            value: 5,
            label: "Auditer Thu Đổi Quà"
        }
    ]
} 
