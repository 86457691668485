
import React, {useEffect, useState} from 'react';
import { FileInput } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import {
    FILE_UPLOAD
} from '../../reducer/contains/api';
import * as Status from '../../common/enum'
import {
    GetURL
} from '../../common/config'
import {axiosWithHeadersFormData} from '../../reducer/contains/axiosWrapper';
const defaultUrl = require("../../assets/images/avatar/user.png");

const UploadAvatar = (props: any) => {
    const {urlAvatar, onChange} = props;
    // console.log(urlAvatar);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [srcImages, setSrcImages] = useState<string>("");
    const [fileName, setFileName] = useState<string>("Chọn hình ảnh...");

    const getUrlAvatar = (urlAvatar: any) => {
        let srcImage: string = "";        
        if(urlAvatar!==undefined && urlAvatar!==null && urlAvatar!==""){
            srcImage = GetURL() + "/" + urlAvatar;
        }

        if(srcImage===undefined || srcImage === null || srcImage===""){
            srcImage = defaultUrl;
        }
        //Hình sau khi upload 
        if(srcImages!==""){
            srcImage = GetURL() + "/" + srcImages;
        }

        return srcImage;
    }

    const uploadFile = (file: any) => {
        let formData = new FormData();
        // console.log(file)
        formData.append('file', file); 

        axiosWithHeadersFormData('post', FILE_UPLOAD, formData)
        .then((response: any) => {
            // console.log(response)
            if(response!==undefined && response!==null && Object.keys(response).length > 0){
                let {status, data} = response;
                // console.log(status, data);
                switch(status){
                    case Status.STATUS_RESPONSE_OK:
                        // console.log(data);
                        const {originalname, filePath} = data;
                        // console.log(originalname, filePath);
                        setFileName(originalname);
                        setSrcImages(filePath);
                        return onChange(filePath);
                        // break;
                    default:
                        props.error_success("error", "Lỗi", "Không thể upload ảnh");
                        break;
                }
            }else{
                // Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi...!, Không thể lấy danh sách điểm đến", "error");
                props.error_success("error", "Lỗi", "Không thể upload ảnh");
            }
        }).catch(err => {
            console.log(err);
            // alert("Lỗi hệ thống!");
            // dispatch(router_fail(false));
        });
    }
    
    const onInputChange = (e: any) =>{
        let files = e.target.files;
        setDisabled(true);
        // console.log(files, Array.from(files));

        if(Array.from(files).length === 0){
            // console.log(1);
            setFileName("Chọn avatar...");
            return;
        }
        
        const fileUpload = files[0];

        var pattern = /image-*/;

        if (!fileUpload.type.match(pattern)) {
            props.error_success("error", "Lỗi", "Vui lòng chọn hình ảnh !");
            return;
        }

        uploadFile(fileUpload);
    }

    return (
        <div className="row">
            <div className="col-4 col-smaller-4 col-small-4 col-medium-4 col-large-4 col-xlarge-4 col-xxlarge-4">

            </div>
            <div className="col-4 col-smaller-4 col-small-4 col-medium-4 col-large-4 col-xlarge-4 col-xxlarge-4">
                <div className="row">
                    <div className="col-12 col-smaller-12 col-small-12 col-medium-12 col-large-12 col-xlarge-12 col-xxlarge-12">
                        <div className="img-avatar">
                            <img src={getUrlAvatar(urlAvatar)} alt="Ảnh đại diện" title="Hình đại diện" />
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop: 10}}>
                    <div className="col-2 col-smaller-2 col-small-2 col-medium-2 col-large-2 col-xlarge-2 col-xxlarge-2">
                    </div>
                    <div className="col-8 col-smaller-8 col-small-8 col-medium-8 col-large-8 col-xlarge-8 col-xxlarge-8">
                        {/* <label className="bp3-file-input">
                            <input type="file" accept="image/*" />
                            <span className="bp3-file-upload-input">{fileName}</span>
                        </label> */}
                        <FileInput 
                            text={fileName} buttonText={"Chọn..."} 
                            onInputChange={onInputChange} 
                            fill
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-2 col-smaller-2 col-small-2 col-medium-2 col-large-2 col-xlarge-2 col-xxlarge-2">
                    </div>
                </div>
            </div>
            <div className="col-4 col-smaller-4 col-small-4 col-medium-4 col-large-4 col-xlarge-4 col-xxlarge-4">

            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        // uploadFile: (file: any) => dispatch(actions.uploadFile(file)),
        error_success: (severity, summary, detail) => dispatch(actions.error_success(severity, summary, detail)),
    }
}

export default connect(null, mapDispatchToProps)(UploadAvatar);