export const filterTable = (province)=> [
    {
        field: 'dateFrom',
        title: 'TỪ NGÀY',
        type: 'date',
        defaultValue: null,
        placeholder: 'Tìm kiếm theo...'
    },
    {
        field: 'dateTo',
        title: 'ĐẾN NGÀY',
        type: 'date',
        defaultValue: null,
        placeholder: 'Tìm kiếm theo...'
    },
    {
        field: 'prefix',
        title: 'Tỉnh thành',
        type: 'dropdown',
        defaultValue: '',
        placeholder: 'Tìm kiếm theo...',
        data:province,
        optionLabel:"provinceName", 
        optionValue:"provinceId" 
    }
    // {
    //     field: 'storeId',
    //     title: 'Siêu thị',
    //     type: 'dropdown',
    //     defaultValue: 0,
    //     placeholder: 'Tìm kiếm theo...',
    //     data:stores
    // }
]

export const columnTable = [
    {
        field: 'chain',
        header: 'ITEM',
        width: 150,
        align: 'left',
        frozen:true,
        fontSize: 11
    },
    {
        field: 'shiftPlan',
        header: 'Plan',
        width: 100,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'shiftActual',
        header: 'Actual',
        width: 100,
        align: 'right',
        usedTemplate: true 
    },
    {
        field: 'percentPlan',
        header: '%',
        width: 100,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'kpiPlan',
        header: 'PLan',
        width: 120,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'kpiPlanUTD',
        header: 'Plan UTD',
        width: 120,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'costOrder',
        header: 'Actual',
        width: 120,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'percentKpiUTD',
        header: '% plan UTD',
        width: 100,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'percentKpi',
        header: '% total kpi',
        width: 100,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    }
]