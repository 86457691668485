import * as React from 'react';
import { connect } from 'react-redux';
import { Button } from "@blueprintjs/core";
import * as actions from '../../reducer/actions/index';
import { InputText } from 'primereact/inputtext';
import { axiosWithHeadersDownloadFile, axiosWithHeaders } from '../../reducer/contains/axiosWrapper'
import FormLayout from '../../components/form/index';
import { Calendar } from 'primereact/calendar';
import * as Status from '../../common/enum';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import { Tree } from 'primereact/tree';
import * as api from '../../reducer/contains/api'

import moment from 'moment';
var _ = require('lodash');

const Home = (props: any) => {
    const [dataReport, setDataReport] = React.useState<any>([
        {
            "key": 0,
            "label": "Danh sách báo cáo",
            "expanded": true,
            "children": [
                {
                    "key": 1,
                    "label": "Báo cáo tổng",
                    "api": api.REPORT_ALL,
                    "namefile": "ReportAll.xlsx",
                    "method": "POST",
                    "validateFrom": false
                },
                // {
                //     "key": 2,
                //     "label": "Báo cáo Form F",
                //     "api": api.REPORT_FORM_F,
                //     "namefile": "ReportFormF.xlsx",
                //     "method": "POST",
                //     "validateFrom": true
                // },
                // {
                //     "key": 2,
                //     "label": "Báo cáo tổng quà tồn",
                //     "api": api.REPORT_HANGTONTHAP,
                //     "namefile": "ReportHangTon.xlsx",
                //     "method": "POST",
                //     "validateFrom": true
                // },
                // {
                //     "key": 3,
                //     "label": "Báo cáo tổng-quà đã ứng",
                //     "api": api.REPORT_UNGQUA,
                //     "namefile": "ReportDaUng.xlsx",
                //     "method": "POST",
                //     "validateFrom": true
                // },
               
                // {
                //     "key": 4,
                //     "label": "Báo cáo tổng - quà đã thu hồi",
                //     "api": api.REPORT_THUHOI,
                //     "namefile": "ReportDaThuHoi.xlsx",
                //     "method": "POST",
                //     "validateFrom": true
                // }
            ]
        }
    ]);
    const [dateFrom, setDateFrom] = React.useState();
    const [isLoading, setIsloading] = React.useState(false);
    const [showReport, setShowReport] = React.useState(false);
    const [dateTo, setDateTo] = React.useState();
    const [listUser, setListUser] = React.useState([]);
    const [userId, setUserId] = React.useState(0);
    const [expandedKeys, setExpandedKeys] = React.useState({});
    const [title, setTitle] = React.useState('BÁO CÁO');
    const [id, setId] = React.useState(0);
    const [apiReport, setApiReport] = React.useState('');
    const [nameFile, setNameFile] = React.useState('');
    // const ReportFormF = () => {
    //     setIsloading(true);
    //     let from = ''
    //     if (dateFrom !== undefined && dateFrom !== '' && dateFrom !== null)
    //         from = moment(dateFrom).format('YYYYMMDD')
    //     let to = ''
    //     if (dateTo !== undefined && dateTo !== '' && dateTo !== null)
    //         to = moment(dateTo).format('YYYYMMDD')
    //     axiosWithHeadersDownloadFile('POST', `${api.REPORT_FORM_F}`, { dateFrom: from, dateTo: to }, 'ReportFormF.xlsx')
    //         .then((data: any) => {
    //             setIsloading(false);
    //             // console.log(data)
    //         }).catch(err => {
    //             //  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
    //         });
    // }
    React.useEffect(() => {
       if( props.profile.roleId === 1 || props.profile.roleId ===2 ){
        let data = [
            {
                "key": 0,
                "label": "Danh sách báo cáo",
                "expanded": true,
                "children": [
                    {
                        "key": 1,
                        "label": "Báo cáo tổng ",
                        "api": api.REPORT_ALL,
                        "namefile": "ReportAll.xlsx",
                        "method": "POST",
                        "validateFrom": false
                    },
                    // {
                    //     "key": 2,
                    //     "label": "Báo cáo Form F",
                    //     "api": api.REPORT_FORM_F,
                    //     "namefile": "ReportFormF.xlsx",
                    //     "method": "POST",
                    //     "validateFrom": true
                    // },
                    
                ]
            }
        ]
        setDataReport(data);
       }
        
        let _expandedKeys = { ...expandedKeys };
        _expandedKeys['0'] = true;
        setExpandedKeys(_expandedKeys);
    }, []);
    const LoadUser = (roleId) => {
        axiosWithHeaders('POST', api.LIST_USER_ROLE, {roleId})
            .then((response: any) => {
                if (response !== undefined && response !== null && Object.keys(response).length > 0) {

                    let { status, message, result } = response.data;
                    switch (status) {
                        case Status.STATUS_RESPONSE_OK:

                            setListUser(result);
                            break;
                        case Status.STATUS_RESPONSE_FORBIDDEN:
                            props.logOut();
                            break;
                        default:
                            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                            break;
                    }
                } else {
                    props.error_success('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
                }
            }).catch(err => {
                console.log(err);
                props.error_success('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
            });
    }
    const onSelectItem = (event) => {
        const reportId = event.value;
        setId(event.value)
        if (reportId !== null) {
            const report = _.find(dataReport[0].children, function (item) { return item.key === reportId });
            setUserId(0);
            setDateFrom(undefined)
            setDateTo(undefined)
            setNameFile(report.namefile)
            setApiReport(report.api)
            setTitle(report.label)
            setShowReport(true)
            LoadUser(reportId)
        }
    }
    const DownloadReport = () => {
        setIsloading(true);
        let from = ''
        if (dateFrom !== undefined && dateFrom !== '' && dateFrom !== null)
            from = moment(dateFrom).format('YYYYMMDD')
        let to = ''
        if (dateTo !== undefined && dateTo !== '' && dateTo !== null)
            to = moment(dateTo).format('YYYYMMDD')
        axiosWithHeadersDownloadFile('POST', apiReport, { dateFrom: from, dateTo: to,userId }, nameFile)
            .then((response: any) => {
                setIsloading(false);
                // console.log(data)
            }).catch(err => {
                setIsloading(false);
                props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
            });
    }
    return (
        <>
            <FormLayout formTitle="Báo Cáo">
                <div className='p-grid'>
                    <div className="p-col-4 col-sm-1">
                        <Tree
                            value={dataReport as any}
                            selectionMode="single"
                            expandedKeys={expandedKeys}
                            onToggle={e => setExpandedKeys(e.value)}
                            onSelectionChange={(event) => onSelectItem(event)}
                          //  filter={true}
                        />
                    </div>
                    <div className='p-col-8  col-smaller-12 col-md-8 col-lg-8 col-xl-8'>
                        {showReport ? <>
                            <div className='p-col-12' >
                                <h2>{title}</h2>
                            </div>
                            <div className='p-col-12 row'>
                                <div className='p-col-4  p-sm-12 col-smaller-12'>
                                    <Calendar
                                        value={dateFrom}
                                        onChange={(e: any) => setDateFrom(e.value)}
                                        placeholder="Chọn từ ngày"
                                        style={{ width: "100%" }}
                                        dateFormat="dd/mm/yy"
                                        //  readOnlyInput
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        yearRange="2020:2021"
                                        className="filter-item"
                                    >
                                    </Calendar>
                                </div>
                                <div className='p-col-4  p-sm-12 col-smaller-12'>
                                    <Calendar
                                        value={dateTo}
                                        onChange={(e: any) => setDateTo(e.value)}
                                        placeholder="Chọn đến ngày"
                                        style={{ width: "100%" }}
                                        dateFormat="dd/mm/yy"
                                        //  readOnlyInput
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        yearRange="2020:2021"
                                        className="filter-item"
                                    >
                                    </Calendar>
                                </div>
                            </div>
                            <div className='p-col-12 row'>
                                <div className='p-col-12'>
                                    <Button icon="saved" onClick={() => DownloadReport()}  >
                                        <label>Tải báo cáo</label>
                                    </Button>
                                </div>
                            </div>

                            {isLoading ? <div className='p-col-12' style={{ margin: 'auto', display: 'flex' }} >
                                <ProgressSpinner />
                            </div> : null
                            }


                        </> : null}
                    </div>
                </div>

            </FormLayout>
        </>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity, summary, detail) => dispatch(actions.error_success(severity, summary, detail))
    }
}
const mapStateToProps = (state:any) => {
    return {
      profile:state.auth.profile
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(Home);
