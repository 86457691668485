import * as React from 'react';
import FormLayout from '../../components/form/index';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import * as router from "../../common/router";
import {
    Link
} from "react-router-dom";
import * as api from '../../reducer/contains/api'
import { axiosWithHeaders,axiosWithHeadersNoTokenUploadFile,axiosWithHeadersDownloadFile } from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum'
import TableThuDoi from './TableThuDoi'
import TableBanner from './TableBanner'
import TableUngQua from './TableUngQua'
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import {InputNumber} from 'primereact/inputnumber';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';
 var _ = require('lodash');

const AddDataOrder = (props: any) => {
    const [storeId, setstoreId] = React.useState(0);
    const [code, setCode] = React.useState('');
    const [note, setNote] = React.useState('');
    const [products, setProducts] = React.useState([]);
    const [dateSendData, setDateQuestion1] = React.useState<Date>(new Date());
    const [lng, setLng] = React.useState<any>(0);
    const [lat, setLat] = React.useState<any>(0);
    const [shiftId, setShiftId] = React.useState(0);
    const [userId, setUserId] = React.useState(0);
    const [roleId, setRoleId] = React.useState(5);
    const [listSup, setListSup] = React.useState([]);
    const [listPG, setListPG] = React.useState([]);
    const [listStore, setListStore] = React.useState([]);
    const [nameCustomer, setNameCustomer] = React.useState('');
    const [phoneCustomer, setPhoneCustomer] = React.useState('');
    const [listFile, setListFile] = React.useState([]) as any;
    const [gift1, setGift1] = React.useState(0);
    const [gift2, setGift2] = React.useState(0);
    const [gift3, setGift3] = React.useState(0);
    const [gift4, setGift4] = React.useState(0);
    const [gift5, setGift5] = React.useState(0);
    React.useEffect(() => {  

            LoadStore(props.match.params.id);
           // LoadUser();
    }, []);


    const LoadStore=(store:any)=>{
        axiosWithHeaders('GET', `${api.LIST_STORE_FULL}`, null)
        .then((data: any) => {
            if (data !== undefined && data !== null) {
                let response = data.data;
                let { status, message, result } = response;

                if (status === Status.STATUS_RESPONSE_OK) {
                    setListStore(result);
                    if (store !== undefined && store !== null){
                        setstoreId(parseInt(store));
                        LoadUser(store)
                        LoadProduct(store)
                    } 
                }
                else {
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                }
            }
            else { props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }

        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
    }

    const LoadUser = (storeId :any) => {
        axiosWithHeaders('POST', api.LIST_USER_BY_STOREID,{storeId:storeId})
            .then((response: any) => {
                if (response !== undefined && response !== null && Object.keys(response).length > 0) {
                 
                    let { status, message, result } = response.data;
                    switch (status) {
                        case Status.STATUS_RESPONSE_OK:
                       
                         //  let sup = _.filter(result, function(o) { return o.roleId === 4; })
                           let pg =_.filter(result, function(o) { return o.roleId === 5; })
                        //    // console.log(thudoi,ungqua)
                        //     setListSup(sup);
                            setListPG(pg);
                            break;
                        case Status.STATUS_RESPONSE_FORBIDDEN:
                            props.logOut();
                            break;
                        default:
                            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                            break;
                    }
                } else {
                    props.error_success('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
                }
            }).catch(err => {
                console.log(err);
                props.error_success('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
            });
    }

    const LoadProduct = (storeId :any) => {
        axiosWithHeaders('GET', `${api.LIST_PRODUCT_STORE_ID_PRICE}${storeId}`,null)
            .then((response: any) => {
                if (response !== undefined && response !== null && Object.keys(response).length > 0) {
                 
                    let { status, message, result } = response.data;
                    switch (status) {
                        case Status.STATUS_RESPONSE_OK:
                            setProducts(result);
                            break;
                        case Status.STATUS_RESPONSE_FORBIDDEN:
                            props.logOut();
                            break;
                        default:
                            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                            break;
                    }
                } else {
                    props.error_success('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
                }
            }).catch(err => {
                console.log(err);
                props.error_success('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
            });
    }
    const onValueChange = (e, field) => {
        let value = e.value !== undefined && e.value !== null ? e.value : 0
        switch(field){
            case 'gift1':
                setGift1(value);
                break;
            case 'gift2':
                setGift2(value);
                break;
            case 'gift3':
                setGift3(value);
                break;
            case 'gift4':
                setGift4(value);
                break;
            case 'gift5':
                setGift5(value);
                break;
            default:
                break;
        }
    }
  
  
    const SaveData =()=>{
        if( dateSendData === undefined || dateSendData ===null)
        {
            props.error_success('error', 'Lỗi', "Vui lòng chọn thời gian" );
            return false;
        }
       
        if( userId === undefined || userId ===0)
        {
            props.error_success('error', 'Lỗi', "Vui lòng chọn tài khoản" );
            return false;
        }
        // if( shiftId === 0)
        // {
        //     props.error_success('error', 'Lỗi', "Vui lòng chọn đúng thời gian và đúng ca" );
        //     return false;
        // }
        let obj = {
            storeId
            ,lng
            ,lat
            ,roleId:roleId
            ,dateSend:moment(dateSendData).format('YYYY-MM-DD HH:mm:ss.SSS') 
            ,userId
            ,code
            ,note
            ,shiftId
            ,infogroup:moment().valueOf()
            ,products
            ,nameCustomer
            ,phoneCustomer
            ,gift1
            ,gift2
            ,gift3
            ,gift4
            ,gift5
            ,listImage :listFile
        }
        axiosWithHeaders('POST', api.DATA_POST_INFOMATION_ORDER_WEB,obj)
        .then((response: any) => {
            if (response !== undefined && response !== null && Object.keys(response).length > 0) {
                let { status, message } = response.data;
                switch (status) {
                    case Status.STATUS_RESPONSE_OK:
                        props.history.push(router.DataOrder+"/"+storeId);
                        break;
                    case Status.STATUS_RESPONSE_FORBIDDEN:
                        props.logOut();
                        break;
                    default:
                        props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                        break;
                }
            } else {
                props.error_success('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
            }
        }).catch(err => {
            console.log(err);
            props.error_success('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
        });

    }
    
    const convertImageto64 = (file, item) => {
        var f = new FormData();
        f.append("file", file)
        axiosWithHeadersNoTokenUploadFile('POST', `${api.FILE_UPLOAD}`, f)
            .then((data: any) => {
                if (data !== undefined && data.data.filePath !== undefined) {
                    var obj = {
                        questionId: item.questionId,
                        answer: data.data.filePath,
                        dateCreate: dateSendData
                    }
                    let list = listFile;
                    list.push(obj);
                    setListFile(list)
                }
            }).catch(err => console.log(err));
    }

    const onChangeFile = (event, item) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const fileList = Array.from(files);
            fileList.map((image: any) => {
                convertImageto64(image, item)
            });
        }
    }
    const onChangeStore=(value:any)=>{
        setstoreId(value);
        LoadUser(value);
        LoadProduct(value);
    }

    const onValueChangePrice = (e,product:any) => {
        let now = products;
        now.map((item :any,idx)=>{
            if(item.productName === product.productName && item.vietnameseName === product.vietnameseName)
            {
                item.cost = e.value !== undefined && e.value !== null ? e.value : 0
            }
        })
        setProducts(now)
    }
    const onValueChangeQuantity = (e,product:any) => {
        let now = products;
        now.map((item :any,idx)=>{
            if(item.productName === product.productName && item.vietnameseName === product.vietnameseName)
            {
                item.quantity =  e.value !== undefined && e.value !== null ? e.value : 0
            }
        })
        setProducts(now)
    }

    const renderTable =()=>{
        let arr =[] as any;
        if(products !== undefined && products !== null && products !== [] && products.length > 0)
        {
            products.map((item :any,idx)=>{
                
                arr.push(<div key={idx} className="p-grid" style={{ marginTop: -7 }}>
                <div className="p-col-4 text-right">
                     <label>{item.productName}</label>
                </div>
                {/* <div className="p-col-2 text-right">
                     <label>{item.vietnameseName}</label>
                </div> */}
                <div className="p-col-1 text-center">
                     <label>Số lượng :</label>
                </div>
                <div className="p-col-2">
                    <InputNumber  value={item.quantity} placeholder="0"  min={0} max={400000000}
                        style={{ width: '100%' }} inputStyle={{ textAlign: 'right' }} onChange={(e) => onValueChangeQuantity(e,item)} />
                </div>
                <div className="p-col-1 text-center">
                     <label>Giá :</label>
                </div>
                <div className="p-col-2">
                    <InputNumber  value={item.cost} placeholder="0"  min={0} max={400000000}
                        style={{ width: '100%' }} inputStyle={{ textAlign: 'right' }} onChange={(e) => onValueChangePrice(e,item)} />
                </div>
            
            </div>)
            })
        }
        return arr;
    }
   
    return (
        <div>
            <FormLayout formTitle="THÊM MỚI THÔNG TIN">
                    <div className='p-col-12'>
                        <div className='p-grid'>
                            <label className="p-col-4 text-right">Thời gian thực hiện</label>
                            <Calendar className="p-col-3" showTime={true} hourFormat="24" value={dateSendData}  dateFormat="dd/mm/yy" touchUI 
                                onChange={(e: any) => setDateQuestion1(e.value)}></Calendar>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Chọn Siêu Thị</label>
                            </div>
                            <div className="p-col-4">
                                <Dropdown 
                                    
                                    value={storeId} 
                                
                                    name="storeName"
                                    optionLabel="storeName" 
                                    optionValue="storeId" 
                                    options={listStore} 
                                    placeholder="Chọn siêu thị..." 
                                    filter={true} 
                                    filterPlaceholder="Tìm..." 
                                    filterBy="storeId,storeName"
                                                        
                                    // filterMatchMode="contains"
                                    style={{width : '100%'}} 
                                    onChange={(e: any)=> onChangeStore(e.target.value)}
                                />
                
                            </div>
                        </div>
                        {/* <div className="p-grid">
                           
                           <label className="p-col-4 text-right">CA</label>
                         
                           <div className="p-col-4">
                               <Dropdown 
                                   value={shiftId} 
                                   optionLabel="shiftName" 
                                   optionValue="shiftId" 
                                   options={[{"shiftId":1,"shiftName":"AM"},{"shiftId":2,"shiftName":"PM"}]} 
                                   placeholder="Chọn Ca thực hiện..." 
                                   filter={true} 
                                   filterPlaceholder="Tìm..." 
                                   filterBy="shiftId,shiftName"
                                   // filterMatchMode="contains"
                                   style={{width : '100%'}} 
                                   onChange={(e: any)=> setShiftId(e.value)}
                               />
                           </div>
                       </div> */}
                        <div className="p-grid">
                            <label className="p-col-4 text-right">Nhân viên audit</label>
                            <div className="p-col-4">
                                <Dropdown 
                                    value={userId} 
                                    optionLabel="name" 
                                    optionValue="userId" 
                                    options={listPG} 
                                    placeholder="Chọn user audit..." 
                                    filter={true} 
                                    filterPlaceholder="Tìm..." 
                                    filterBy="userId,name"
                                    // filterMatchMode="contains"
                                    style={{width : '100%'}} 
                                    onChange={(e: any)=> setUserId(e.value)}
                                />
                            </div>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                                <div className="p-col-4 text-right">
                                    <label>Tên khách hàng</label>
                                </div>
                                <div className="p-col-4">
                                    <InputText value={nameCustomer} placeholder="Tên khách hàng"
                                        style={{ width: '100%' }} onChange={(e :any) => setNameCustomer(e.target.value)} />
                                </div>
                            </div>
                            <div className="p-grid" style={{ marginTop: -7 }}>
                                <div className="p-col-4 text-right">
                                    <label>Số điện thoại</label>
                                </div>
                                <div className="p-col-4">
                                    <InputText value={phoneCustomer} placeholder="Số điện thoại"
                                        style={{ width: '100%' }} onChange={(e:any) => setPhoneCustomer(e.target.value)} />
                                </div>
                            </div>
                            <div className="p-grid" style={{ marginTop: -7 }}>
                    <div className="p-col-4 text-right">
                        <label>Số lượng Không Trúng</label>
                    </div>
                    <div className="p-col-2">
                        <InputNumber   max={400000000} value={gift1} placeholder="Số lượng Không trúng" 
                            style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e,'gift1')}   />
                          
                    </div>
                </div>
                <div className="p-grid" style={{ marginTop: -7 }}>
                    <div className="p-col-4 text-right">
                        <label>Số lượng Phone card 10k</label>
                    </div>
                    <div className="p-col-2">
                        <InputNumber   max={400000000} value={gift2} placeholder="Số lượng Phone card 10k" 
                            style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e,'gift2')}   />
                          
                    </div>
                </div>
                <div className="p-grid" style={{ marginTop: -7 }}>
                    <div className="p-col-4 text-right">
                        <label>Số lượng Đế gắn điện thoại</label>
                    </div>
                    <div className="p-col-2">
                        <InputNumber   max={400000000} value={gift3} placeholder="Số lượng Đế gắn điện thoại" 
                            style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e,'gift3')}   />
                          
                    </div>
                </div>
                <div className="p-grid" style={{ marginTop: -7 }}>
                    <div className="p-col-4 text-right">
                        <label>Số lượng Tai nghe chụp tai Kanen IP-350</label>
                    </div>
                    <div className="p-col-2">
                        <InputNumber   max={400000000} value={gift4} placeholder="Số lượng Tai nghe chụp tai Kanen IP-350" 
                            style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e,'gift4')}   />
                          
                    </div>
                </div>
                <div className="p-grid" style={{ marginTop: -7 }}>
                    <div className="p-col-4 text-right">
                        <label>Số lượng Loa Bluetooth JBL GO2</label>
                    </div>
                    <div className="p-col-2">
                        <InputNumber   max={400000000} value={gift5} placeholder="Số lượng Loa Bluetooth JBL GO2" 
                            style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e,'gift5')}   />
                          
                    </div>
                </div>
                    {renderTable()}
                    <div className='row p-col-12'>
                    <div className='row p-col-12'>
                        <label className="p-col-4">Hình bill </label>
                        <input type="file"
                            name="import2"
                            className="p-col-4" multiple accept="image/*"
                            onChange={(event) => onChangeFile(event, { questionId: 11})}
                        />

                    </div>
                </div>
                <div className='row p-col-12'>
                    <div className='row p-col-12'>
                        <label className="p-col-4">Hình KH nhận quà</label>
                        <input type="file"
                            name="import2"
                            className="p-col-4" multiple accept="image/*"
                            onChange={(event) => onChangeFile(event, { questionId: 12 })}
                        />

                    </div>
                </div>
                      </div>  
                    <div className="p-grid" style={{marginBottom: 10, marginTop: 10}}>
                        <div className='p-col-4'> 
                        </div>
                        <div className="p-col-2">
                                <Link className="bp3-button bp3-large btn-block btn-back" to={router.DataOrder+"/"+storeId}>QUAY LẠI</Link>
                            </div>
                            <div className="p-col-2">
                               <Button
                                    intent="success"
                                    className="btn-block"
                                    large
                                    onClick={()=>SaveData()}
                                >
                                    THÊM MỚI
                                </Button>
                              
                           
                            </div>
                            <div className='p-col-4'> 
                        </div>
                        </div>
                           
                      
            </FormLayout>
        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity, summary, detail) => dispatch(actions.error_success(severity, summary, detail))
    }
}
const mapStateToProps = (state:any) => {
    return {
      profile:state.auth.profile
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(AddDataOrder);
