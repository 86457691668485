import * as React from 'react';
import FormLayout from '../../components/form/index';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import { InputText } from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import * as router from "../../common/router";
import {
    Link
} from "react-router-dom";

import * as api from '../../reducer/contains/api'
import {axiosWithHeaders} from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum'
import UploadAvatar from './UploadAvatar'
// var _ = require('lodash');

const Gift = (props: any) => {
    const [button, setButton] = React.useState(true);
    const [giftId, setgiftId] = React.useState('');
    const [giftName,    setgiftName] = React.useState('');
    const [pathImage,    setPathImage] = React.useState('');
    const [avatar, setAvatar] = React.useState<string>("");
    const [total, settotal] = React.useState(0);
  
  
    React.useEffect(() => {
        if (props.match.params.id !== undefined && props.match.params.id !== null){
            setButton(false);
            axiosWithHeaders('POST',`${api.GET_GIFT}`,{giftId: props.match.params.id})
            .then((data:any) => {
                if(data !== undefined && data !== null ){
                    let response = data.data;
                    let {status,message} = response;
                    if(status === Status.STATUS_RESPONSE_OK)
                    {
    
                       const{	giftId,giftName,pathImage,specifications,total
                    } = response.result;
    
                        setgiftId(giftId);
                        setgiftName(giftName);
                        setPathImage(pathImage);
                        settotal(total)
                    
                    }
                    else{
                        props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                    }
                }
                else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }
                
            }).catch(err => {
                props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
            });
        }   
    }, []);

    const onValueChange = (e, field) => {
        switch(field){
            case 'giftName':
                setgiftName(e.target.value);
                break;
            case 'total':
                settotal(e.target.value);
                break;
            default:
                break;
        }
    }
    const CheckData=()=>{
        if(giftName === undefined || giftName === '')
        {
            props.error_success('error', 'Lỗi', "Vui lòng nhập tên sản phẩm");
            return false;
        }
        if(pathImage === undefined || pathImage === null || pathImage === '')
        {
            props.error_success('error', 'Lỗi', "Vui lòng chọn hình ảnh sản phẩm");
            return false;
        }
        return true;
    }
    const SaveData=()=>{
        if(!CheckData()){
            return false;
        }
        var obj={giftId,giftName,pathImage,total}
        axiosWithHeaders('POST',api.ADD_GIFT, obj)
        .then((data:any) => {
            if(data !== undefined && data !== null ){
                let response = data.data;
                let {status,message} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                    props.error_success('success', 'Thông báo', 'Thêm mới sản phẩm thành công !');
                    props.history.push(router.ListGift);
                }
                else{
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra ! !!!" + message);
                }
            }
            else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" );}
            
        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
        
    }

    const SaveUpdateData=()=>{
        if(!CheckData()){
            return false;
        }
        var obj={giftId,giftName,pathImage,total
        }
        axiosWithHeaders('POST',api.UPDATE_GIFT, obj)
        .then((data:any) => {
            if(data !== undefined && data !== null ){
                let response = data.data;
                let {status,message} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                    props.error_success('success', 'Thông báo', 'Cập nhat sản phẩm thành công !');
                    props.history.push(router.ListGift);
                }
                else{
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra ! !!!" + message);
                }
            }
            else{  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" );}
            
        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
        
    }

    return (
        <div className="booking-layout">
            <FormLayout formTitle="CỬA HÀNG">
                <div className="p-grid">
                    <div className="p-col-4"></div>
                    <div className="p-col-4">
                    <UploadAvatar onChange={setPathImage} urlAvatar={pathImage} />
                    <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Hình ảnh quà</label>
                            </div>
                            <div className="p-col-6">
                            <InputText 
                                    value={pathImage} 
                                    placeholder="Chọn ảnh và upload ở trên..." 
                                    onChange={(e: any)=> setPathImage(e.target.value)} 
                                    style={{width: "100%"}}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Tên sản phẩm</label>
                            </div>
                            <div className="p-col-6">
                                <InputText value={giftName} placeholder="Tên sản phẩm"
                                    style={{ width: '100%' }} onChange={(e) => onValueChange(e, 'giftName')} />
                            </div>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Số lượng</label>
                            </div>
                            <div className="p-col-6">
                                <InputNumber  min={0} max={400000000} value={total} placeholder="Số lượng" 
                                    style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e, 'total')}   />
                                  
                            </div>
                        </div>
                        <div className="p-grid" style={{marginBottom: 10, marginTop: 10}}>
                            <div className="p-col-6">
                                <Link className="bp3-button bp3-large bp3-intent-primary btn-block btn-back" to={router.ListGift}>QUAY LẠI</Link>
                            </div>
                            <div className="p-col-6">
                                {button ?     <Button
                                    intent="success"
                                    className="btn-block"
                                    large
                                    onClick={()=>SaveData()}
                                >
                                    THÊM MỚI
                                </Button>:
                                <Button
                                    intent="primary"
                                    className="btn-block"
                                    large
                                    onClick={()=>SaveUpdateData()}
                                >
                                    CẬP NHẬT
                                </Button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="p-col-4"></div>
                </div>
            </FormLayout>
        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity,summary,detail) => dispatch(actions.error_success(severity,summary,detail))
    }
}

export default connect(null, mapDispatchToProps)(Gift);
