export const filterTable = [
    {
        field: 'prefix',
        title: 'Từ khóa',
        type: 'string',
        defaultValue: '',
        placeholder: 'Tìm kiếm theo...'
    },
    {
        field: 'dateFrom',
        title: 'TỪ NGÀY',
        type: 'date',
        defaultValue: null,
        placeholder: 'Tìm kiếm theo...'
    },
    {
        field: 'dateTo',
        title: 'ĐẾN NGÀY',
        type: 'date',
        defaultValue: null,
        placeholder: 'Tìm kiếm theo...'
    },
    {
        field: 'userAudit',
        title: 'Nhân viên',
        type: 'string',
        defaultValue: '',
        placeholder: 'Tên nhân viên...'
    },
]

export const columnTable = [
    {
        field: 'stt',
        header: '#',
        width: 30,
        align: 'left',
        fontSize: 11,
        rowSpan:2,
        frozen:true,
        usedTemplate: true
    },
    {
        field: 'storeCode',
        header: 'Mã',
        frozen:true,
        width: 150,
        align: 'left',
        fontSize: 9,
        usedTemplate: true
    },
    {
        field: 'storeName',
        header: 'Tên cửa hàng',
      //  frozen:true,
        width: 200,
        align: 'left',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'dateSend',
        header: 'Ngày',
        width: 100,
        align: 'left',
        fontSize: 11
    },
    // {
    //     field: 'storeAddress',
    //     header: 'Địa chỉ',
    //     width: 300,
    //     align: 'left',
    //     fontSize: 11,
    //     usedTemplate: true
    // },
    // {
    //     field: 'costOrder',
    //     header: 'Doanh số',
    //     width: 80,
    //     align: 'right',
    //     fontSize: 11,
    //     usedTemplate: true
    // },
    {
        field: 'gift1',
        header: 'SL Mặt Nạ',
        width: 80,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'giftrest1',
        header: 'SL Mặt Nạ',
        width: 80,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'gift2',
        header: 'SL Khăn tẩy trang',
        width: 80,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'giftrest2',
        header: 'SL Khăn tẩy trang',
        width: 80,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'gift3',
        header: 'SL thố',
        width: 80,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'giftrest3',
        header: 'SL thố',
        width: 80,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'gift4',
        header: 'SL thố',
        width: 80,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'giftrest4',
        header: 'SL thố',
        width: 80,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'gift5',
        header: 'SL thố',
        width: 80,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    {
        field: 'giftrest5',
        header: 'SL thố',
        width: 80,
        align: 'right',
        fontSize: 11,
        usedTemplate: true
    },
    
    {
        field: 'action',
        header: '',
        width: 100,
        align: 'center',
        fontSize: 11,
        usedTemplate: true
    }
]