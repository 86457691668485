export const filterTable = [
    {
        field: 'prefix',
        title: 'Từ khóa',
        type: 'string',
        defaultValue: '',
        placeholder: 'Tìm kiếm theo...'
    }
]

export const columnTable = [
    {
        field: 'stt',
        header: '#',
        width: 40,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'dateRun',
        header: 'Ngày chạy',
        width: 60,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'storeName',
        header: 'Tên siêu thị',
        width: 60,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'shiftName',
        header: 'Ca',
        width: 60,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'sessionPlan',
        header: 'Session Plan',
        width: 60,
        align: 'right',
        fontSize: 11
    },

    {
        field: 'action',
        header: '',
        width: 55,
        align: 'left',
        fontSize: 11,
        usedTemplate: true
    }
]